import { Box, Container, Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import logo from '../../assets/images/logowhite.png';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import TelegramIcon from '@mui/icons-material/Telegram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import './landFooter.css';
import { Link } from 'react-router-dom';

const LandFooter = () => {
  return (
    <>
    <Box  sx={{background:'#00150f', pt: 10, position:'relative', overflow:'hidden'}}>
    <span className="footerShape">
    <span className="footerbg"></span>
    </span>
    <Container maxWidth='lg' sx={{position:'relative', zIndex: 111}}>
        <Grid container spacing={2}>
  <Grid className='footerlogo' item xs={12} md={4}>
       <img src={logo} width={200} alt='logo'></img>
       <Typography variant='h6' component='p' sx={{marginTop: 2, color:'text.light'}} >Elevate your trading experience with our comprehensive resources and expert guidance. Join our community today to unlock the potential of algorithmic trading, master option buying and selling, and stay ahead of market trends. Connect with us to transform your approach to trading and achieve your financial goals.</Typography>
  </Grid>
  <Grid item xs={1}></Grid>
  <Grid item xs={12} md={3}>
        <div>
        <Typography variant='h6' component='p' sx={{marginTop: 2, color:'text.white'}} >Quick Links</Typography>
        <Link to="/about" style={{textDecoration:'none'}}>
        <Typography variant='h6' component='p' sx={{marginTop: 2, color:'text.light'}} >About Us</Typography>
        </Link>
        <Link to="/course" style={{textDecoration:'none'}}>
        <Typography variant='h6' component='p' sx={{marginTop: 2, color:'text.light'}} >Course</Typography>
        </Link>
        <Link to="/services" style={{textDecoration:'none'}}>
        <Typography variant='h6' component='p' sx={{marginTop: 2, color:'text.light'}} >Services</Typography>
        </Link>
        <Link to="/referraltrading" style={{textDecoration:'none'}}>
        <Typography variant='h6' component='p' sx={{marginTop: 2, color:'text.light'}} >Referral Trading Account (Firststock, AliceBlueOnline)</Typography>
        </Link>
        <Link to="/contact" style={{textDecoration:'none'}}>
        <Typography variant='h6' component='p' sx={{marginTop: 2, color:'text.light'}} >Contact Us</Typography>
        </Link>
        </div>
          </Grid>
  <Grid item xs={12} md={2}>
  <div>
  <Typography variant='h6' component='p' sx={{marginTop: 2, color:'text.white'}} >Support</Typography>
  <Link to='/termsandcondition' style={{textDecoration:'none'}}>
  <Typography variant='h6' component='p' sx={{marginTop: 2, color:'text.light'}} >Terms & Conditions</Typography>
  </Link>
  <Link to='/privacypolicy' style={{textDecoration:'none'}}>
        <Typography variant='h6' component='p' sx={{marginTop: 2, color:'text.light'}} >Privacy policy</Typography></Link>
        <Link to='/refundpolicy' style={{textDecoration:'none'}}>
        <Typography variant='h6' component='p' sx={{marginTop: 2, color:'text.light'}} >Refund policy</Typography>
        </Link>
        </div>
</Grid>
  <Grid item xs={12} md={2}>
  <div>
  <Typography variant='h6' component='p' sx={{marginTop: 2, color:'text.white'}} >Company</Typography>
        <Typography variant='h6' component='p' sx={{marginTop: 2, color:'text.light'}} >Updates</Typography>
        <Typography variant='h6' component='p' sx={{marginTop: 2, color:'text.light'}} >Announce</Typography>
        </div>
  </Grid>
</Grid>
<Divider orientation='horizontal' flexItem sx={{my: 3, borderColor:'text.light'}}/>
<Grid container spacing={2} sx={{pb: 2, alignItems:'center'}}>
  <Grid item xs={12} md={4} sx={{textAlign:{md:'start', xs:'center'}}}>
  <Typography variant='h6' component='p' sx={{color:'text.light'}} >© 2024 All Right Reserved
</Typography>
  </Grid>
  <Grid item xs={12} md={4} sx={{display:'flex', justifyContent:{md:'center', xs:'center'}, alignItems:'center', gap: 3}}>
  <a href='https://wa.link/l8q5eh' target='_blank' style={{textDecoration:'none'}}>
  <Box sx={{border:'1px solid #6b777f', width:'45px', height:'45px', borderRadius:'50%', display:'flex', justifyContent:'center', alignItems:'center'}}>
    <WhatsAppIcon sx={{color:'text.white'}}/>
  </Box>
  </a>
  <a href='https://www.instagram.com/dtz_vinoth/' target='_blank' style={{textDecoration:'none'}}>
  <Box sx={{border:'1px solid #6b777f', width:'45px', height:'45px', borderRadius:'50%', display:'flex', justifyContent:'center', alignItems:'center'}}>
    <InstagramIcon sx={{color:'text.white'}}/>
  </Box>
  </a>
  <a href='https://t.me/DTZcommunity/' target='_blank' style={{textDecoration:'none'}}>
  <Box sx={{border:'1px solid #6b777f', width:'45px', height:'45px', borderRadius:'50%', display:'flex', justifyContent:'center', alignItems:'center'}}>
    <TelegramIcon sx={{color:'text.white'}}/>
  </Box>
  </a>
  <a href='https://www.youtube.com/@DisciplineTradersZone' target='_blank' style={{textDecoration:'none'}}>
  <Box sx={{border:'1px solid #6b777f', width:'45px', height:'45px', borderRadius:'50%', display:'flex', justifyContent:'center', alignItems:'center'}}>
    <YouTubeIcon sx={{color:'text.white'}}/>
  </Box>
  </a>
  </Grid>
  <Grid item xs={12} md={4} sx={{textAlign:{md:'end', xs:'center'}}}>
  <Typography variant='h6' component='p' sx={{color:'text.light'}} >Designed and Developed by <a href="https://www.nexarcit.com" target='_blank' style={{textDecoration:'none', color:'#fff'}}>NexArc IT</a>
</Typography>
  </Grid>
</Grid>

    </Container>
    </Box>
    </>
  )
}

export default LandFooter;