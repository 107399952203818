import React, {useState} from 'react';
import Dashboard from './pages/dashboard/Dashboard.js';
import Category from './pages/category/Category.js';
import Subcategory from './pages/subcategory/Subcategory.js';
import { Route, Routes } from 'react-router-dom';
import Layout from './Layout';
import Layout2 from './Layout2.js';
import Login from './pages/login/Login.js';
import Home from './pages/home/Home.js';
import Register from './pages/register/Register.js';
import School from './pages/schools/School.js';
import About from './pages/about/About.js';
import Algo from './pages/algo/Algo.js';
import Course from './pages/Course/Course.js';
import Services from './pages/services/Services.js';
import Contact from './pages/Contact/Contact.js';
import Coursedetail from './pages/Course/Coursedetail.js';
import Privacy from './pages/Privacy/Privacy.js';
import Refund from './pages/Refund/Refund.js';
import Terms from './pages/Terms/Terms.js';
import Coursedetailsell from './pages/Course/Coursedetailsell.js';
import Referral from './pages/Referral/Referrel.js';
import Algoform from './pages/algoform/index.js';


const RouteRoute = ({setLoader}) => {

  const [show, setShow] = useState(true);

  return (
    <>
    <Routes>
        <Route path='/' element={<Layout2/>}>
        <Route path='/' exact element={<Home setLoader={setLoader}/>}></Route>
        <Route path='/about' exact element={<About setLoader={setLoader}/>}></Route>  
        <Route path='/algoc' exact element={<Algo setLoader={setLoader}/>}></Route>  
        <Route path='/course' >
          <Route index={true} element={<Course setLoader={setLoader}/>}></Route>
          <Route path='buydetail' element={<Coursedetail setLoader={setLoader}/>}></Route>
          <Route path='selldetail' element={<Coursedetailsell setLoader={setLoader}/>}></Route>
        </Route>  
        <Route path='/services' exact element={<Services setLoader={setLoader}/>}></Route>  
        <Route path='/contact' exact element={<Contact setLoader={setLoader}/>}></Route> 
        <Route path='/referraltrading' exact element={<Referral setLoader={setLoader}/>}></Route> 
        <Route path='/privacypolicy' exact element={<Privacy setLoader={setLoader}/>}></Route> 
        <Route path='/refundpolicy' exact element={<Refund setLoader={setLoader}/>}></Route> 
        <Route path='/termsandcondition' exact element={<Terms setLoader={setLoader}/>}></Route>  
        <Route path='/algo' element={<Algoform setLoader={setLoader}/>}></Route>       
        </Route>
        <Route path='/login' element={<Login setLoader={setLoader}/>}></Route> 
        <Route path='/register' element={<Register setLoader={setLoader}/>}></Route> 
        <Route path='/' element={<Layout show={show} setShow={setShow}/>}>
        <Route path='/dashboard' element={<Dashboard  show={show} setLoader={setLoader}/>}></Route>
        <Route path='/schools' element={<School show={show} setLoader={setLoader}/>}></Route>
        <Route path='/subcategory' element={<Subcategory setLoader={setLoader}/>}></Route>
      </Route>
    </Routes>
    </>
  )
}

export default RouteRoute;