import React, {useState, useEffect} from 'react';
import {
  Box, Button, Container, Divider, Grid, Modal, TextField, Typography,
} from "@mui/material";
import './style.css';
import takepic from '../../assets/images/takeapick.png';
import temp from '../../assets/images/choosetemp.png';
import studio from '../../assets/images/studio.png';
import arrow1 from '../../assets/images/arrow1.png';
import arrow2 from '../../assets/images/arrow2.png';
import VertTabs from '../../components/tabs/Tabs';
import userAi from '../../assets/images/users_ai.png';
import AIenhance from '../../assets/images/aienhancer.png';
import Easytask from '../../assets/images/easytask.png';
import team from '../../assets/images/team.png';
import figma1 from '../../assets/images/figma_1.png';

import uparrow from '../../assets/images/uparrow.png';
import trader from '../../assets/images/trader.png';
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';
import Reattimedata from '../../assets/images/1 (1).png';
import custSupport from '../../assets/images/customer_support.png';
import highSec from '../../assets/images/high_security.png';
import lowCommis from '../../assets/images/lower_commis.png';
import check from '../../assets/images/check.png';
import ourcommit from '../../assets/images/about.png';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import starery from '../../assets/images/stagery.png';
import financeadvi from '../../assets/images/financeadvi.png';
import management from '../../assets/images/management.png';
import check1 from '../../assets/images/check1.svg';
import MultipleItems from '../../components/carousal/Carousal';
import faq from '../../assets/images/faq.png';
import CustomizedAccordions from '../../components/accordion/Accordion';
import contact from '../../assets/images/contact.png';
import alertPop from '../../assets/images/alertPoster.jpeg';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ScrollToTop from '../../components/ScrollToTop';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 15,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: {xs: 300 ,md: 600},
};

const style1 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: {xs: 300 ,md: 1000},
};



const Home = ({setLoader}) => {

  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);

  const [open1, setOpen1] = React.useState(false);
  const handleClose1 = () => setOpen1(false);

  const handleOpen1 = () => setOpen1(true);

  useEffect(()=>{
      const openModal = sessionStorage.getItem("modal");
      if(openModal){
        setOpen(false)
      }
      else{
        sessionStorage.setItem("modal", false);
        setOpen(true)
      }
  }, [])

  return (
    <div>
    <ScrollToTop/>
    <div className='firstSection'>
    <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <HighlightOffIcon onClick={handleClose} sx={{cursor:'pointer' ,color:'#fff', position:'absolute', right: '-30px', top: '-30px', fontSize:'50px'}}/>
          <img className='popUpalert' src={alertPop} alt='poster' width='100%' ></img>
        </Box>
      </Modal>
    <Container maxWidth="lg">
      <Grid container spacing={2} sx={{alignItems:'center'}}>
          <Grid item xs={12} md={5}>
          <img src={uparrow} alt='uparrow'></img>
          <Typography className='headText' variant='h5' sx={{fontFamily:"'Sora', sans-serif", fontWeight:'bold', marginTop: 2, lineHeight: 1.7}}>Empower Your Trading with DTZ Trading Academy: Where Algorithms Meet Expertise!</Typography>
          <Typography variant='h6' sx={{color:'text.light', fontSize:'18px', fontWeight:'normal', my: 3}}>Unlock the power of trading with DTZ Trading Academy! Led by CEO Vinoth, our platform combines cutting-edge algorithms with expert insights. Join us to revolutionize your trading strategies today!</Typography>
          <Box sx={{mt: 2}}>
          <a href='https://wa.link/l8q5eh' target='_blank'>
            <Button size='large' variant="contained" sx={{mr: 2}}>Book a Demo</Button>
            </a>
            <Button onClick={handleOpen1} size='large' variant="text"><PlayCircleOutlinedIcon sx={{mr: 1}}/> Watch Video</Button>
            <Modal
        open={open1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style1}>
          <HighlightOffIcon onClick={handleClose1} sx={{cursor:'pointer' ,color:'#fff', position:'absolute', right: '-30px', top: '-30px', fontSize:'50px'}}/>
          <iframe width="100%" height="600" src="https://www.youtube.com/embed/9zUZolg9EFo?si=ted702qAZ62YgCpd" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </Box>
      </Modal>
          </Box>
          </Grid>
          <Grid className="bannerThump" item xs={12} md={3} sx={{textAlign:'center'}}>
              <img src={trader} alt='trader' width={300} style={{position:'relative'}}></img>
            </Grid>
            <Grid className="lastThump" item xs={12} md={4} sx={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', gap: 5, mt: 0}}>
              <Box sx={{p: 5, border:"2px solid rgba(31,35,61,0)", boxShadow:'0 16px 64px 0 rgba(0,208,148,.12)', borderRadius:'8px', width:'200px'}}>
                <Typography variant='h4' sx={{fontWeight:'bold'}}>5+ Years</Typography>
                <Typography variant='h6' sx={{fontWeight:'normal'}}>Trading Experience</Typography>
              </Box>
              <Box sx={{p: 5, border:"2px solid rgba(31,35,61,0)", boxShadow:'0 16px 64px 0 rgba(0,208,148,.12)', borderRadius:'8px', width:'200px'}}>
                <Typography variant='h4' sx={{fontWeight:'bold'}}>1000+</Typography>
                <Typography variant='h6' sx={{fontWeight:'normal'}}>Students</Typography>
              </Box>
              <Box sx={{p: 5, border:"2px solid rgba(31,35,61,0)", boxShadow:'0 16px 64px 0 rgba(0,208,148,.12)', borderRadius:'8px', width:'200px'}}>
                <Typography variant='h4' sx={{fontWeight:'bold'}}>1000+</Typography>
                <Typography variant='h6' sx={{fontWeight:'normal'}}>Algo Users</Typography>
              </Box>
            </Grid>
      </Grid>
    </Container>
    </div>
    <Box className="secondSec" sx={{position:'relative'}}>
    <Container maxWidth="lg">
          <Typography variant='h3' sx={{fontFamily:"'Sora', sans-serif", fontWeight:'bold', textAlign:'center', color:'text.white'}}>
          Features We Have
          </Typography>
          <Typography variant='h6' sx={{color:'text.white', fontSize:'18px', fontWeight:'normal', my: 3, textAlign:'center'}}>Hey there! Here are the features we have: user-friendly interface, top-notch security, and fast performance.</Typography>
          <Grid container spacing={3} sx={{mt: 5}}>
              <Grid item xs={12} md={3}>
                  <Box sx={{background:'#fff', borderRadius: 2, p: 2,  pb: 0, height:'100%'}}>
                  <img src={Reattimedata} alt='real'></img>
                  <Typography variant='h5' sx={{fontWeight:'bold', textAlign:'center', mt: 1}}>Full API Integration</Typography>
                  <Typography variant='body1' sx={{color:'text.light', textAlign:'center', my: 2}}>Seamlessly connect your demat account to our platform with full API integration, enabling efficient trade execution and management.</Typography>
                  </Box>
              </Grid>
              <Grid item xs={12} md={3}>
              <Box sx={{background:'#fff', borderRadius: 2, p: 2,  pb: 0, height:'100%'}}>
                  <img src={custSupport} alt='real'></img>
                  <Typography variant='h5' sx={{fontWeight:'bold', textAlign:'center', mt: 1}}>Back Tested Strategies
</Typography>
                  <Typography variant='body1' sx={{color:'text.light', textAlign:'center', my: 2}}>
Access a library of meticulously back-tested strategies, providing you with data-driven insights to make informed trading decisions.</Typography>
                  </Box>
              </Grid>
              <Grid item xs={12} md={3}>
                                <Box sx={{background:'#fff', borderRadius: 2, p: 2, pb: 0, height:'100%'}}>
                  <img src={highSec} alt='real'></img>
                  <Typography variant='h5' sx={{fontWeight:'bold', textAlign:'center', mt: 1}}>Real-time Data</Typography>
                  <Typography variant='body1' sx={{color:'text.light', textAlign:'center', my: 2}}>Access up-to-the-minute market data, empowering you with timely insights to make informed trading decisions and seize opportunities as they unfold.</Typography>
                  </Box>
              </Grid>
              <Grid item xs={12} md={3}>
                                <Box sx={{background:'#fff', borderRadius: 2, p: 2,  pb: 0, height:'100%'}}>
                  <img src={lowCommis} alt='real'></img>
                  <Typography variant='h5' sx={{fontWeight:'bold', textAlign:'center', mt: 1}}>Maximized security</Typography>
                  <Typography variant='body1' sx={{color:'text.light', textAlign:'center', my: 2}}>
Advanced encryption and constant monitoring guarantee the safety of your financial data.</Typography>
                  </Box>
              </Grid>
          </Grid>
    </Container>
        </Box>
        <Box className="thirdSec" sx={{position:'relative'}}>
    <Container maxWidth="lg">
    <Grid container spacing={2}>
  <Grid item xs={12} md={6}>
  <Typography variant='h4' sx={{fontFamily:"'Sora', sans-serif", fontWeight:'bold', lineHeight: 1.5}}>
  Unlock Your Trading Potential with Algorithmic Trading</Typography>
  <Box sx={{display:'flex', justifyContent:'start', alignItems:'center', gap: 2, py: 3, mt: 4}}>
  <img src={check} alt='check'></img>
<Typography  component='p' sx={{fontSize: '20px', fontWeight:'medium'}}> Precision Execution</Typography>
  </Box>
  <Box sx={{display:'flex', justifyContent:'start', alignItems:'center', gap: 2,}}>
  <img src={check} alt='check'></img>
<Typography  component='p' sx={{fontSize: '20px', fontWeight:'medium'}}> Data-Driven Insights</Typography>
  </Box>
  <Box sx={{display:'flex', justifyContent:'start', alignItems:'center', gap: 2, py: 3}}>
  <img src={check} alt='check'></img>
<Typography  component='p' sx={{fontSize: '20px', fontWeight:'medium'}}> Risk Management</Typography>
  </Box>
  <Box sx={{display:'flex', justifyContent:'start', alignItems:'center', gap: 2}}>
  <img src={check} alt='check'></img>
<Typography  component='p' sx={{fontSize: '20px', fontWeight:'medium'}}>Time Efficiency</Typography>
  </Box>
        <Button size="large" variant='contained' sx={{mt: 5}}>Book a Demo</Button>
  </Grid>
  <Grid className="commitImg" item xs={12} md={6}>
      <img src={ourcommit} alt='ourcommit' width={400}></img>
      <Box className="commitProgress">
        <Typography variant='h3' sx={{fontWeight:'bold'}}>90%</Typography>
        <Typography variant='body1' sx={{mb: 2}}>Satisfied Clients</Typography>
        <BorderLinearProgress variant="determinate" value={50} />
      </Box>
  </Grid>
</Grid>
    </Container>
        </Box>
        <Box className="fourthSec" sx={{position:'relative'}}>
    <Container maxWidth="lg">
          <Typography variant='h3' sx={{fontFamily:"'Sora', sans-serif", fontWeight:'bold', textAlign:'center', color:'text.main'}}>
          <Typography variant='p' sx={{color:'secondary.main'}}>Services</Typography> We Offer
          </Typography>
          <Typography variant='h6' sx={{color:'text.light', fontSize:'18px', fontWeight:'normal', my: 3, textAlign:'center'}}>
We are proud to offer three essential services to our DTZ family</Typography>
          <Grid container spacing={3} sx={{mt: 5}}>
              <Grid item xs={12} md={4}>
                  <Box sx={{background:'linear-gradient(152deg, rgba(237,242,248,.22) 0, #edf2f8 100%)', borderRadius: 3, p: 7, border:'2px solid #fff'}}>
                  <Box sx={{ display:'flex', justifyContent:'center', alignItems:'center', mb: 4}}>
                  <Box sx={{background:'#fff', p: 3, borderRadius:'50%'}}>
                  <img src={starery} alt='real'></img>
                  </Box>
                  </Box>
                  <Typography variant='h5' sx={{fontWeight:'bold', textAlign:'center', mt: 1}}>Algorithmic Trading</Typography>
                  <Typography variant='body1' sx={{color:'text.light', textAlign:'center', my: 2}}>Experience the future of trading with our cutting-edge algorithmic trading services, providing automation and optimization for your trading strategies</Typography>
                  </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                  <Box sx={{background:'linear-gradient(152deg, rgba(237,242,248,.22) 0, #edf2f8 100%)', borderRadius: 3, p: 7, border:'2px solid #fff'}}>
                  <Box sx={{ display:'flex', justifyContent:'center', alignItems:'center', mb: 4}}>
                  <Box sx={{background:'#fff', p: 3, borderRadius:'50%'}}>
                  <img src={financeadvi} alt='real'></img>
                  </Box>
                  </Box>
                  <Typography variant='h5' sx={{fontWeight:'bold', textAlign:'center', mt: 1}}>Option Buying Class</Typography>
                  <Typography variant='body1' sx={{color:'text.light', textAlign:'center', my: 2}}>Dive into the world of option buying with our comprehensive classes, designed to empower you with knowledge and skills for successful trading.</Typography>
                  </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                  <Box sx={{background:'linear-gradient(152deg, rgba(237,242,248,.22) 0, #edf2f8 100%)', borderRadius: 3, p: 7, border:'2px solid #fff'}}>
                  <Box sx={{ display:'flex', justifyContent:'center', alignItems:'center', mb: 4}}>
                  <Box sx={{background:'#fff', p: 3, borderRadius:'50%'}}>
                  <img src={management} alt='real'></img>
                  </Box>
                  </Box>
                  <Typography variant='h5' sx={{fontWeight:'bold', textAlign:'center', mt: 1}}>Option Selling Class</Typography>
                  <Typography variant='body1' sx={{color:'text.light', textAlign:'center', my: 2}}>Explore advanced techniques in option selling through our specialized classes, aimed at enhancing your proficiency and confidence in the options market.</Typography>
                  </Box>
              </Grid>
          </Grid>
    </Container>
        </Box>
        {/* <Box className="fifthSec" >
    <Container maxWidth="lg">
          <Typography variant='h3' sx={{fontFamily:"'Sora', sans-serif", fontWeight:'bold', textAlign:'center', color:'text.main'}}>Our
           <Typography className='priceTitle' variant='p' sx={{color:'secondary.main'}}> Pricings</Typography> Plan
          </Typography>
          <Typography variant='h6' sx={{color:'text.light', fontSize:'18px', fontWeight:'normal', my: 3, textAlign:'center'}}>Our Pricing Plans cater to all individuals within our community, ensuring accessibility and inclusivity for everyone in our society</Typography>
          <Grid container spacing={2} sx={{mt: 5, alignItems:'center'}}>
              <Grid item xs={12} md={3}>
                  <Box className="pricings" sx={{background:'#fff', borderRadius: 4, p: 2, border:'2px solid #fff', boxShadow:'0 16px 40px 0 rgba(26,64,137,.06)'}}>
                  <Typography variant='h5' sx={{fontWeight:'bold', mt: 1}}>Explore With Free Plan</Typography>
                  <Typography variant='p' sx={{color:'secondary.main', fontWeight:'bold', mt: 1, fontSize: 36}}>₹0</Typography>
                  <Typography variant='p' sx={{color:'text.light',fontWeight:'medium', mt: 1}}> (Incl of all tax)</Typography>
                  <Divider sx={{my: 3}}/>
                  <Box sx={{display:'flex', justifyContent:'start', alignItems:'center', gap: 2, mt: 4}}>
  <img src={check1} alt='check'></img>
<Typography  component='p' sx={{fontSize: '16px', fontWeight:'medium'}}>Nifty Algo</Typography>
  </Box>
  <Box sx={{display:'flex', justifyContent:'start', alignItems:'center', gap: 2,mt: 2}}>
  <img src={check1} alt='check'></img>
<Typography  component='p' sx={{fontSize: '16px', fontWeight:'medium'}}>Midcap Algo</Typography>
  </Box>
  <Box sx={{display:'flex', justifyContent:'start', alignItems:'center', gap: 2, mt: 2}}>
  <img src={check1} alt='check'></img>
<Typography  component='p' sx={{fontSize: '16px', fontWeight:'medium'}}>With Customized Target & Stop Loss</Typography>
  </Box>
  <a href='https://wa.link/l8q5eh' target='_blank'>
  <Button size="large" fullWidth variant="outlined" sx={{mt: 3}}>Buy Now</Button>
  </a>
                  </Box>
              </Grid>
              <Grid item xs={12} md={3}>
                  <Box className="pricings1" sx={{background:'#fff', borderRadius: 4, p: 2, boxShadow:'0 16px 40px 0 rgba(26,64,137,.06)'}}>
                  <Typography variant='h5' sx={{fontWeight:'bold', mt: 1}}>Basic Plan</Typography>
                  <Typography variant='p' sx={{color:'secondary.main', fontWeight:'bold', mt: 1, fontSize: 36}}>₹6000</Typography>
                  <Typography variant='p' sx={{color:'text.light',fontWeight:'medium', mt: 1}}> (Incl of all tax)</Typography>
                  <Typography variant='h6' sx={{color:'primary.main',fontWeight:'medium', mt: 1}}>₹ 10000 (40% Off)</Typography>
                  <Divider sx={{my: 3}}/>
                  <Divider sx={{my: 3}}/>
                  <Box sx={{display:'flex', justifyContent:'start', alignItems:'center', gap: 2, mt: 4}}>
  <img src={check1} alt='check'></img>
<Typography  component='p' sx={{fontSize: '16px', fontWeight:'medium'}}>Nifty Algo</Typography>
  </Box>
  <Box sx={{display:'flex', justifyContent:'start', alignItems:'center', gap: 2,mt: 2}}>
  <img src={check1} alt='check'></img>
<Typography  component='p' sx={{fontSize: '16px', fontWeight:'medium'}}>Midcap Algo</Typography>
  </Box>
  <Box sx={{display:'flex', justifyContent:'start', alignItems:'center', gap: 2, mt: 2}}>
  <img src={check1} alt='check'></img>
<Typography  component='p' sx={{fontSize: '16px', fontWeight:'medium'}}>Bank Nifty Algo</Typography>
  </Box>
  <Box sx={{display:'flex', justifyContent:'start', alignItems:'center', gap: 2, mt: 2}}>
  <img src={check1} alt='check'></img>
<Typography  component='p' sx={{fontSize: '16px', fontWeight:'medium'}}>Commodity</Typography>
  </Box>
  <Box sx={{display:'flex', justifyContent:'start', alignItems:'center', gap: 2, mt: 2}}>
  <img src={check1} alt='check'></img>
<Typography  component='p' sx={{fontSize: '16px', fontWeight:'medium'}}>With Customized Target & Stop Loss</Typography>
  </Box>
  <a href='https://wa.link/l8q5eh' target='_blank'>
  <Button size="large" fullWidth variant="contained" sx={{mt: 3}}>Buy Now</Button>
  </a>
                  </Box>
              </Grid>
              <Grid item xs={12} md={3}>
                  <Box className="pricings" sx={{background:'#fff', borderRadius: 4, p: 2, border:'2px solid #fff', boxShadow:'0 16px 40px 0 rgba(26,64,137,.06)'}}>
                  <Typography variant='h5' sx={{fontWeight:'bold', mt: 1}}>Standard Plan</Typography>
                  <Typography variant='p' sx={{color:'secondary.main', fontWeight:'bold', mt: 1, fontSize: 36}}>₹25000</Typography>
                  <Typography variant='p' sx={{color:'text.light',fontWeight:'medium', mt: 1}}> (Incl of all tax)</Typography>
                  <Divider sx={{my: 3}}/>
                  <Box sx={{display:'flex', justifyContent:'start', alignItems:'center', gap: 2, mt: 4}}>
  <img src={check1} alt='check'></img>
<Typography  component='p' sx={{fontSize: '16px', fontWeight:'medium'}}>12 Hrs Algo Strategy Course Material</Typography>
  </Box>
  <Box sx={{display:'flex', justifyContent:'start', alignItems:'center', gap: 2,mt: 2}}>
  <img src={check1} alt='check'></img>
<Typography  component='p' sx={{fontSize: '16px', fontWeight:'medium'}}>Nifty Algo</Typography>
  </Box>
  <Box sx={{display:'flex', justifyContent:'start', alignItems:'center', gap: 2, mt: 2}}>
  <img src={check1} alt='check'></img>
<Typography  component='p' sx={{fontSize: '16px', fontWeight:'medium'}}>Midcap Algo</Typography>
  </Box>
  <Box sx={{display:'flex', justifyContent:'start', alignItems:'center', gap: 2, mt: 2}}>
  <img src={check1} alt='check'></img>
<Typography  component='p' sx={{fontSize: '16px', fontWeight:'medium'}}>Bank Nifty Algo</Typography>
  </Box>
  <Box sx={{display:'flex', justifyContent:'start', alignItems:'center', gap: 2, mt: 2}}>
  <img src={check1} alt='check'></img>
<Typography  component='p' sx={{fontSize: '16px', fontWeight:'medium'}}>Commodity</Typography>
  </Box>
  <Box sx={{display:'flex', justifyContent:'start', alignItems:'center', gap: 2, mt: 2}}>
  <img src={check1} alt='check'></img>
<Typography  component='p' sx={{fontSize: '16px', fontWeight:'medium'}}>With Customized Target & Stop Loss</Typography>
  </Box>
  <a href='https://wa.link/l8q5eh' target='_blank'>
  <Button size="large" fullWidth variant="outlined" sx={{mt: 3}}>Buy Now</Button>
  </a>
                  </Box>
              </Grid>
              <Grid item xs={12} md={3}>
                  <Box className="pricings" sx={{background:'#fff', borderRadius: 4, p: 2, border:'2px solid #fff', boxShadow:'0 16px 40px 0 rgba(26,64,137,.06)'}}>
                  <Typography variant='h5' sx={{fontWeight:'bold', mt: 1}}>Premium Plan (Life Time)</Typography>
                  <Typography variant='p' sx={{color:'secondary.main', fontWeight:'medium', mt: 1, fontSize: 20}}>For Pricing Contact Support Team</Typography>
                  <Divider sx={{my: 3}}/>
                  <Box sx={{display:'flex', justifyContent:'start', alignItems:'center', gap: 2, mt: 4}}>
  <img src={check1} alt='check'></img>
<Typography  component='p' sx={{fontSize: '16px', fontWeight:'medium'}}>All existing indices</Typography>
  </Box>
  <Box sx={{display:'flex', justifyContent:'start', alignItems:'center', gap: 2,mt: 2}}>
  <img src={check1} alt='check'></img>
<Typography  component='p' sx={{fontSize: '16px', fontWeight:'medium'}}>Customized algo for any indices or stocks (Intra Day & Swing)</Typography>
  </Box>
  <Box sx={{display:'flex', justifyContent:'start', alignItems:'center', gap: 2, mt: 2}}>
  <img src={check1} alt='check'></img>
<Typography  component='p' sx={{fontSize: '16px', fontWeight:'medium'}}>Intra Day & Swing Trading</Typography>
  </Box>
  <a href='https://wa.link/l8q5eh' target='_blank'>
  <Button size="large" fullWidth variant="outlined" sx={{mt: 3}}>Buy Now</Button>
  </a>
                  </Box>
              </Grid>
          </Grid>
          <Grid container spacing={2} sx={{mt: 5}}>
          <Grid item xs={12}>
              <Box sx={{background:'#f3f3f3', p: 3, borderRadius: 3}}>
              <Typography  component='h5' sx={{color:'text.main', fontSize: '16px', fontWeight:'bold', textAlign:'center', lineHeight: 2}}>Desclaimer 
              </Typography>
              <Typography  component='li' sx={{color:'text.light', fontSize: '16px', fontWeight:'normal', textAlign:'justify', lineHeight: 2}}>We are not a SEBI-registered investment advisor,
 Therefore, none of our ALGO STRATEGIES shall be considered as investment advice. 
 </Typography>
 <Typography  component='li' sx={{color:'text.light', fontSize: '16px', fontWeight:'normal', textAlign:'justify', lineHeight: 2}}> 
 Market participants shall use these ALGO STRATEGIES for their knowledge purposes only. </Typography>
 <Typography  component='li' sx={{color:'text.light', fontSize: '16px', fontWeight:'normal', textAlign:'justify', lineHeight: 2}}> 
 CONSULT YOUR INVESTMENT ADVISOR BEFORE INVESTING.   </Typography>
 <Typography  component='li' sx={{color:'text.light', fontSize: '16px', fontWeight:'normal', textAlign:'justify', lineHeight: 2}}>   
 DTZ Trading Academy(www.dtzvinoth.com) ISN'T LIABLE FOR YOUR PROFITS OR LOSSES.  </Typography>
 <Typography  component='li' sx={{color:'text.light', fontSize: '16px', fontWeight:'normal', textAlign:'justify', lineHeight: 2}}>     
 ALL THE FEES PAID TO DTZ Trading Academy(www.dtzvinoth.com) IS NON - REFUNDABLE. 
 </Typography>
 <Typography  component='li' sx={{color:'text.light', fontSize: '16px', fontWeight:'normal', textAlign:'justify', lineHeight: 2}}>     
 Any dispute arising under these terms and conditions shall be subject to the coimbatore jurisdiction only.</Typography>
 </Box>
              </Grid>
          </Grid>
    </Container>
        </Box> */}
        <Box className="sixthSec" >
    <Container maxWidth="lg">
        <Grid container spacing={2}>
            <Grid item xs={12} md={7}>
            <Typography variant='h3' sx={{fontFamily:"'Sora', sans-serif", fontWeight:'bold',  color:'text.main'}}>Connect With
           <Typography variant='p' sx={{color:'secondary.main'}}> Our Clients</Typography>
          </Typography>
          <Typography variant='h6' sx={{color:'text.light', fontSize:'18px', fontWeight:'normal', my: 3, }}>Engage with our valued client.</Typography>
            </Grid>
        </Grid>
          <Grid container spacing={3} sx={{mt:{md: 0, xs: 5}, alignItems:'center'}}>
              <Grid item xs={12} md={12}>
                  <MultipleItems/>
              </Grid>
          </Grid>
    </Container>
        </Box>
        <Box className="seventhSec" >
    <Container maxWidth="lg">
          <Typography variant='h3' sx={{fontFamily:"'Sora', sans-serif", fontWeight:'bold', textAlign:'center', color:'text.main'}}>
           <Typography className='priceTitle1' variant='p' sx={{color:'secondary.main'}}> Frequently</Typography> Asked Questions
          </Typography>
          <Typography variant='h6' sx={{color:'text.light', fontSize:'18px', fontWeight:'normal', my: 3, textAlign:'center'}}>Hey there! Got questions? We've got answers. Check out our FAQ page for all<br></br> the deets. Still not satisfied? Hit us up.</Typography>
          <Grid container spacing={3} sx={{mt: 5, alignItems:'center'}}>
              <Grid item xs={12} md={8}>
                  <CustomizedAccordions/>
              </Grid>
              <Grid className='faq' item xs={12} md={4} sx={{textAlign:'center'}}> 
                 <img src={faq} alt="faq" width={300}></img>
              </Grid>
          </Grid>
    </Container>
        </Box>
          <div className='lastSection'>
    <Container maxWidth="lg">
        <Box className="contBox" sx={{ background:'#00d094', mx:{md: 5, xs: 0}, p:{md: 5, xs: 3}, borderRadius: 4}}>
        <Grid container spacing={2}>
              <Grid item xs={12} md={4} sx={{textAlign:{md: 'start', xs:'center'}}}>
                  <img src={contact} alt='contact'></img>
              </Grid>
              <Grid item xs={12} md={8}>
              <Typography variant='h3' sx={{fontFamily:"'Sora', sans-serif", fontWeight:'bold',  color:'text.main', textAlign:{md: 'start', xs:'center'}}}>
           <Typography variant='p' sx={{color:'secondary.main'}}> Subscribe</Typography> Our News
          </Typography>
          <Typography variant='h6' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3, textAlign:{md: 'start', xs:'center'} }}>Hey! Are you tired of missing out on our updates? Subscribe to our news now and stay in the loop!</Typography>
          <Grid container spacing={2}>
              <Grid item xs={12} md={8}>
              <TextField fullWidth  size="medium" id="outlined-basic" placeholder="Email Address" variant="outlined" sx={{background:'#fff', borderRadius: 1.5}} />
              </Grid>
              <Grid item xs={12} md={4}>
              <Button fullWidth size='large'  color='secondary' variant='contained' sx={{py: 1.7}}>Submit</Button>
              </Grid>
          </Grid>
              </Grid>
          </Grid>
        </Box>
    </Container>
    </div>
    </div>
  )
}

export default Home