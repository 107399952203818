import { Box, Button, Checkbox, Container, Divider, FormControlLabel, FormGroup, Grid, Modal, TextField, Typography } from '@mui/material'
import React, {useState, useEffect} from 'react'
import './LandHeader.css'
import Logo from '../../assets/images/logo.png'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Link } from 'react-router-dom';
import MailIcon from '@mui/icons-material/Mail';
import CallIcon from '@mui/icons-material/Call';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import TelegramIcon from '@mui/icons-material/Telegram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import HorizontalScroll from '../../components/scroll/HorizontalScroll';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: {md: 600, xs: 300},
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 1
};

const LandHeader = () => {

  const [headpos, setHeadpos] = useState(true);

  const [menuOpen, setMenuopen] = useState(false);

  const [loginopen, setLoginOpen] = React.useState(false);
  const handleLoginOpen = () => setLoginOpen(true);
  const handleLoginClose = () => setLoginOpen(false);

  const [registeropen, setRegisterOpen] = React.useState(false);
  const handleRegisterOpen = () => setRegisterOpen(true);
  const handleRegisterClose = () => setRegisterOpen(false);

  const handleScroll = () => {
    const scrollPosition = window.scrollY; // => scroll position
    if(scrollPosition > 250){
      setHeadpos(false);
    }
    else{
      setHeadpos(true);
    }
};

const handleMenu = () =>{
  setMenuopen(!menuOpen);
}

useEffect(() => {
  handleScroll();
  window.addEventListener("scroll", handleScroll);
  return () => {
    window.removeEventListener("scroll", handleScroll);
  };
  }, []);

  return (
    <>
    <Box sx={{backgroundColor:'primary.main', borderBottom:'1px solid #6b777f', py: 2.5}}>
    <HorizontalScroll text="Disclaimer: We are not a SEBI-registered investment advisor, Therefore, none of our ALGO STRATEGIES shall be considered as investment advice | Market participants shall use these ALGO STRATEGIES for their knowledge purposes only | CONSULT YOUR INVESTMENT ADVISOR BEFORE INVESTING | DTZ Trading Academy(www.dtzvinoth.com) ISN'T LIABLE FOR YOUR PROFITS OR LOSSES | ALL THE FEES PAID TO DTZ Trading Academy(www.dtzvinoth.com) IS NON - REFUNDABLE | Any dispute arising under these terms and conditions shall be subject to the coimbatore jurisdiction only disclaimer">
    </HorizontalScroll>
    <Typography variant='h6'>
    </Typography>
    </Box>
    <Box sx={{backgroundColor:'primary.main'}}>
    <Container maxWidth="lg">
            <Box sx={{ flexGrow: 1, py: 2.5 }}>
      <Grid container spacing={2}>
        <Grid item xs={6} sx={{display:'flex', justifyContent:'start', gap: {md: 3, xs: 1}, alignItems:'center', flexWrap:'wrap'}}>
            <a href='mailto:sales@dtzvinoth.com' style={{textDecoration:'none'}}>
              <Box sx={{display:'flex', justifyContent:'start', gap: 1}}>
              <MailIcon  sx={{color: 'text.main'}}/>
              <Typography variant='p' sx={{color: 'text.main', fontWeight:'medium'}}>sales@dtzvinoth.com</Typography>
              </Box></a>
            <a href='tel:+91 96775 95880' style={{textDecoration:'none'}}>
            <Box sx={{display:'flex', justifyContent:'start', gap: 1}}>
            <CallIcon sx={{color: 'text.main'}}/>
            <Typography variant='p' sx={{color: 'text.main', fontWeight:'medium'}}>+91 96775 95880</Typography>
            </Box>
            </a>
        </Grid>
        <Grid item xs={6} sx={{display:'flex', justifyContent:'end', gap: 2, alignItems:'center'}}>
        <a href='https://wa.link/l8q5eh' target='_blank' style={{textDecoration:'none'}}>
              <Box sx={{display:'flex', justifyContent:'start', gap: 1}}>
              <WhatsAppIcon  sx={{color: 'text.main'}}/>
              </Box></a>
              <a href='https://www.instagram.com/dtz_vinoth/' target='_blank' style={{textDecoration:'none'}}>
              <Box sx={{display:'flex', justifyContent:'start', gap: 1}}>
              <InstagramIcon  sx={{color: 'text.main'}}/>
              </Box></a>
              <a href='https://t.me/DTZcommunity/' target='_blank' style={{textDecoration:'none'}}>
              <Box sx={{display:'flex', justifyContent:'start', gap: 1}}>
              <TelegramIcon  sx={{color: 'text.main'}}/>
              </Box></a>
              <a href='https://www.youtube.com/@DisciplineTradersZone' target='_blank' style={{textDecoration:'none'}}>
              <Box sx={{display:'flex', justifyContent:'start', gap: 1}}>
              <YouTubeIcon  sx={{color: 'text.main'}}/>
              </Box></a>
        </Grid>
      </Grid>
    </Box>
    </Container>
    </Box>
    <div className={headpos ? 'landHeader':'landHeader1'} style={{position: headpos ?'absolute':'fixed'}}>
    <Container maxWidth="lg">
            <Box sx={{ flexGrow: 1, py: 3, display:{md:'block', xs:'none'} }}>
      <Grid container spacing={2} sx={{alignItems:'center'}}>
        <Grid item xs={3}>
            <img src={Logo} alt='logo' width={200}></img>
        </Grid>
        <Grid item xs={6} sx={{display:'flex', justifyContent:'space-around'}}>
          <Link to='/'><Button variant='text' sx={{color: 'text.main'}}>Home</Button></Link> 
          <Link to='/about'> <Button variant='text' sx={{color: 'text.main'}}>About Us</Button></Link> 
            <Link to='/algoc'><Button variant='text' sx={{color: 'text.main'}}>Algo</Button></Link>
            <Link to='/course'><Button variant='text' sx={{color: 'text.main'}}>Course</Button></Link>
            <Link to='/services'><Button variant='text' sx={{color: 'text.main'}}>Services</Button></Link>
            <Link to='/contact'><Button variant='text' sx={{color: 'text.main'}}>Contact Us</Button></Link>
        </Grid>
        <Grid item xs={3} sx={{textAlign: 'end'}}>
            <Button onClick={handleLoginOpen} size="large" variant='outlined' sx={{marginRight: 2}}>Login</Button>
            <Button onClick={handleRegisterOpen} size="large" variant='contained'>Sign Up</Button>
        </Grid>
      </Grid>
    </Box>
    <Box sx={{ flexGrow: 1, py: 3 , display:{md:'none', xs:'block'}}}>
      <Grid container spacing={1} sx={{alignItems:'center'}}>
      <Grid item xs={1}>
            {menuOpen ? <CloseIcon onClick={handleMenu}/> : <MenuIcon onClick={handleMenu}/>}
            {menuOpen && <Box sx={{background: '#fff', width:'100%', position:'absolute', left: 0, top: 80, display:'flex', flexDirection:'column', justifyContent:'start', alignItems:'start', boxShadow:'0px 5px 5px #eaeaea'}}>
            <Link to='/' onClick={handleMenu} style={{textDecoration:'none'}}><Typography variant="body1" sx={{color: 'text.main', fontSize: 20, p: 2}}>Home</Typography></Link>
            <Link to='/about' onClick={handleMenu} style={{textDecoration:'none'}}> <Typography variant="body1" sx={{color: 'text.main', fontSize: 20, p: 2}}>About Us</Typography></Link>
            <Link to='/algo' onClick={handleMenu} style={{textDecoration:'none'}}><Typography variant="body1" sx={{color: 'text.main', fontSize: 20, p: 2}}>Algo</Typography></Link>
            <Link to='/course' onClick={handleMenu} style={{textDecoration:'none'}}><Typography variant="body1" sx={{color: 'text.main', fontSize: 20, p: 2}}>Course</Typography></Link>
            <Link to='/services' onClick={handleMenu} style={{textDecoration:'none'}}><Typography variant="body1" sx={{color: 'text.main', fontSize: 20, p: 2}}>Services</Typography></Link>
            <Link to='/contact' onClick={handleMenu} style={{textDecoration:'none'}}><Typography variant="body1" sx={{color: 'text.main', fontSize: 20, p: 2}}>Contact Us</Typography></Link>
        </Box>
            }
        </Grid>
        <Grid item xs={5} sx={{textAlign:'center'}}>
            <img src={Logo} alt='logo' width={100}></img>
        </Grid>
        <Grid item xs={6} sx={{textAlign: 'end', display:'flex', justifyContent:'end'}}>
            <Button onClick={handleLoginOpen} variant='outlined' sx={{marginRight: 1}}>Login</Button>
            <Button  onClick={handleRegisterOpen} variant='contained'>Sign Up</Button>
        </Grid>
      </Grid>
    </Box>
    </Container>
    </div>

    <div>
      <Modal
        open={loginopen}
        onClose={handleLoginClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography sx={{fontWeight:'bold'}} id="modal-modal-title" variant="h4" component="h2">
            Welcome Back
          </Typography>
          <Button fullWidth size='large' variant='outlined' sx={{my: 2, py: 1.5}}>Continue With Google</Button>
          <Divider>Or</Divider>
          <Box sx={{mt: 2}}>
          <Typography variant='h6'>Email</Typography>
          <TextField fullWidth id="outlined-basic" placeholder="Enter your email" variant="outlined" sx={{mt: 0.5}}/>
          </Box>
          <Box sx={{mt: 2}}>
          <Typography variant='h6'>Password</Typography>
          <TextField fullWidth id="outlined-basic" placeholder="Password" variant="outlined" sx={{mt: 0.5}}/>
          </Box>
          <Box sx={{mt: 2, display:'flex', justifyContent:'space-between', alignItems:'center'}}>
          <FormGroup>
  <FormControlLabel control={<Checkbox />} label="Remember Me" />
  </FormGroup>
          <Button variant='text'>Forgot Password?</Button>
          </Box>
          <Box sx={{my: 2}}>
            <Button fullWidth variant='contained' sx={{py: 1.5}}>Sign In</Button>
          </Box>
          <Box sx={{display:'flex', justifyContent:'center', alignItems:'center'}}>
            <Typography variant='body1'>Don't have an account?</Typography>
            <Button variant="text">Sign Up</Button>
          </Box>
        </Box>
      </Modal>
    </div>

    <div>
      <Modal
        open={registeropen}
        onClose={handleRegisterClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography sx={{fontWeight:'bold'}} id="modal-modal-title" variant="h4" component="h2">
            Create Your Account
          </Typography>
          <Button fullWidth size='large' variant='outlined' sx={{my: 2, py: 1.5}}>Continue With Google</Button>
          <Divider>Or</Divider>
          <Box sx={{mt: 2}}>
          <Typography variant='h6'>Name</Typography>
          <TextField fullWidth id="outlined-basic" placeholder="Enter your name" variant="outlined" sx={{mt: 0.5}}/>
          </Box>
          <Box sx={{mt: 2}}>
          <Typography variant='h6'>Email</Typography>
          <TextField fullWidth id="outlined-basic" placeholder="Enter your email" variant="outlined" sx={{mt: 0.5}}/>
          </Box>
          <Box sx={{mt: 2}}>
          <Typography variant='h6'>Password</Typography>
          <TextField fullWidth id="outlined-basic" placeholder="Password" variant="outlined" sx={{mt: 0.5}}/>
          </Box>
          <Box sx={{mt: 2}}>
          <Typography variant='h6'>Confirm Password</Typography>
          <TextField fullWidth id="outlined-basic" placeholder="Confirm Password" variant="outlined" sx={{mt: 0.5}}/>
          </Box>
          <Box sx={{my: 2}}>
            <Button fullWidth variant='contained' sx={{py: 1.5}}>Sign Up</Button>
          </Box>
          <Box sx={{display:'flex', justifyContent:'center', alignItems:'center'}}>
            <Typography variant='body1'>Don't have an account?</Typography>
            <Button variant="text">Log In</Button>
          </Box>
        </Box>
      </Modal>
    </div>
    </>
  )
}

export default LandHeader