import * as React from 'react';
import { styled } from '@mui/material/styles';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  '&::before': {
    display: 'none',
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(1, 2),
}));

export default function CustomizedAccordions() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };


  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      color:'#00d094'
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      color:'#00d094'
    },
  }));

  return (
    <div>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary 
        expandIcon={expanded ==='panel1' ? <RemoveCircleOutlineIcon  sx={{ fontSize: '1.5rem' }}/> : <AddCircleOutlineIcon sx={{ fontSize: '1.5rem' }}/>}
        aria-controls="panel1d-content" id="panel1d-header">
          <Typography variant='h6'>
1. What are the benefits of algorithmic trading?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Algorithmic trading offers benefits such as increased efficiency, reduced emotional trading, faster trade execution, and the ability to backtest strategies for improved performance.          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary 
                expandIcon={expanded ==='panel2' ? <RemoveCircleOutlineIcon  sx={{ fontSize: '1.5rem' }}/> : <AddCircleOutlineIcon sx={{ fontSize: '1.5rem' }}/>}
        aria-controls="panel2d-content" id="panel2d-header">
          <Typography variant='h6'>2. How can I enroll in the option buying and selling classes?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          You can enroll in our option buying and selling classes by visiting our website and selecting the desired course. Follow the instructions to complete the enrollment process.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary
        expandIcon={expanded ==='panel3' ? <RemoveCircleOutlineIcon  sx={{ fontSize: '1.5rem' }}/> : <AddCircleOutlineIcon sx={{ fontSize: '1.5rem' }}/>}
         aria-controls="panel3d-content" id="panel3d-header">
          <Typography variant='h6'>
3. What kind of support do you offer during the classes?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          We provide comprehensive support during classes, including live Q&A sessions, interactive discussions, and access to course materials and resources.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary 
        expandIcon={expanded ==='panel4' ? <RemoveCircleOutlineIcon  sx={{ fontSize: '1.5rem' }}/> : <AddCircleOutlineIcon sx={{ fontSize: '1.5rem' }}/>}
        aria-controls="panel1d-content" id="panel1d-header">
          <Typography variant='h6'>4. Can I access recorded sessions if I miss a live class?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Yes, all live sessions are recorded and made available to participants for future reference, ensuring you never miss out on valuable content.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
        <AccordionSummary 
                expandIcon={expanded ==='panel5' ? <RemoveCircleOutlineIcon  sx={{ fontSize: '1.5rem' }}/> : <AddCircleOutlineIcon sx={{ fontSize: '1.5rem' }}/>}
        aria-controls="panel2d-content" id="panel2d-header">
          <Typography variant='h6'>
5. How do I integrate my demat account with your algorithmic trading service?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Our team will guide you through the process of integrating your demat account with our algorithmic trading service, ensuring a seamless setup tailored to your needs.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
        <AccordionSummary
        expandIcon={expanded ==='panel6' ? <RemoveCircleOutlineIcon  sx={{ fontSize: '1.5rem' }}/> : <AddCircleOutlineIcon sx={{ fontSize: '1.5rem' }}/>}
         aria-controls="panel3d-content" id="panel3d-header">
          <Typography variant='h6'>6. Are there any prerequisites for joining the option selling class?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          No, there are no specific prerequisites for joining our option selling class. Our courses are designed to accommodate traders of all levels, from beginners to experienced professionals.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
        <AccordionSummary
        expandIcon={expanded ==='panel7' ? <RemoveCircleOutlineIcon  sx={{ fontSize: '1.5rem' }}/> : <AddCircleOutlineIcon sx={{ fontSize: '1.5rem' }}/>}
         aria-controls="panel3d-content" id="panel3d-header">
          <Typography variant='h6'>
          7. What sets DTZ Trading Academy apart from other trading education platforms?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          DTZ Trading Academy stands out for its comprehensive curriculum, personalized support, cutting-edge technology, and commitment to empowering traders with practical skills and knowledge.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
        <AccordionSummary 
        expandIcon={expanded ==='panel8' ? <RemoveCircleOutlineIcon  sx={{ fontSize: '1.5rem' }}/> : <AddCircleOutlineIcon sx={{ fontSize: '1.5rem' }}/>}
        aria-controls="panel1d-content" id="panel1d-header">
          <Typography variant='h6'>8. Do you offer any discounts or promotions for your services?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          We occasionally offer discounts and promotions on our services. Stay updated by subscribing to our newsletter or following us on social media for the latest offers.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
        <AccordionSummary 
                expandIcon={expanded ==='panel9' ? <RemoveCircleOutlineIcon  sx={{ fontSize: '1.5rem' }}/> : <AddCircleOutlineIcon sx={{ fontSize: '1.5rem' }}/>}
        aria-controls="panel2d-content" id="panel2d-header">
          <Typography variant='h6'>
          9. How can I stay updated on upcoming events and live sessions?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          You can stay updated on upcoming events and live sessions by checking our website's events calendar, subscribing to our newsletter, or following us on social media for announcements.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
        <AccordionSummary
        expandIcon={expanded ==='panel10' ? <RemoveCircleOutlineIcon  sx={{ fontSize: '1.5rem' }}/> : <AddCircleOutlineIcon sx={{ fontSize: '1.5rem' }}/>}
         aria-controls="panel3d-content" id="panel3d-header">
          <Typography variant='h6'>10. What measures do you have in place to ensure the security of my personal and financial information?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          We prioritize the security of our clients' personal and financial information by implementing industry-standard encryption protocols, robust authentication measures, and regular security audits to safeguard data integrity and confidentiality.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}