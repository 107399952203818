import React, {useState, useEffect} from 'react';
import {
  Box, Button, Container, Divider, Grid, TextField, Typography,
} from "@mui/material";
import './style.css';
import takepic from '../../assets/images/takeapick.png';
import temp from '../../assets/images/choosetemp.png';
import studio from '../../assets/images/studio.png';
import arrow1 from '../../assets/images/arrow1.png';
import arrow2 from '../../assets/images/arrow2.png';
import VertTabs from '../../components/tabs/Tabs';
import userAi from '../../assets/images/users_ai.png';
import AIenhance from '../../assets/images/aienhancer.png';
import Easytask from '../../assets/images/easytask.png';
import team from '../../assets/images/team.png';
import figma1 from '../../assets/images/figma_1.png';

import uparrow from '../../assets/images/uparrow.png';
import trader from '../../assets/images/trader.png';
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';
import Reattimedata from '../../assets/images/1 (1).png';
import custSupport from '../../assets/images/customer_support.png';
import highSec from '../../assets/images/high_security.png';
import lowCommis from '../../assets/images/lower_commis.png';
import check from '../../assets/images/check.png';
import ourcommit from '../../assets/images/aboutus.png';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import starery from '../../assets/images/stagery.png';
import financeadvi from '../../assets/images/financeadvi.png';
import management from '../../assets/images/management.png';
import check1 from '../../assets/images/check1.svg';
import MultipleItems from '../../components/carousal/Carousal';
import faq from '../../assets/images/faq.png';
import CustomizedAccordions from '../../components/accordion/Accordion';
import contact from '../../assets/images/contact.png';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import TelegramIcon from '@mui/icons-material/Telegram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import MailIcon from '@mui/icons-material/Mail';
import CallIcon from '@mui/icons-material/Call';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import ScrollToTop from '../../components/ScrollToTop';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 15,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));


const Terms = ({setLoader}) => {


  return (
    <div>
    <ScrollToTop/>
    <Box className='bannerBg'>
    <Container maxWidth="lg" sx={{py: 12}}>
      <Typography variant='h3' sx={{color:'text.white', fontWeight:'bold'}}>Terms and Conditions</Typography>
      <Typography variant='h6' sx={{fontFamily:"'Sora', sans-serif", fontWeight:'medium',  color:'text.white', mt: 3}}>
          <Typography variant='p' sx={{color:'primary.main'}}>Home</Typography> / Terms and Conditions
          </Typography>
</Container>
    </Box>
        <Box className="thirdSec" sx={{position:'relative'}}>
    <Container maxWidth="lg">
    <Typography variant='h4' sx={{color:'primary.main', textAlign:'center', fontWeight:'bold'}}> Terms and Conditions </Typography> 
<Typography variant='h5' sx={{color:'red', fontWeight:'bold'}}> Disclaimer:
</Typography>
 <Typography variant='h6' component='li' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>
 We are not a SEBI registered investment advisor,
</Typography>
 <Typography variant='h6' component='li' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>
 Therefore, none of our ALGO STRATEGIES shall be considered as an investment advice. 
</Typography>
 <Typography variant='h6' component='li' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>
 Market participants shall use these ALGO STRATEGIES for their knowledge purposes only</Typography>
 <Typography variant='h6' component='li' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>
 CONSULT YOUR INVESTMENT ADVISOR BEFORE INVESTING</Typography>
 <Typography variant='h6' component='li' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>
 DTZ Trading Academy(www.dtzvinoth.com) ISN'T LIABLE FOR YOUR PROFITS OR LOSSES.</Typography>
 <Typography variant='h6' component='li' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>
 ALL THE FEES PAID TO DTZ Trading Academy(www.dtzvinoth.com) IS NON - REFUNDABLE.</Typography>
 <Typography variant='h6' component='li' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>
 Any dispute arising under these terms and conditions shall be subject to the coimbatore 
jurisdiction only</Typography>
<Typography variant='h5' sx={{color:'secondary.main', fontWeight:'bold'}}>Overview
</Typography>
 <Typography variant='h6' component='li' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>This website is operated by DTZ Trading Academy(dtzvinoth). Throughout the site, the terms “we”, 
“us” and “our” refer to DTZ Trading Academy(dtzvinoth). DTZ Trading Academy offers this website, 
including all information, tools and services available from this site to you, the user, conditioned 
upon your acceptance of all terms, conditions, policies and notices stated here.

</Typography>
 <Typography variant='h6' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>
 By visiting our site and/ or purchasing something from us, you engage in our “Service” and agree to 
be bound by the following terms and conditions (“Terms of Service”, “Terms”), including those 
additional terms and conditions and policies referenced herein and/or available by hyperlink. These 
Terms of Service apply to all users of the site, including without limitation users who are browsers, 
vendors, customers, merchants, and/ or contributors of content.
</Typography>
 <Typography variant='h6' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>Please read these Terms of Service carefully before accessing or using our website. By accessing or 
using any part of the site, you agree to be bound by these Terms of Service. If you do not agree to all 
the terms and conditions of this agreement, then you may not access the website or use any 
services. If these Terms of Service are considered an offer, acceptance is expressly limited to these 
Terms of Service.
</Typography>
 <Typography variant='h6' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>Any new features or tools which are added to the current store shall also be subject to the Terms of 
Service. You can review the most current version of the Terms of Service at any time on this page. 
We reserve the right to update, change or replace any part of these Terms of Service by posting 
updates and/or changes to our website. It is your responsibility to check this page periodically for 
changes. Your continued use of or access to the website following the posting of any changes 
constitutes acceptance of those changes
</Typography>
 <Typography variant='h6' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>DTZ Trading Academy(dtzvinoth)is a digital service provider which provides you a technology to fully 
automate your trading and minimize your risk.</Typography>
 <Typography variant='h6' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>DTZ Trading Academy will link your demat account with their software through API bridge by which 
it will scan the trade and send it your demat account.
</Typography>
 <Typography variant='h6' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>The Client/investor agrees that all investment and disinvestment decisions are based on the 
client/trader’s evaluation of financial circumstances and investment objectives. </Typography>
<Typography variant='h6' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>The Client/trader will not hold nor seek to hold DTZ Trading Academy(dtzvinoth) or any of its 
officers, directors, partners, employees, agents, subsidiaries, affiliates, or business associates liable 
for any trading losses.
</Typography>
 <Typography variant='h6' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>The fees paid towards “plan subscription charges” for enabling equities and commodities trades, or 
any other services are non-refundable.</Typography>
 <Typography variant='h6' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>There is absolutely zero involvement of DTZ Trading Academy(dtzvinoth) in your profit and loss, 
Client/trader is the sole owner of their profit and losses incurred from trading
</Typography>
 <Typography variant='h6' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>hereby doesn’t commit any false promises with relation to client/trader investment or financial 
decisions.
</Typography>
<Typography variant='h5' sx={{color:'secondary.main', fontWeight:'bold'}}> Do not track
</Typography>
 <Typography variant='h6' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>Please note that we do not alter our Site’s data collection and use practices when we 
see a Do Not Track signal from your browser.
</Typography>
 <Typography variant='h6' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>Client/trader should use the proper trade option to be confident enough on the strategies to be use 
in live market. For more details of our policies visit Privacy Terms on our website.
</Typography>
 <Typography variant='h6' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>DTZ Trading Academy(dtzvinoth) makes no guarantee, that any account will or is likely to achieve 
any profits. Past results do not guarantee future results
</Typography>
 <Typography variant='h6' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>Trading segments such as options, and futures may not be suitable for all investors as they carry a 
degree of risk to your capital.
</Typography>
 <Typography variant='h6' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>You must be aware of the risks and be willing to bear any level of risk to invest in financial markets. 
Past performance is not indicative of future results.
</Typography>
 <Typography variant='h6' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>DTZ Trading Academy(dtzvinoth) and all individuals associated assume no responsibility for your 
trading results or investments. Please ensure that you fully understant the risk involved.
</Typography>
<Typography variant='h5' sx={{color:'secondary.main', fontWeight:'bold'}}>ONLINE STORE TERMS
</Typography>
 <Typography variant='h6' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>By agreeing to these Terms of Service, you represent that you are at least the age of majority in your 
state or province of residence, or that you are the age of majority in your state or province of 
residence and you have given us your consent to allow any of your minor dependents to use this 
site.
</Typography>
 <Typography variant='h6' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>You may not use our products for any illegal or unauthorized purpose nor may you, in the use of the 
Service, violate any laws in your jurisdiction (including but not limited to copyright laws).
</Typography>
 <Typography variant='h6' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>You must not transmit any worms or viruses or any code of a destructive nature.
</Typography>
 <Typography variant='h6' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>A breach or violation of any of the Terms will result in an immediate termination of your Services.
</Typography>
<Typography variant='h5' sx={{color:'secondary.main', fontWeight:'bold'}}>GENERAL CONDITIONS
</Typography>
 <Typography variant='h6' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>We reserve the right to refuse service to anyone for any reason at any time.
</Typography>
 <Typography variant='h6' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>You understand that your content (not including credit card information), may be transferred 
unencrypted and involve (a) transmissions over various networks; and (b) changes to conform and 
adapt to technical requirements of connecting networks or devices. Credit card information is 
always encrypted during transfer over networks.
</Typography>
 <Typography variant='h6' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, use of 
the Service, or access to the Service or any contact on the website through which the service is 
provided, without express written permission by us.
</Typography>
 <Typography variant='h6' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>The headings used in this agreement are included for convenience only and will not limit or 
otherwise affect these Terms.
</Typography>
<Typography variant='h5' sx={{color:'secondary.main', fontWeight:'bold'}}>ACCURACY, COMPLETENESS AND TIMELINESS OF INFORMATION
</Typography>
 <Typography variant='h6' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>The headings used in this agreement are included for convenience only and will not limit or 
otherwise affect these Terms.
</Typography>
 <Typography variant='h6' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>This site may contain certain historical information. Historical information, necessarily, is not current 
and is provided for your reference only. We reserve the right to modify the contents of this site at 
any time, but we have no obligation to update any information on our site. You agree that it is your 
responsibility to monitor changes to our site.
</Typography>
<Typography variant='h5' sx={{color:'secondary.main', fontWeight:'bold'}}>PRODUCT / SERVICE
</Typography>
 <Typography variant='h6' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>Certain products or services may be available exclusively online through the website. We do not 
warrant that the quality of any products, services, information, or other material purchased or 
obtained by you will meet your expectations, or that any errors in the Service will be corrected. 
Imitations of our product are not allowed, you agree that we have the right at anytime to remove 
your access and ban you from our server if suspected of imitating in any form.
</Typography>
<Typography variant='h5' sx={{color:'secondary.main', fontWeight:'bold'}}>SECTION 6 - REFUNDS
</Typography>
 <Typography variant='h6' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>All subscription fees paid to DTZ Trading Academy(dtzvinoth) are not refundable. We do not provide 
trading tips nor we are investment advisers. Our service is solely restricted to automated trading 
application development, deployment, and maintenance.
</Typography>
 <Typography variant='h6' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>Please refer to Refund and Cancellation Policy. 
</Typography>
<Typography variant='h5' sx={{color:'secondary.main', fontWeight:'bold'}}>PROHIBITED USES
</Typography>
 <Typography variant='h6' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>In addition to other prohibitions as set forth in the Terms of Service, you are prohibited from using 
the site or its content:
</Typography>
 <Typography variant='h6' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>(a) for any unlawful purpose;
</Typography>
 <Typography variant='h6' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>(b) to solicit others to perform or participate in any unlawful acts;
</Typography>
 <Typography variant='h6' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>(c) to violate any international, federal, provincial or state regulations, rules, laws, or local 
ordinances;
</Typography>
 <Typography variant='h6' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>(d) to infringe upon or violate our intellectual property rights or the intellectual property rights of 
others;
</Typography>
 <Typography variant='h6' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>(e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on 
gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability;
</Typography>
 <Typography variant='h6' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>(f) to submit false or misleading information;
</Typography>
 <Typography variant='h6' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>(g) to upload or transmit viruses or any other type of malicious code that will or may be used in any 
way that will affect the functionality or operation of the Service or of any related website, other 
websites, or the Internet;
</Typography>
 <Typography variant='h6' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>(h) to collect or track the personal information of others;
</Typography>
 <Typography variant='h6' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>(i) to spam, phish, pharm, pretext, spider, crawl, or scrape;
</Typography>
 <Typography variant='h6' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>(j) for any obscene or immoral purpose; or
</Typography>
 <Typography variant='h6' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>(k) to interfere with or circumvent the security features of the Service or any related website, other 
websites, or the Internet;
</Typography>
 <Typography variant='h6' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>(l) forge headers or otherwise manipulate identifiers in order to disguise the origin of any 
information transmitted through the Services;
</Typography>
 <Typography variant='h6' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>(m) “frame” or “mirror” any part of the Services, without our prior written authorization or use meta 
tags or code or other devices containing any reference to us in order to direct any person to any 
other service or Web site for any purpose;
</Typography>
 <Typography variant='h6' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>(n) modify, adapt, translate, reverse engineer, decipher, decompile, separate, or otherwise 
disassemble any portion of the Services or any software used on or for the Services; or
</Typography>
 <Typography variant='h6' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>(o) to interfere with or circumvent the security features of the Services.
</Typography>
 <Typography variant='h6' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>We reserve the right to terminate your use of the Service or any related website for violating any of 
the prohibited uses.
</Typography>
<Typography variant='h5' sx={{color:'secondary.main', fontWeight:'bold'}}>DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY
</Typography>
 <Typography variant='h6' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>We do not guarantee, represent or warrant that your use of our service will be uninterrupted, 
timely, secure or error-free.
</Typography>
 <Typography variant='h6' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>We do not warrant that the results that may be obtained from the use of the service will be accurate 
or reliable.
</Typography>
 <Typography variant='h6' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>You expressly agree that your use of, or inability to use, the service is at your sole risk. The service 
and all products and services delivered to you through the service are (except as expressly stated by 
us) provided ‘as is’ and ‘as available’ for your use, without any representation, warranties or 
conditions of any kind, either express or implied, including all implied warranties or conditions of 
merchantability, merchantable quality, fitness for a particular purpose, durability, title, and noninfringement.
</Typography>
 <Typography variant='h6' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>In no case shall DTZ Trading Academy(dtzvinoth) our directors, officers, employees, affiliates, agents, 
contractors, interns, suppliers, service providers or licensors be liable for any injury, loss, claim, or 
any direct, indirect, incidental, punitive, special, or consequential damages of any kind, including, 
without limitation lost profits, lost revenue, lost savings, loss of data, replacement costs, or any 
similar damages, whether based in contract, tort (including negligence), strict liability or otherwise, 
arising from your use of any of the service or any products procured using the service, or for any 
other claim related in any way to your use of the service or any product, including, but not limited 
to, any errors or omissions in any content, or any loss or damage of any kind incurred as a result of 
the use of the service or any content (or product) posted, transmitted, or otherwise made available 
via the service, even if advised of their possibility. Because some states or jurisdictions do not allow 
the exclusion or the limitation of liability for consequential or incidental damages, in such states or 
jurisdictions, our liability shall be limited to the maximum extent permitted by law.
</Typography>
<Typography variant='h5' sx={{color:'secondary.main', fontWeight:'bold'}}>INDEMNIFICATION
</Typography>
 <Typography variant='h6' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>You agree to indemnify, defend and hold harmless DTZ Trading Academy(dtzvinoth) and our parent, 
subsidiaries, affiliates, partners, officers, directors, agents, contractors, licensors, service providers, 
subcontractors, suppliers, interns and employees, harmless from any claim or demand, including 
reasonable attorneys’ fees, made by any third-party due to or arising out of your breach of these 
Terms of Service or the documents they incorporate by reference, or your violation of any law or the 
rights of a third-party.
</Typography>
<Typography variant='h5' sx={{color:'secondary.main', fontWeight:'bold'}}>CHANGES TO TERMS OF SERVICE
</Typography>
 <Typography variant='h6' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>We reserve the right, at our sole discretion, to update, change or replace any part of these Terms of 
Service by posting updates and changes to our website. It is your responsibility to check our website 
periodically for changes. Your continued use of or access to our website or the Service following the 
posting of any changes to these Terms of Service constitutes acceptance of those changes.
</Typography>
<Typography variant='h5' sx={{color:'secondary.main', fontWeight:'bold'}}>CONTACT INFORMATION
</Typography>
 <Typography variant='h6' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>
 Questions about the Terms of Service should be sent to us at disciplinetraderszone@gmail.com</Typography>
<Typography variant='h5' sx={{color:'secondary.main', fontWeight:'bold'}}>Note:-
</Typography>
 <Typography variant='h6' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>Any dispute arising under these terms and conditions shall be subject to the coimbatore 
jurisdiction only.
</Typography>
    </Container>
        </Box>
    </div>
  )
}

export default Terms