import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from "react-redux";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import {Link, Navigate, useNavigate } from 'react-router-dom';

import { login } from "../../slices/auth";
import { clearMessage } from "../../slices/message";
import logo from '../../assets/images/logo.png';
import './style.css';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {CustInput, CustPassInput} from '../../components/input/CustInput';

const Login = ({setLoader}) => {

    const [forgot, setForgot] = useState(false);
    const [verify, setVerify] = useState(false);


    const navigate = useNavigate();

    const { isLoggedIn } = useSelector((state) => state.auth);
    const { message } = useSelector((state) => state.message);

    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(clearMessage());
    }, [dispatch]);

    const [userCredentials, setUserCredentials] = useState({
        username: "",
        password: ""
      });
    
      const [showpassword, setShowpassword] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoader(true)
        dispatch(login(userCredentials))
        .unwrap()
        .then(() => {
          navigate("/");
          window.location.reload();
        })
        .catch(()=>{
          setLoader(false);
        })
      };
    
      const handleClickShowPassword = () => {
        setShowpassword(!showpassword);
      };
    
      const handleMouseDownPassword = (event) => {
        event.preventDefault();
      };

      if (isLoggedIn) {
        return <Navigate to="/" />;
      }

  return (
    <Grid container sx={{height:'100vh'}}>
  <Grid item xs={6} sx={{ display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column', overflow:'hidden', position:'relative'}}>
  <div className='gradCircle6'></div>
  <Link to='/'>
  <ArrowBackIcon className='arrow'/>
  </Link>
    <Box>
    <img src={logo} alt='logo' width={300}></img>
    <Typography variant='h5' sx={{lineHeight: '35px'}}>Empowering Identity, <br></br>
One Smart Card at a Time</Typography>
    </Box>
    <div className='gradCircle7'></div>
  </Grid>
  <Grid item xs={6}     sx={{bgcolor: `primary.light`, display:'flex', justifyContent:'center', alignItems:'start', position:'relative'}}>
  {!forgot && 
  <>
  <Box
    component="form"
    noValidate
    autoComplete="off"
    onSubmit={handleSubmit}>
        <Paper sx={{width: 500, p: 10, boxShadow:'none', bgcolor:'transparent'}}>
            <Typography variant='h4' sx={{textAlign: 'start', fontWeight:'bold', mb: 1}}>Login</Typography>
            <Typography variant='h6' sx={{textAlign: 'start', mb: 1}}>Welcome Back</Typography>
            <Grid container spacing={2}>
            <Grid item xs={12}>
            <CustInput placeholder="Enter email id/ Phone number" type="text" label="Email ID/ Phone Number"/>
                </Grid>
                <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end'}}>
                    <CustPassInput placeholder="Enter password" type="password" label="Password"/>
                    <Button variant='text' onClick={()=> setForgot(true)}>Forgot Password?</Button>
                </Grid>
                <Grid item xs={12}>
                    <Button fullWidth type='submit' variant='contained' sx={{py: 1.5, marginTop: 0}}>Login</Button>
                </Grid>
            </Grid>
            {message && (
        <div className="form-group">
          <div className="alert alert-danger" role="alert">
            {message}
          </div>
        </div>
      )}
        </Paper>
    </Box>
    <div className='policy_content'>
      <Typography >Privacy Policy</Typography>
      <Typography>Terms of Service</Typography>
    </div>
    </>
  }
  {(!verify && forgot) && 
  <>
  <Box
    component="form"
    noValidate
    autoComplete="off"
    onSubmit={handleSubmit}>
        <Paper sx={{width: 500, p: 10, boxShadow:'none', bgcolor:'transparent'}}>
            <Typography variant='h4' sx={{textAlign: 'start', fontWeight:'bold', mb: 1}}>Verify OTP</Typography>
            <Typography variant='h6' sx={{textAlign: 'start', mb: 1}}>OTP has been sent to +91 9876543210 and 9876543210@gmail.com</Typography>
            <Grid container spacing={2}>
            <Grid item xs={12} sx={{display:'flex', justifyContent:'space-between', gap: 5}}>
            <CustInput placeholder="_" type="number"/>
            <CustInput placeholder="_" type="number"/>
            <CustInput placeholder="_" type="number"/>
            <CustInput placeholder="_" type="number"/>
                </Grid>
                <Grid item xs={12}>
                    <Button fullWidth type='submit' onClick={()=> setVerify(true)} variant='contained' sx={{py: 1.5, marginTop: 0}}>Verify</Button>
                </Grid>
            </Grid>
            {message && (
        <div className="form-group">
          <div className="alert alert-danger" role="alert">
            {message}
          </div>
        </div>
      )}
        </Paper>
    </Box>
    </>
  }
    {verify && 
  <>
  <Box
    component="form"
    noValidate
    autoComplete="off"
    onSubmit={handleSubmit}>
        <Paper sx={{width: 500, p: 10, boxShadow:'none', bgcolor:'transparent'}}>
            <Typography variant='h4' sx={{textAlign: 'start', fontWeight:'bold', mb: 1}}>Set New Password</Typography>
            <Typography variant='h6' sx={{textAlign: 'start', mb: 1}}>Enter your new password</Typography>
            <Grid container spacing={2}>
            <Grid item xs={12} sx={{display:'flex', justifyContent:'space-between', gap: 5}}>
            <CustPassInput placeholder="Set password" type="password" />
                </Grid>
                <Grid item xs={12}>
                    <Button fullWidth type='submit' variant='contained' sx={{py: 1.5, marginTop: 0}}>Change</Button>
                </Grid>
            </Grid>
            {message && (
        <div className="form-group">
          <div className="alert alert-danger" role="alert">
            {message}
          </div>
        </div>
      )}
        </Paper>
    </Box>
    </>
  }
  </Grid>
</Grid>
  )
}

export default Login