import React, {useState, useEffect} from 'react';
import {
  Box, Button, Container, Divider, Grid, TextField, Typography,
} from "@mui/material";
import './style.css';
import takepic from '../../assets/images/takeapick.png';
import temp from '../../assets/images/choosetemp.png';
import studio from '../../assets/images/studio.png';
import arrow1 from '../../assets/images/arrow1.png';
import arrow2 from '../../assets/images/arrow2.png';
import VertTabs from '../../components/tabs/Tabs';
import userAi from '../../assets/images/users_ai.png';
import AIenhance from '../../assets/images/aienhancer.png';
import Easytask from '../../assets/images/easytask.png';
import team from '../../assets/images/team.png';
import figma1 from '../../assets/images/figma_1.png';

import uparrow from '../../assets/images/uparrow.png';
import trader from '../../assets/images/trader.png';
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';
import Reattimedata from '../../assets/images/1 (1).png';
import custSupport from '../../assets/images/customer_support.png';
import highSec from '../../assets/images/high_security.png';
import lowCommis from '../../assets/images/lower_commis.png';
import check from '../../assets/images/check.png';
import ourcommit from '../../assets/images/aboutus.png';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import starery from '../../assets/images/stagery.png';
import financeadvi from '../../assets/images/financeadvi.png';
import management from '../../assets/images/management.png';
import check1 from '../../assets/images/check1.svg';
import MultipleItems from '../../components/carousal/Carousal';
import faq from '../../assets/images/faq.png';
import CustomizedAccordions from '../../components/accordion/Accordion';
import contact from '../../assets/images/contact.png';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import TelegramIcon from '@mui/icons-material/Telegram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import MailIcon from '@mui/icons-material/Mail';
import CallIcon from '@mui/icons-material/Call';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import ScrollToTop from '../../components/ScrollToTop';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 15,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));


const Privacy = ({setLoader}) => {


  return (
    <div>
    <ScrollToTop/>
    <Box className='bannerBg'>
    <Container maxWidth="lg" sx={{py: 12}}>
      <Typography variant='h3' sx={{color:'text.white', fontWeight:'bold'}}>Privacy Policy</Typography>
      <Typography variant='h6' sx={{fontFamily:"'Sora', sans-serif", fontWeight:'medium',  color:'text.white', mt: 3}}>
          <Typography variant='p' sx={{color:'primary.main'}}>Home</Typography> / Privacy Policy
          </Typography>
</Container>
    </Box>
        <Box className="thirdSec" sx={{position:'relative'}}>
    <Container maxWidth="lg">
    <Typography variant='h4' sx={{color:'primary.main', textAlign:'center', fontWeight:'bold'}}> Privacy Policy </Typography> 
    <Typography variant='h6' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>This Privacy Policy describes how your personal information is collected, used, and 
shared when you visit or make a purchase from dtzvinoth.com (the “Site”).
</Typography>
<Typography variant='h5' sx={{color:'secondary.main', fontWeight:'bold'}}> Personal Information we collect
</Typography>
 <Typography variant='h6' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>When you visit the Site, we automatically collect certain information about your 
device, including information about your web browser, IP address, time zone, and 
some of the cookies that are installed on your device. Additionally, as you browse 
the Site, we collect information about the individual web pages or products that you 
view, what websites or search terms referred you to the Site, and information about 
how you interact with the Site. We refer to this automatically-collected information as 
“Device Information”.
</Typography>
<Typography variant='h5' sx={{color:'secondary.main', fontWeight:'bold'}}> We collect Device Information using the following 
technologies:
</Typography>
 <Typography variant='h6' component='li' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>“Cookies” are data files that are placed on your device or computer and often 
include an anonymous unique identifier. For more information about cookies, 
and how to disable cookies, visit http://www.allaboutcookies.org.
– “Log files” track actions occurring on the Site, and collect data including your 
IP address, browser type, Internet service provider, referring/exit pages, and 
date/time stamps.
</Typography>
 <Typography variant='h6' component='li' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>
 “Web beacons”, “tags”, and “pixels” are electronic files used to record 
information about how you browse the Site.
Additionally when you make a purchase or attempt to make a purchase 
through the Site, we collect certain information from you, including your name, 
payment information (including credit card numbers, email address, and 
phone number). We refer to this information as “Order Information”.
When we talk about “Personal Information” in this Privacy Policy, we are 
talking both about Device Information and Order Information.
</Typography>
<Typography variant='h5' sx={{color:'secondary.main', fontWeight:'bold'}}> How do we use your Personal Information?
</Typography>
 <Typography variant='h6' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>We use the Order Information that we collect generally to fulfill any orders placed 
through the Site.
</Typography>
 <Typography variant='h6' component='li' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>Additionally, we use this Order Information to:
</Typography>
 <Typography variant='h6' component='li' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}> Communicate with you;</Typography>
 <Typography variant='h6' component='li' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>Screen our orders for potential risk or fraud; and
</Typography>
 <Typography variant='h6' component='li' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>When in line with the preferences you have shared with us, provide you with 
information or
advertising relating to our products or services. </Typography>
<Typography variant='h6' component='li' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}> Display information using third party apps on our website.
</Typography>
 <Typography variant='h6' component='li' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>Set up your accounts with our products.</Typography>
 <Typography variant='h6' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>We use the Device Information that we collect to help us screen for potential risk and 
fraud (in particular, your IP address), and more generally to improve and optimize 
our Site (for example, by generating analytics about how our customers browse and 
interact with the Site, and to assess the success of our marketing and advertising 
campaigns).
</Typography>
<Typography variant='h5' sx={{color:'secondary.main', fontWeight:'bold'}}> Sharing you personal Information
</Typography>
 <Typography variant='h6' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>We share your Personal Information with third parties to help us use your Personal 
Information, as described above.
We also use Google Analytics to help us understand how our customers use the Site 
— you can read more about how Google uses your Personal Information here: 
https://www.google.com/intl/en/policies/privacy/. You can also opt-out of Google 
Analytics here: https://tools.google.com/dlpage/gaoptout.
Finally, we may also share your Personal Information to comply with applicable laws 
and regulations, to respond to a subpoena, search warrant or other lawful request for 
information we receive, or to otherwise protect our rights.
</Typography>
<Typography variant='h5' sx={{color:'secondary.main', fontWeight:'bold'}}> Do not track
</Typography>
 <Typography variant='h6' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>Please note that we do not alter our Site’s data collection and use practices when we 
see a Do Not Track signal from your browser.
</Typography>
<Typography variant='h5' sx={{color:'secondary.main', fontWeight:'bold'}}> Your rights
</Typography>
 <Typography variant='h6' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>If you are a European resident, you have the right to access personal information we 
hold about you and to ask that your personal information be corrected, updated, or 
deleted. If you would like to exercise this right, please contact us through the contact 
information below.
Additionally, if you are a European resident we note that we are processing your
information in order to fulfill contracts we might have with you (for example if you 
make an order through the
Site), or otherwise to pursue our legitimate business interests listed above.
Additionally, please note that your information will be transferred outside of Europe, 
including to Canada and the United States.
</Typography>
<Typography variant='h5' sx={{color:'secondary.main', fontWeight:'bold'}}> California Residents
</Typography>
 <Typography variant='h6' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>California Civil Code Section 1798.83, also known as the “Shine The Light” law, 
permits our users who are California residents to request and obtain from us, once a 
year and free of charge, information about categories of personal information (if any) 
we disclosed to third parties for direct marketing purposes and the names and 
addresses of all third parties with which we shared personal information in the 
immediately preceding calendar year. If you are a California resident and would like 
to make such a request, please submit your request in writing to us using the contact 
information provided below.
</Typography>
 <Typography variant='h6' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>If you are under 18 years of age, reside in California, and have a registered account 
with the Site, you have the right to request removal of unwanted data that you 
publicly post on the Website. To request removal of such data, please contact us 
using the contact information provided below, and include the email address 
associated with your account and a statement that you reside in
California. We will make sure the data is not publicly displayed on the Website, but 
please be aware that the data may not be completely or comprehensively removed 
from all our systems (e.g. backups, etc.).
</Typography>
<Typography variant='h5' sx={{color:'secondary.main', fontWeight:'bold'}}> Data retention
</Typography>
 <Typography variant='h6' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>When you place an order through the Site, we will maintain your Order Information 
for our records unless and until you ask us to delete this information.
</Typography>
<Typography variant='h5' sx={{color:'secondary.main', fontWeight:'bold'}}> Changes
</Typography>
 <Typography variant='h6' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>We may update this privacy policy from time to time in order to reflect, for example, 
changes to our practices or for other operational, legal or regulatory reasons.
The updated version will be indicated by an updated “Revised” date and the updated 
version will be effective as soon as it is accessible.
If we make material changes to this privacy notice, we may notify you either by 
prominently posting a notice of such changes or by directly sending you a 
notification.
</Typography>
 <Typography variant='h6' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>We encourage you to review this privacy notice frequently to be informed of how we 
are protecting your information.
</Typography>
<Typography variant='h5' sx={{color:'secondary.main', fontWeight:'bold'}}>CHANGES TO TERMS OF SERVICE
</Typography>
 <Typography variant='h6' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>We may update this privacy policy from time to time in order to reflect, for example, 
changes to our practices or for other operational, legal or regulatory reasons. The 
updated version will be indicated by an updated “Revised” date and the updated 
version will be effective as soon as it is accessible. If we make material changes to 
this privacy notice, we may notify you either by
prominently posting a notice of such changes or by directly sending you a 
notification. We encourage you to review this privacy notice frequently to be informed 
of how we are protecting your information.
</Typography>
<Typography variant='h5' sx={{color:'secondary.main', fontWeight:'bold'}}>Contact us
</Typography>
 <Typography variant='h6' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>For more information about our privacy practices, if you have questions,
or if you would like to make a complaint, please contact us by email at 
disciplinetraderszone@gmail.com
</Typography>
<Typography variant='h5' sx={{color:'secondary.main', fontWeight:'bold'}}>Note:-
</Typography>
 <Typography variant='h6' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>Any dispute arising under these terms and conditions shall be subject to the coimbatore 
jurisdiction only.
</Typography>
    </Container>
        </Box>
    </div>
  )
}

export default Privacy