import React, {useState, useEffect} from 'react';
import {
  Box, Button, Container, Divider, Grid, TextField, Typography,
} from "@mui/material";
import './style.css';
import takepic from '../../assets/images/takeapick.png';
import temp from '../../assets/images/choosetemp.png';
import studio from '../../assets/images/studio.png';
import arrow1 from '../../assets/images/arrow1.png';
import arrow2 from '../../assets/images/arrow2.png';
import VertTabs from '../../components/tabs/Tabs';
import userAi from '../../assets/images/users_ai.png';
import AIenhance from '../../assets/images/aienhancer.png';
import Easytask from '../../assets/images/easytask.png';
import team from '../../assets/images/team.png';
import figma1 from '../../assets/images/figma_1.png';

import uparrow from '../../assets/images/uparrow.png';
import trader from '../../assets/images/trader.png';
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';
import Reattimedata from '../../assets/images/1 (1).png';
import custSupport from '../../assets/images/customer_support.png';
import highSec from '../../assets/images/high_security.png';
import lowCommis from '../../assets/images/lower_commis.png';
import check from '../../assets/images/check.png';
import ourcommit from '../../assets/images/aboutus.png';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import starery from '../../assets/images/stagery.png';
import financeadvi from '../../assets/images/financeadvi.png';
import management from '../../assets/images/management.png';
import check1 from '../../assets/images/check1.svg';
import MultipleItems from '../../components/carousal/Carousal';
import faq from '../../assets/images/faq.png';
import CustomizedAccordions from '../../components/accordion/Accordion';
import contact from '../../assets/images/contact.png';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import TelegramIcon from '@mui/icons-material/Telegram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import MailIcon from '@mui/icons-material/Mail';
import CallIcon from '@mui/icons-material/Call';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import ScrollToTop from '../../components/ScrollToTop';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 15,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));


const Refund = ({setLoader}) => {


  return (
    <div>
    <ScrollToTop/>
    <Box className='bannerBg'>
    <Container maxWidth="lg" sx={{py: 12}}>
      <Typography variant='h3' sx={{color:'text.white', fontWeight:'bold'}}>Refund & Cancellation Policy</Typography>
      <Typography variant='h6' sx={{fontFamily:"'Sora', sans-serif", fontWeight:'medium',  color:'text.white', mt: 3}}>
          <Typography variant='p' sx={{color:'primary.main'}}>Home</Typography> / Refund & Cancellation Policy
          </Typography>
</Container>
    </Box>
        <Box className="thirdSec" sx={{position:'relative'}}>
    <Container maxWidth="lg">
    <Typography variant='h4' sx={{color:'primary.main', textAlign:'center', fontWeight:'bold'}}> REFUND & CANCELLATION POLICY </Typography> 
    <Typography variant='h6' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>All subscription fees paid to DTZ Trading Academy(dtzvinoth) are not refundable.
We do not provide trading tips nor we are investment advisers.
Our service is solely restricted to automated trading application development, 
deployment, and maintenance.
The information provided on this website or associated services are neither 
replacement for a professional “investment adviser” nor it claims to be a substitute 
for “financial planning”.
We strongly advise you to take consulting from SEBI recognized “investment 
adviser” before deciding what percentage of your asset can be exposed to the equity 
market.
Advice received via this website or Telegram or YOUTUBE or our services should 
not be relied upon for personal, medical, legal, or financial decisions.
All such information, software, products, services, and related graphics are provided 
“AS IS” without a warranty of any kind.
In no event shall DTZ Trading Academy(dtzvinoth be liable for any direct, indirect, 
punitive, incidental, special, consequential damages or any damages whatsoever 
including without limitation, damages for loss of use, data, or profits, arising out of or 
in any way connected with the use or performance of this website, with the delay or 
inability to use this website, the provision of or failure to provide services, or for any 
information, software, products, services and related graphics obtained through this 
website, or otherwise arising out of the use of this website, whether based on 
contract, tort, strict liability or otherwise, even if DTZ Trading Academy(dtzvinoth has 
been advised of the possibility of damages.
If you are dissatisfied with any portion of this website, or with any of these terms of 
use, your sole and exclusive remedy is to discontinue using this website and 
associated services. The stock market is subject to market risk.
Please read the associated document carefully or take help from SEBI recognized 
professionals to understand the finer points & details of the various financial 
products.
All algorithms are based on backtested data but we do not provide any guarantee for 
their performance in the future.
The algorithm running in an automated system is agreed upon with the user prior to 
deployment and we do not take any liability for any loss generated by the same.
Past performance of advice/strategy/model does not indicate the future performance 
of any current or future strategy/model or advice by DTZ Trading Academy(dtzvinoth
Trades and actual returns may differ significantly from that depicted herein due to 
various factors including but not limited to impact costs, expense charged, the timing 
of entry/exit, the timing of additional flows/redemptions, individual client mandates, 
specific portfolio construction characteristics, etc.
There is no assurance or guarantee that the objectives of any strategy/model or 
advice provided by DTZ Trading Academy(dtzvinoth Trades will be achieved.
DTZ Trading Academy(dtzvinoth Trades or any of its partner/s principal 
officer/employees do not assure/give a guarantee for any return on the investment in 
strategies/models/advice given to the Investor.
The value of an investment can go up/down depending on factors & forces affecting
securities markets.
DTZ Trading Academy(dtzvinoth Trades or its associates are not liable or 
responsible for any loss or shortfall arising from operations and affected by the 
market condition. By visiting www.dtzvinoth.com you automatically indicate that you 
agree to Indemnity, Disclaimer & Disclosure Notice, and General Content Disclaimer 
Notice and indemnify www.dtzvinoth.com, its associates, and related parties of all 
claims howsoever resulting from the usage of the forum/site.
</Typography>
 <Typography variant='h6' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>All names or products mentioned are trademarks or registered trademarks of their 
respective owners
</Typography>
<Typography variant='h5' sx={{color:'secondary.main', fontWeight:'bold'}}>Note:-
</Typography>
 <Typography variant='h6' sx={{color:'text.main', fontSize:'18px', fontWeight:'normal', my: 3}}>Any dispute arising under these terms and conditions shall be subject to the coimbatore 
jurisdiction only.
</Typography>
    </Container>
        </Box>
    </div>
  )
}

export default Refund