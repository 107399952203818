import React, { useEffect } from 'react';
import { Box, Grid, Button } from '@mui/material';
import CustomTable from '../../components/table/Table';
import Addcategory from './Addcategory';
import { useDispatch, useSelector } from "react-redux";
import { getcategory } from "../../slices/category";

const columns = [
  { id: 'sno', label: 'S.No', minWidth: 150, align:'start' },
  { id: 'category', label: 'Category', minWidth: 150, align:'start' },
  { id: 'img', label: 'Image', minWidth: 150, align:'center' },
  { id: 'action', label: 'Action', minWidth: 100, align:'center', actionType: [{edit: true, delete: true}] },
];

const rows = [
  {sno: '01', category: 'Cow', img: 'url'},
  {sno: '02', category: 'Dog', img: 'url'}
];

const Category = ({setLoader}) => {

  const animal = useSelector((state) => state.category);

  console.log(animal);

  const dispatch = useDispatch();

  useEffect(()=>{
    setLoader(true)
      dispatch(getcategory())
      .then(() => {
        setLoader(false);
      })
      .catch(()=>{
        setLoader(false);
      })
  }, [])
  return (
    <div className="content">
      <Box>
        <Grid container spacing={2} sx={{ paddingBottom: 2, alignItems:'center' }}>
          <Grid item xs={6} sx={{paddingTop: 0}}>
            Manage Category
          </Grid>
          <Grid item xs={6} sx={{textAlign: 'end', paddingTop: 0}}>
            <Addcategory/>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <CustomTable columns={columns} rows={rows} />
      </Box>
    </div>
  );
}

export default Category;