import React, {useState, useEffect} from 'react';
import {
  Box, Button, Card, Container, Divider, Grid, Paper, TextField, Typography,
} from "@mui/material";
import './style.css';
import takepic from '../../assets/images/takeapick.png';
import temp from '../../assets/images/choosetemp.png';
import studio from '../../assets/images/studio.png';
import arrow1 from '../../assets/images/arrow1.png';
import arrow2 from '../../assets/images/arrow2.png';
import VertTabs from '../../components/tabs/Tabs';
import userAi from '../../assets/images/users_ai.png';
import AIenhance from '../../assets/images/aienhancer.png';
import Easytask from '../../assets/images/easytask.png';
import team from '../../assets/images/team.png';
import figma1 from '../../assets/images/figma_1.png';

import uparrow from '../../assets/images/uparrow.png';
import trader from '../../assets/images/trader.png';
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';
import Reattimedata from '../../assets/images/1 (1).png';
import custSupport from '../../assets/images/customer_support.png';
import highSec from '../../assets/images/high_security.png';
import lowCommis from '../../assets/images/lower_commis.png';
import check from '../../assets/images/check.png';
import ourcommit from '../../assets/images/aboutus.png';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import starery from '../../assets/images/stagery.png';
import financeadvi from '../../assets/images/financeadvi.png';
import management from '../../assets/images/management.png';
import check1 from '../../assets/images/check1.svg';
import MultipleItems from '../../components/carousal/Carousal';
import faq from '../../assets/images/faq.png';
import CustomizedAccordions from '../../components/accordion/Accordion';
import contact from '../../assets/images/contact.png';
import ScrollToTop from '../../components/ScrollToTop';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 15,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));


const Algo = ({setLoader}) => {

const [step, setStep] = useState('S1');

const [astep, setaStep] = useState('S1');

  return (
    <div>
    <ScrollToTop/>
    <Box className='bannerBg'>
    <Container maxWidth="lg" sx={{py: 12}}>
      <Typography variant='h3' sx={{color:'text.white', fontWeight:'bold'}}>Algo</Typography>
      <Typography variant='h6' sx={{fontFamily:"'Sora', sans-serif", fontWeight:'medium',  color:'text.white', mt: 3}}>
          <Typography variant='p' sx={{color:'primary.main'}}>Home</Typography> / Algo
          </Typography>
</Container>
    </Box>
        <Box className="thirdSec" sx={{position:'relative'}}>
    <Container maxWidth="lg">
    <Grid container spacing={2}>
    <Grid className="commitImg" item xs={12} md={6}>
      <img src={ourcommit} alt='ourcommit' width={400}></img>
      <Box className="commitProgress">
        <Typography variant='h5' sx={{fontWeight:'bold', color:'secondary.main'}}>10 Years</Typography>
        <Typography variant='body1'>Consulting Experience</Typography>
     </Box>
     <Box className="commitProgress1">
        <Typography variant='h5' sx={{fontWeight:'bold', color:'secondary.main'}}>25k+</Typography>
        <Typography variant='body1'>Satisfied Customers</Typography>
     </Box>
  </Grid>
  <Grid item xs={12} md={6}>
  <Typography variant='h4' sx={{fontFamily:"'Sora', sans-serif", fontWeight:'bold',  color:'text.main', mt: 3, lineHeight: 1.5}}>Revolutionize Your
          <Typography variant='p' sx={{color:'secondary.main'}}> Trading Experience </Typography>  with DTZ's Advanced Algorithmic Trading
          </Typography>
          <Typography  component='p' sx={{fontSize: '19px', fontWeight:'normal', color:'text.light', my: 3}}>Experience the future of trading with DTZ's cutting-edge algorithmic trading service. Our sophisticated algorithms leverage real-time data analysis and automation to optimize your trading strategies and maximize your potential returns. Join us and gain a competitive edge in the financial markets, as we redefine the way you trade.</Typography>
        <Button size="large" variant='contained'>Explore More</Button>
  </Grid>
</Grid>
    </Container>
        </Box>
        {/* <Box className="fifthSec" >
    <Container maxWidth="lg">
          <Typography variant='h3' sx={{fontFamily:"'Sora', sans-serif", fontWeight:'bold', textAlign:'center', color:'text.main'}}>Our
           <Typography className='priceTitle' variant='p' sx={{color:'secondary.main'}}> Pricings</Typography> Plan
          </Typography>
          <Typography variant='h6' sx={{color:'text.light', fontSize:'18px', fontWeight:'normal', my: 3, textAlign:'center'}}>Our Pricing Plans cater to all individuals within our community, ensuring accessibility and inclusivity for everyone in our society</Typography>
          <Grid container spacing={2} sx={{mt: 5, alignItems:'center'}}>
              <Grid item xs={12} md={3}>
                  <Box className="pricings" sx={{background:'#fff', borderRadius: 4, p: 2, border:'2px solid #fff', boxShadow:'0 16px 40px 0 rgba(26,64,137,.06)'}}>
                  <Typography variant='h5' sx={{fontWeight:'bold', mt: 1}}>Explore With Free Plan</Typography>
                  <Typography variant='p' sx={{color:'secondary.main', fontWeight:'bold', mt: 1, fontSize: 36}}>₹0</Typography>
                  <Typography variant='p' sx={{color:'text.light',fontWeight:'medium', mt: 1}}> (Incl of all tax)</Typography>
                  <Divider sx={{my: 3}}/>
                  <Box sx={{display:'flex', justifyContent:'start', alignItems:'center', gap: 2, mt: 4}}>
  <img src={check1} alt='check'></img>
<Typography  component='p' sx={{fontSize: '16px', fontWeight:'medium'}}>Nifty Algo</Typography>
  </Box>
  <Box sx={{display:'flex', justifyContent:'start', alignItems:'center', gap: 2,mt: 2}}>
  <img src={check1} alt='check'></img>
<Typography  component='p' sx={{fontSize: '16px', fontWeight:'medium'}}>Midcap Algo</Typography>
  </Box>
  <Box sx={{display:'flex', justifyContent:'start', alignItems:'center', gap: 2, mt: 2}}>
  <img src={check1} alt='check'></img>
<Typography  component='p' sx={{fontSize: '16px', fontWeight:'medium'}}>With Customized Target & Stop Loss</Typography>
  </Box>
  <a href='https://wa.link/l8q5eh' target='_blank'>
  <Button size="large" fullWidth variant="outlined" sx={{mt: 3}}>Buy Now</Button>
  </a>
                  </Box>
              </Grid>
              <Grid item xs={12} md={3}>
                  <Box className="pricings1" sx={{background:'#fff', borderRadius: 4, p: 2, boxShadow:'0 16px 40px 0 rgba(26,64,137,.06)'}}>
                  <Typography variant='h5' sx={{fontWeight:'bold', mt: 1}}>Basic Plan</Typography>
                  <Typography variant='p' sx={{color:'secondary.main', fontWeight:'bold', mt: 1, fontSize: 36}}>₹6000</Typography>
                  <Typography variant='p' sx={{color:'text.light',fontWeight:'medium', mt: 1}}> (Incl of all tax)</Typography>
                  <Typography variant='h6' sx={{color:'primary.main',fontWeight:'medium', mt: 1}}>₹ 10000 (40% Off)</Typography>
                  <Divider sx={{my: 3}}/>
                  <Divider sx={{my: 3}}/>
                  <Box sx={{display:'flex', justifyContent:'start', alignItems:'center', gap: 2, mt: 4}}>
  <img src={check1} alt='check'></img>
<Typography  component='p' sx={{fontSize: '16px', fontWeight:'medium'}}>Nifty Algo</Typography>
  </Box>
  <Box sx={{display:'flex', justifyContent:'start', alignItems:'center', gap: 2,mt: 2}}>
  <img src={check1} alt='check'></img>
<Typography  component='p' sx={{fontSize: '16px', fontWeight:'medium'}}>Midcap Algo</Typography>
  </Box>
  <Box sx={{display:'flex', justifyContent:'start', alignItems:'center', gap: 2, mt: 2}}>
  <img src={check1} alt='check'></img>
<Typography  component='p' sx={{fontSize: '16px', fontWeight:'medium'}}>Bank Nifty Algo</Typography>
  </Box>
  <Box sx={{display:'flex', justifyContent:'start', alignItems:'center', gap: 2, mt: 2}}>
  <img src={check1} alt='check'></img>
<Typography  component='p' sx={{fontSize: '16px', fontWeight:'medium'}}>Commodity</Typography>
  </Box>
  <Box sx={{display:'flex', justifyContent:'start', alignItems:'center', gap: 2, mt: 2}}>
  <img src={check1} alt='check'></img>
<Typography  component='p' sx={{fontSize: '16px', fontWeight:'medium'}}>With Customized Target & Stop Loss</Typography>
  </Box>
  <a href='https://wa.link/l8q5eh' target='_blank'>
  <Button size="large" fullWidth variant="contained" sx={{mt: 3}}>Buy Now</Button>
  </a>
                  </Box>
              </Grid>
              <Grid item xs={12} md={3}>
                  <Box className="pricings" sx={{background:'#fff', borderRadius: 4, p: 2, border:'2px solid #fff', boxShadow:'0 16px 40px 0 rgba(26,64,137,.06)'}}>
                  <Typography variant='h5' sx={{fontWeight:'bold', mt: 1}}>Standard Plan</Typography>
                  <Typography variant='p' sx={{color:'secondary.main', fontWeight:'bold', mt: 1, fontSize: 36}}>₹25000</Typography>
                  <Typography variant='p' sx={{color:'text.light',fontWeight:'medium', mt: 1}}> (Incl of all tax)</Typography>
                  <Divider sx={{my: 3}}/>
                  <Box sx={{display:'flex', justifyContent:'start', alignItems:'center', gap: 2, mt: 4}}>
  <img src={check1} alt='check'></img>
<Typography  component='p' sx={{fontSize: '16px', fontWeight:'medium'}}>12 Hrs Algo Strategy Course Material</Typography>
  </Box>
  <Box sx={{display:'flex', justifyContent:'start', alignItems:'center', gap: 2,mt: 2}}>
  <img src={check1} alt='check'></img>
<Typography  component='p' sx={{fontSize: '16px', fontWeight:'medium'}}>Nifty Algo</Typography>
  </Box>
  <Box sx={{display:'flex', justifyContent:'start', alignItems:'center', gap: 2, mt: 2}}>
  <img src={check1} alt='check'></img>
<Typography  component='p' sx={{fontSize: '16px', fontWeight:'medium'}}>Midcap Algo</Typography>
  </Box>
  <Box sx={{display:'flex', justifyContent:'start', alignItems:'center', gap: 2, mt: 2}}>
  <img src={check1} alt='check'></img>
<Typography  component='p' sx={{fontSize: '16px', fontWeight:'medium'}}>Bank Nifty Algo</Typography>
  </Box>
  <Box sx={{display:'flex', justifyContent:'start', alignItems:'center', gap: 2, mt: 2}}>
  <img src={check1} alt='check'></img>
<Typography  component='p' sx={{fontSize: '16px', fontWeight:'medium'}}>Commodity</Typography>
  </Box>
  <Box sx={{display:'flex', justifyContent:'start', alignItems:'center', gap: 2, mt: 2}}>
  <img src={check1} alt='check'></img>
<Typography  component='p' sx={{fontSize: '16px', fontWeight:'medium'}}>With Customized Target & Stop Loss</Typography>
  </Box>
  <a href='https://wa.link/l8q5eh' target='_blank'>
  <Button size="large" fullWidth variant="outlined" sx={{mt: 3}}>Buy Now</Button>
  </a>
                  </Box>
              </Grid>
              <Grid item xs={12} md={3}>
                  <Box className="pricings" sx={{background:'#fff', borderRadius: 4, p: 2, border:'2px solid #fff', boxShadow:'0 16px 40px 0 rgba(26,64,137,.06)'}}>
                  <Typography variant='h5' sx={{fontWeight:'bold', mt: 1}}>Premium Plan (Life Time)</Typography>
                  <Typography variant='p' sx={{color:'secondary.main', fontWeight:'medium', mt: 1, fontSize: 20}}>For Pricing Contact Support Team</Typography>
                  <Divider sx={{my: 3}}/>
                  <Box sx={{display:'flex', justifyContent:'start', alignItems:'center', gap: 2, mt: 4}}>
  <img src={check1} alt='check'></img>
<Typography  component='p' sx={{fontSize: '16px', fontWeight:'medium'}}>All existing indices</Typography>
  </Box>
  <Box sx={{display:'flex', justifyContent:'start', alignItems:'center', gap: 2,mt: 2}}>
  <img src={check1} alt='check'></img>
<Typography  component='p' sx={{fontSize: '16px', fontWeight:'medium'}}>Customized algo for any indices or stocks (Intra Day & Swing)</Typography>
  </Box>
  <Box sx={{display:'flex', justifyContent:'start', alignItems:'center', gap: 2, mt: 2}}>
  <img src={check1} alt='check'></img>
<Typography  component='p' sx={{fontSize: '16px', fontWeight:'medium'}}>Intra Day & Swing Trading</Typography>
  </Box>
  <a href='https://wa.link/l8q5eh' target='_blank'>
  <Button size="large" fullWidth variant="outlined" sx={{mt: 3}}>Buy Now</Button>
  </a>
                  </Box>
              </Grid>
          </Grid>
          <Grid container spacing={2} sx={{mt: 5}}>
          <Grid item xs={12}>
              <Box sx={{background:'#f3f3f3', p: 3, borderRadius: 3}}>
              <Typography  component='h5' sx={{color:'text.main', fontSize: '16px', fontWeight:'bold', textAlign:'center', lineHeight: 2}}>Desclaimer 
              </Typography>
              <Typography  component='li' sx={{color:'text.light', fontSize: '16px', fontWeight:'normal', textAlign:'justify', lineHeight: 2}}>We are not a SEBI-registered investment advisor,
 Therefore, none of our ALGO STRATEGIES shall be considered as investment advice. 
 </Typography>
 <Typography  component='li' sx={{color:'text.light', fontSize: '16px', fontWeight:'normal', textAlign:'justify', lineHeight: 2}}> 
 Market participants shall use these ALGO STRATEGIES for their knowledge purposes only. </Typography>
 <Typography  component='li' sx={{color:'text.light', fontSize: '16px', fontWeight:'normal', textAlign:'justify', lineHeight: 2}}> 
 CONSULT YOUR INVESTMENT ADVISOR BEFORE INVESTING.   </Typography>
 <Typography  component='li' sx={{color:'text.light', fontSize: '16px', fontWeight:'normal', textAlign:'justify', lineHeight: 2}}>   
 DTZ Trading Academy(www.dtzvinoth.com) ISN'T LIABLE FOR YOUR PROFITS OR LOSSES.  </Typography>
 <Typography  component='li' sx={{color:'text.light', fontSize: '16px', fontWeight:'normal', textAlign:'justify', lineHeight: 2}}>     
 ALL THE FEES PAID TO DTZ Trading Academy(www.dtzvinoth.com) IS NON - REFUNDABLE. 
 </Typography>
 <Typography  component='li' sx={{color:'text.light', fontSize: '16px', fontWeight:'normal', textAlign:'justify', lineHeight: 2}}>     
 Any dispute arising under these terms and conditions shall be subject to the coimbatore jurisdiction only.</Typography>
 </Box>
              </Grid>
          </Grid>
    </Container>
        </Box> */}
        <Box className="roadmapSec"  >
    <Container maxWidth="lg">
          <Typography variant='h3' sx={{fontFamily:"'Sora', sans-serif", fontWeight:'bold', textAlign:'center', color:'text.main'}}>Firstock Setup
           <Typography variant='p' sx={{color:'secondary.main'}}> Roadmap</Typography>
          </Typography>
          <Typography variant='h6' sx={{color:'text.light', fontSize:'18px', fontWeight:'normal', my: 3, textAlign:'center'}}>A product roadmap shows the path ahead, helps teams plan, and guides the delivery of the product.</Typography>
          <Grid container>
            <Grid item xs={5} sx={{display:{md:'block', xs:'none'}}}>

            </Grid>
            <Grid item xs={2} sx={{ justifyContent:'center', alignItems:'center', display:{md:'flex', xs:'none'}}}>
                <Paper sx={{width: '10px', background:(step === "S1" || step === "S2" || step === "S3" || step === "S4" || step === "S5" || step === "S6" || step ==="S7" || step==="S8" || step==="S9") ? '#00d094' : '#e6edfb', height:'100%', borderRadius:0, boxShadow:'none'}}>
                </Paper>
            </Grid>
            <Grid item xs={12} md={5}>
                <Card sx={{p: 3, background:'fafafa', boxShadow:'none', borderRadius: 2}}>
                    <Box sx={{display:'flex', justifyContent:'space-between'}}>
                      <Typography variant="h6" sx={{fontWeight:'bold'}}>Dive into the World of Investing with Firstock</Typography>
                      <Typography sx={{color:'primary.main', fontWeight:'bold'}} variant="h6">S1</Typography>
                    </Box>
                    <Typography variant="body1" sx={{color:'text.light', mt: 1}}>Get started by downloading the Firstock app from your device's app store. Whether you're on iOS or Android, you can find us on the App Store or Google Play. Can't access the app store? No worries, you can also sign up directly on our website <a href='https://signup.thefirstock.com?p=DTZ94 ' target='_blank'>https://signup.thefirstock.com?p=DTZ94 </a></Typography>
                    {(step === 'S1') && <Button variant='contained' onClick={()=>{
                      setStep('S2')
                      window.scrollBy(0, 250);
                    } } sx={{mt: 2}}>Next</Button>}
                </Card>
            </Grid>
            <Grid item xs={12} md={5}>
                <Card sx={{p: 3, background:'fafafa', boxShadow:'none', borderRadius: 2}}>
                    <Box sx={{display:'flex', justifyContent:'space-between'}}>
                      <Typography variant="h6" sx={{fontWeight:'bold'}}>Seamlessly Sign Up and Verify Your Account</Typography>
                      <Typography sx={{color:'primary.main', fontWeight:'bold'}} variant="h6">S2</Typography>
                    </Box>
                    <Typography variant="body1" sx={{color:'text.light', mt: 1}}>Enter your contact details to create your account. We'll send you a one-time password (OTP) to verify your mobile number. Once verified, proceed to fill in your full name, email address, and verify your email by clicking on the link sent to your inbox.</Typography>
                    {(step === 'S2') && <Button variant='contained' onClick={()=> {
                      setStep('S3')
                      window.scrollBy(0, 250);
                    }} sx={{mt: 2}}>Next</Button>}             </Card>
            </Grid>
            <Grid item xs={2} sx={{display:{md:'flex', xs:'none'}, justifyContent:'center', alignItems:'center'}}>
                <Paper sx={{width: '10px', background:(step === "S2" || step === "S3" || step === "S4" || step === "S5" || step === "S6" || step ==="S7" || step==="S8" || step==="S9") ? '#00d094' : '#e6edfb', height:'100%', borderRadius:0, boxShadow:'none'}}>
                </Paper>
            </Grid>
            <Grid item xs={5}  sx={{display:{md:'block', xs:'none'}}}>

            </Grid>
            <Grid item xs={5}  sx={{display:{md:'block', xs:'none'}}}>

            </Grid>
            <Grid item xs={2} sx={{display:{md:'flex', xs:'none'}, justifyContent:'center', alignItems:'center'}}>
                <Paper sx={{width: '10px', background:(step === "S3" || step === "S4" || step === "S5" || step === "S6" || step ==="S7" || step==="S8" || step==="S9") ? '#00d094' : '#e6edfb', height:'100%', borderRadius:0, boxShadow:'none'}}>
                </Paper>
            </Grid>
            <Grid item xs={12} md={5}>
                <Card sx={{p: 3, background:'fafafa', boxShadow:'none', borderRadius: 2}}>
                    <Box sx={{display:'flex', justifyContent:'space-between'}}>
                      <Typography variant="h6" sx={{fontWeight:'bold'}}>Secure Your Account with PAN Card Details</Typography>
                      <Typography sx={{color:'primary.main', fontWeight:'bold'}} variant="h6">S3</Typography>
                    </Box>
                    <Typography variant="body1" sx={{color:'text.light', mt: 1}}>Provide your PAN card details to ensure the security of your account. Enter your PAN number and date of birth as per your PAN card.</Typography>
                    {(step === 'S3') && <Button variant='contained' onClick={()=>{
                      setStep('S4')
                      window.scrollBy(0, 250);
                    }} sx={{mt: 2}}>Next</Button>}                    </Card>
            </Grid>
            <Grid item xs={12} md={5}>
                <Card sx={{p: 3, background:'fafafa', boxShadow:'none', borderRadius: 2}}>
                    <Box sx={{display:'flex', justifyContent:'space-between'}}>
                      <Typography variant="h6" sx={{fontWeight:'bold'}}>Safely Link Your Bank Account</Typography>
                      <Typography sx={{color:'primary.main', fontWeight:'bold'}} variant="h6">S4</Typography>
                    </Box>
                    <Typography variant="body1" sx={{color:'text.light', mt: 1}}>Enter your bank details including your account number and IFSC code to link your bank account securely with Firstock.</Typography>
                    {(step === 'S4') && <Button variant='contained' onClick={()=>{
                      setStep('S5')
                      window.scrollBy(0, 250);
                    }} sx={{mt: 2}}>Next</Button>}                    </Card>
            </Grid>
            <Grid item xs={2} sx={{display:{md:'flex', xs:'none'}, justifyContent:'center', alignItems:'center'}}>
                <Paper sx={{width: '10px', background:(step === "S4" || step === "S5" || step === "S6" || step ==="S7" || step==="S8" || step==="S9") ? '#00d094' : '#e6edfb', height:'100%', borderRadius:0, boxShadow:'none'}}>
                </Paper>
            </Grid>
            <Grid item xs={5} sx={{display:{md:'block', xs:'none'}}}>

            </Grid>
            <Grid item xs={5} sx={{display:{md:'block', xs:'none'}}}>

            </Grid>
            <Grid item xs={2} sx={{display:{md:'flex', xs:'none'}, justifyContent:'center', alignItems:'center'}}>
                <Paper sx={{width: '10px', background:(step === "S5" || step === "S6" || step ==="S7" || step==="S8" || step==="S9") ? '#00d094' : '#e6edfb', height:'100%',borderRadius:0, boxShadow:'none'}}>
                </Paper>
            </Grid>
            <Grid item xs={12} md={5}>
                <Card sx={{p: 3, background:'fafafa', boxShadow:'none', borderRadius: 2}}>
                    <Box sx={{display:'flex', justifyContent:'space-between'}}>
                      <Typography variant="h6" sx={{fontWeight:'bold'}}>Personalize Your Account with Photos and Signature</Typography>
                      <Typography sx={{color:'primary.main', fontWeight:'bold'}} variant="h6">S5</Typography>
                    </Box>
                    <Typography variant="body1" sx={{color:'text.light', mt: 1}}>Upload your photos and signature to personalize your account. You can upload a live selfie for added security and verification.</Typography>
                    {(step === 'S5') && <Button variant='contained' onClick={()=>{
                      setStep('S6')
                      window.scrollBy(0, 150);
                    }} sx={{mt: 2}}>Next</Button>}                    </Card>
            </Grid>
            <Grid item xs={12} md={5}>
                <Card sx={{p: 3, background:'fafafa', boxShadow:'none', borderRadius: 2}}>
                    <Box sx={{display:'flex', justifyContent:'space-between'}}>
                      <Typography variant="h6" sx={{fontWeight:'bold'}}>Secure Your Account with Aadhaar</Typography>
                      <Typography sx={{color:'primary.main', fontWeight:'bold'}} variant="h6">S6</Typography>
                    </Box>
                    <Typography variant="body1" sx={{color:'text.light', mt: 1}}>Link your Aadhaar number for enhanced security and verification purposes</Typography>
                    {(step === 'S6') && <Button variant='contained' onClick={()=>{
                      setStep('S7')
                      window.scrollBy(0, 150);
                    }} sx={{mt: 2}}>Next</Button>}    
                </Card>
            </Grid>
            <Grid item xs={2} sx={{display:{md:'flex', xs:'none'}, justifyContent:'center', alignItems:'center'}}>
                <Paper sx={{width: '10px', background:(step === "S6" || step ==="S7" || step==="S8" || step==="S9") ? '#00d094' : '#e6edfb', height:'100%', borderRadius:0, boxShadow:'none'}}>
                </Paper>
            </Grid>
            <Grid item xs={5} sx={{display:{md:'block', xs:'none'}}}>

            </Grid>
            <Grid item xs={5} sx={{display:{md:'block', xs:'none'}}}>

            </Grid>
            <Grid item xs={2} sx={{display:{md:'flex', xs:'none'}, justifyContent:'center', alignItems:'center'}}>
                <Paper sx={{width: '10px', background:(step ==="S7" || step==="S8" || step==="S9") ? '#00d094' : '#e6edfb', height:'100%',borderRadius:0, boxShadow:'none'}}>
                </Paper>
            </Grid>
            <Grid item xs={12} md={5}>
                <Card sx={{p: 3, background:'fafafa', boxShadow:'none', borderRadius: 2}}>
                    <Box sx={{display:'flex', justifyContent:'space-between'}}>
                      <Typography variant="h6" sx={{fontWeight:'bold'}}>Reach Out to Our Support Team</Typography>
                      <Typography sx={{color:'primary.main', fontWeight:'bold'}} variant="h6">S7</Typography>
                    </Box>
                    <Typography variant="body1" sx={{color:'text.light', mt: 1}}>Need assistance? Our dedicated support team is here to help. Contact us via phone or email, and we'll be happy to assist you with any queries you may have. <a href='tel:9900199801'>+91 99001 99801</a>, <a href='tel:7204036521'>+91 72040 36521</a>, <a href='tel:9663590011'>+91 96635 90011</a></Typography>
                    {(step === 'S7') && <Button variant='contained' onClick={()=>{
                      setStep('S8')
                      window.scrollBy(0, 150);
                    }} sx={{mt: 2}}>Next</Button>}                    </Card>
            </Grid>
            <Grid item xs={12} md={5}>
                <Card sx={{p: 3, background:'fafafa', boxShadow:'none', borderRadius: 2}}>
                    <Box sx={{display:'flex', justifyContent:'space-between'}}>
                      <Typography variant="h6" sx={{fontWeight:'bold'}}>Sit Back and Relax as We Process Your Account</Typography>
                      <Typography sx={{color:'primary.main', fontWeight:'bold'}} variant="h6">S8</Typography>
                    </Box>
                    <Typography variant="body1" sx={{color:'text.light', mt: 1}}>Within 24 to 48 hours, your account will be processed, and you'll be ready to start your investing journey with Firstock</Typography>
                    {(step === 'S8') && <Button variant='contained' onClick={()=>{
                      setStep('S9')
                      window.scrollBy(0, 150);
                    }} sx={{mt: 2}}>Next</Button>}    
                </Card>
            </Grid>
            <Grid item xs={2} sx={{display:{md:'flex', xs:'none'}, justifyContent:'center', alignItems:'center'}}>
                <Paper sx={{width: '10px', background:( step==="S8" || step==="S9") ? '#00d094' : '#e6edfb', height:'100%', borderRadius:0, boxShadow:'none'}}>
                </Paper>
            </Grid>
            <Grid item xs={5} sx={{display:{md:'block', xs:'none'}}}>

            </Grid>
            <Grid item xs={5} sx={{display:{md:'block', xs:'none'}}}>

            </Grid>
            <Grid item xs={2} sx={{display:{md:'flex', xs:'none'}, justifyContent:'center', alignItems:'center'}}>
                <Paper sx={{width: '10px', background:(step==="S9") ? '#00d094' : '#e6edfb', height:'100%',borderRadius:0, boxShadow:'none'}}>
                </Paper>
            </Grid>
            <Grid item xs={12} md={5}>
                <Card sx={{p: 3, background:'fafafa', boxShadow:'none', borderRadius: 2}}>
                    <Box sx={{display:'flex', justifyContent:'space-between'}}>
                      <Typography variant="h6" sx={{fontWeight:'bold'}}>Ready to Dive into the World of Investing</Typography>
                      <Typography sx={{color:'primary.main', fontWeight:'bold'}} variant="h6">S9</Typography>
                    </Box>
                    <Typography variant="body1" sx={{color:'text.light', mt: 1}}>Congratulations! You're now ready to explore the world of investing with Firstock. Start investing, monitoring your portfolio, and achieving your financial goals effortlessly.</Typography>
                    {(step === 'S9') && <Button variant='contained' onClick={()=>{
                      setStep('S9')
                      window.scrollBy(0, 100);
                    }} sx={{mt: 2}}>Finish</Button>}                    </Card>
            </Grid>
          </Grid>
    </Container>
        </Box>
        <Box className="roadmapSec"  >
    <Container maxWidth="lg">
          <Typography variant='h3' sx={{fontFamily:"'Sora', sans-serif", fontWeight:'bold', textAlign:'center', color:'text.main'}}>Algo Setup
           <Typography variant='p' sx={{color:'secondary.main'}}> Roadmap</Typography>
          </Typography>
          <Typography variant='h6' sx={{color:'text.light', fontSize:'18px', fontWeight:'normal', my: 3, textAlign:'center'}}>A product roadmap shows the path ahead, helps teams plan, and guides the delivery of the product.</Typography>
          <Grid container>
            <Grid item xs={5} sx={{display:{md:'block', xs:'none'}}}>

            </Grid>
            <Grid item xs={2} sx={{ justifyContent:'center', alignItems:'center', display:{md:'flex', xs:'none'}}}>
                <Paper sx={{width: '10px', background:(astep === "S1" || astep === "S2" || astep === "S3" || astep === "S4" || astep === "S5" || astep === "S6" || astep ==="S7" || astep==="S8" || astep==="S9" || astep==="S10" || astep==="S11" || astep==="S12") ? '#00d094' : '#e6edfb', height:'100%', borderRadius:0, boxShadow:'none'}}>
                </Paper>
            </Grid>
            <Grid item xs={12} md={5}>
                <Card sx={{p: 3, background:'fafafa', boxShadow:'none', borderRadius: 2}}>
                    <Box sx={{display:'flex', justifyContent:'space-between'}}>
                      <Typography variant="h6" sx={{fontWeight:'bold'}}>Embark on Your Firstock Journey</Typography>
                      <Typography sx={{color:'primary.main', fontWeight:'bold'}} variant="h6">S1</Typography>
                    </Box>
                    <Typography variant="body1" sx={{color:'text.light', mt: 1}}>Begin your journey by accessing the login page via the provided <a href='https://login.thefirstock.com/#/' target='_blank'>https://login.thefirstock.com/#/</a></Typography>
                    {(astep === 'S1') && <Button variant='contained' onClick={()=>{
                      setaStep('S2')
                      window.scrollBy(0, 250);
                    } } sx={{mt: 2}}>Next</Button>}
                </Card>
            </Grid>
            <Grid item xs={12} md={5}>
                <Card sx={{p: 3, background:'fafafa', boxShadow:'none', borderRadius: 2}}>
                    <Box sx={{display:'flex', justifyContent:'space-between'}}>
                      <Typography variant="h6" sx={{fontWeight:'bold'}}>Discover Your User ID</Typography>
                      <Typography sx={{color:'primary.main', fontWeight:'bold'}} variant="h6">S2</Typography>
                    </Box>
                    <Typography variant="body1" sx={{color:'text.light', mt: 1}}>Locate your unique user ID, labeled as "NV3007," positioned elegantly at the top right corner of the page. Click on it to proceed.</Typography>
                    {(astep === 'S2') && <Button variant='contained' onClick={()=> {
                      setaStep('S3')
                      window.scrollBy(0, 250);
                    }} sx={{mt: 2}}>Next</Button>}             </Card>
            </Grid>
            <Grid item xs={2} sx={{display:{md:'flex', xs:'none'}, justifyContent:'center', alignItems:'center'}}>
                <Paper sx={{width: '10px', background:(astep === "S2" || astep === "S3" || astep === "S4" || astep === "S5" || astep === "S6" || astep ==="S7" || astep==="S8" || astep==="S9" || astep==="S10" || astep==="S11" || astep==="S12") ? '#00d094' : '#e6edfb', height:'100%', borderRadius:0, boxShadow:'none'}}>
                </Paper>
            </Grid>
            <Grid item xs={5}  sx={{display:{md:'block', xs:'none'}}}>

            </Grid>
            <Grid item xs={5}  sx={{display:{md:'block', xs:'none'}}}>

            </Grid>
            <Grid item xs={2} sx={{display:{md:'flex', xs:'none'}, justifyContent:'center', alignItems:'center'}}>
                <Paper sx={{width: '10px', background:(astep === "S3" || astep === "S4" || astep === "S5" || astep === "S6" || astep ==="S7" || astep==="S8" || astep==="S9" || astep==="S10" || astep==="S11" || astep==="S12") ? '#00d094' : '#e6edfb', height:'100%', borderRadius:0, boxShadow:'none'}}>
                </Paper>
            </Grid>
            <Grid item xs={12} md={5}>
                <Card sx={{p: 3, background:'fafafa', boxShadow:'none', borderRadius: 2}}>
                    <Box sx={{display:'flex', justifyContent:'space-between'}}>
                      <Typography variant="h6" sx={{fontWeight:'bold'}}>Prioritize Security</Typography>
                      <Typography sx={{color:'primary.main', fontWeight:'bold'}} variant="h6">S3</Typography>
                    </Box>
                    <Typography variant="body1" sx={{color:'text.light', mt: 1}}>Click on the "Security" option to ensure the safety of your account.</Typography>
                    {(astep === 'S3') && <Button variant='contained' onClick={()=>{
                      setaStep('S4')
                      window.scrollBy(0, 250);
                    }} sx={{mt: 2}}>Next</Button>}                    </Card>
            </Grid>
            <Grid item xs={12} md={5}>
                <Card sx={{p: 3, background:'fafafa', boxShadow:'none', borderRadius: 2}}>
                    <Box sx={{display:'flex', justifyContent:'space-between'}}>
                      <Typography variant="h6" sx={{fontWeight:'bold'}}>Strengthen Your Account with Two-Step Verification</Typography>
                      <Typography sx={{color:'primary.main', fontWeight:'bold'}} variant="h6">S4</Typography>
                    </Box>
                    <Typography variant="body1" sx={{color:'text.light', mt: 1}}>Next, select "TOTP" to enable Two-Step Verification, adding an extra layer of security to your account. Use Google Authenticator to scan and set up this feature seamlessly.</Typography>
                    {(astep === 'S4') && <Button variant='contained' onClick={()=>{
                      setaStep('S5')
                      window.scrollBy(0, 250);
                    }} sx={{mt: 2}}>Next</Button>}                    </Card>
            </Grid>
            <Grid item xs={2} sx={{display:{md:'flex', xs:'none'}, justifyContent:'center', alignItems:'center'}}>
                <Paper sx={{width: '10px', background:(astep === "S4" || astep === "S5" || astep === "S6" || astep ==="S7" || astep==="S8" || astep==="S9" || astep==="S10" || astep==="S11" || astep==="S12") ? '#00d094' : '#e6edfb', height:'100%', borderRadius:0, boxShadow:'none'}}>
                </Paper>
            </Grid>
            <Grid item xs={5} sx={{display:{md:'block', xs:'none'}}}>

            </Grid>
            <Grid item xs={5} sx={{display:{md:'block', xs:'none'}}}>

            </Grid>
            <Grid item xs={2} sx={{display:{md:'flex', xs:'none'}, justifyContent:'center', alignItems:'center'}}>
                <Paper sx={{width: '10px', background:(astep === "S5" || astep === "S6" || astep ==="S7" || astep==="S8" || astep==="S9" || astep==="S10" || astep==="S11" || astep==="S12") ? '#00d094' : '#e6edfb', height:'100%',borderRadius:0, boxShadow:'none'}}>
                </Paper>
            </Grid>
            <Grid item xs={12} md={5}>
                <Card sx={{p: 3, background:'fafafa', boxShadow:'none', borderRadius: 2}}>
                    <Box sx={{display:'flex', justifyContent:'space-between'}}>
                      <Typography variant="h6" sx={{fontWeight:'bold'}}>Customize Your Trade Settings</Typography>
                      <Typography sx={{color:'primary.main', fontWeight:'bold'}} variant="h6">S5</Typography>
                    </Box>
                    <Typography variant="body1" sx={{color:'text.light', mt: 1}}>Navigate to the "Trade setting" option on the left side of the page. Enter your password to proceed.</Typography>
                    {(astep === 'S5') && <Button variant='contained' onClick={()=>{
                      setaStep('S6')
                      window.scrollBy(0, 150);
                    }} sx={{mt: 2}}>Next</Button>}                    </Card>
            </Grid>
            <Grid item xs={12} md={5}>
                <Card sx={{p: 3, background:'fafafa', boxShadow:'none', borderRadius: 2}}>
                    <Box sx={{display:'flex', justifyContent:'space-between'}}>
                      <Typography variant="h6" sx={{fontWeight:'bold'}}>Securely Input Your Credentials</Typography>
                      <Typography sx={{color:'primary.main', fontWeight:'bold'}} variant="h6">S6</Typography>
                    </Box>
                    <Typography variant="body1" sx={{color:'text.light', mt: 1}}>Enter your Firstock Client ID and TOTP generated by Google Authenticator. Click "Save Changes" at the bottom right, then proceed to click "Generate Token."</Typography>
                    {(astep === 'S6') && <Button variant='contained' onClick={()=>{
                      setaStep('S7')
                      window.scrollBy(0, 150);
                    }} sx={{mt: 2}}>Next</Button>}    
                </Card>
            </Grid>
            <Grid item xs={2} sx={{display:{md:'flex', xs:'none'}, justifyContent:'center', alignItems:'center'}}>
                <Paper sx={{width: '10px', background:(astep === "S6" || astep ==="S7" || astep==="S8" || astep==="S9" || astep==="S10" || astep==="S11" || astep==="S12") ? '#00d094' : '#e6edfb', height:'100%', borderRadius:0, boxShadow:'none'}}>
                </Paper>
            </Grid>
            <Grid item xs={5} sx={{display:{md:'block', xs:'none'}}}>

            </Grid>
            <Grid item xs={5} sx={{display:{md:'block', xs:'none'}}}>

            </Grid>
            <Grid item xs={2} sx={{display:{md:'flex', xs:'none'}, justifyContent:'center', alignItems:'center'}}>
                <Paper sx={{width: '10px', background:(astep ==="S7" || astep==="S8" || astep==="S9" || astep==="S10" || astep==="S11" || astep==="S12") ? '#00d094' : '#e6edfb', height:'100%',borderRadius:0, boxShadow:'none'}}>
                </Paper>
            </Grid>
            <Grid item xs={12} md={5}>
                <Card sx={{p: 3, background:'fafafa', boxShadow:'none', borderRadius: 2}}>
                    <Box sx={{display:'flex', justifyContent:'space-between'}}>
                      <Typography variant="h6" sx={{fontWeight:'bold'}}>Enhance Your Trading Experience</Typography>
                      <Typography sx={{color:'primary.main', fontWeight:'bold'}} variant="h6">S7</Typography>
                    </Box>
                    <Typography variant="body1" sx={{color:'text.light', mt: 1}}>Expand your knowledge by exploring various features. Learn to adjust lot sizes by accessing "Watchlist" at the top left corner of the page.</Typography>
                    {(astep === 'S7') && <Button variant='contained' onClick={()=>{
                      setaStep('S8')
                      window.scrollBy(0, 150);
                    }} sx={{mt: 2}}>Next</Button>}                    </Card>
            </Grid>
            <Grid item xs={12} md={5}>
                <Card sx={{p: 3, background:'fafafa', boxShadow:'none', borderRadius: 2}}>
                    <Box sx={{display:'flex', justifyContent:'space-between'}}>
                      <Typography variant="h6" sx={{fontWeight:'bold'}}>Utilize Administrative Tools</Typography>
                      <Typography sx={{color:'primary.main', fontWeight:'bold'}} variant="h6">S8</Typography>
                    </Box>
                    <Typography variant="body1" sx={{color:'text.light', mt: 1}}>
Access the Admin Option Converter by clicking at the top of the page.</Typography>
                    {(astep === 'S8') && <Button variant='contained' onClick={()=>{
                      setaStep('S9')
                      window.scrollBy(0, 150);
                    }} sx={{mt: 2}}>Next</Button>}    
                </Card>
            </Grid>
            <Grid item xs={2} sx={{display:{md:'flex', xs:'none'}, justifyContent:'center', alignItems:'center'}}>
                <Paper sx={{width: '10px', background:( astep==="S8" || astep==="S9" || astep==="S10" || astep==="S11" || astep==="S12") ? '#00d094' : '#e6edfb', height:'100%', borderRadius:0, boxShadow:'none'}}>
                </Paper>
            </Grid>
            <Grid item xs={5} sx={{display:{md:'block', xs:'none'}}}>

            </Grid>
            <Grid item xs={5} sx={{display:{md:'block', xs:'none'}}}>

            </Grid>
            <Grid item xs={2} sx={{display:{md:'flex', xs:'none'}, justifyContent:'center', alignItems:'center'}}>
                <Paper sx={{width: '10px', background:(astep==="S9" || astep==="S10" || astep==="S11" || astep==="S12") ? '#00d094' : '#e6edfb', height:'100%',borderRadius:0, boxShadow:'none'}}>
                </Paper>
            </Grid>
            <Grid item xs={12} md={5}>
                <Card sx={{p: 3, background:'fafafa', boxShadow:'none', borderRadius: 2}}>
                    <Box sx={{display:'flex', justifyContent:'space-between'}}>
                      <Typography variant="h6" sx={{fontWeight:'bold'}}>Refresh Your Settings</Typography>
                      <Typography sx={{color:'primary.main', fontWeight:'bold'}} variant="h6">S9</Typography>
                    </Box>
                    <Typography variant="body1" sx={{color:'text.light', mt: 1}}>Click the "Regenerate" button located at the bottom left corner of the page.</Typography>
                    {(astep === 'S9') && <Button variant='contained' onClick={()=>{
                      setaStep('S10')
                      window.scrollBy(0, 100);
                    }} sx={{mt: 2}}>Next</Button>}                    </Card>
            </Grid>
            <Grid item xs={12} md={5}>
                <Card sx={{p: 3, background:'fafafa', boxShadow:'none', borderRadius: 2}}>
                    <Box sx={{display:'flex', justifyContent:'space-between'}}>
                      <Typography variant="h6" sx={{fontWeight:'bold'}}>Confirm Changes</Typography>
                      <Typography sx={{color:'primary.main', fontWeight:'bold'}} variant="h6">S10</Typography>
                    </Box>
                    <Typography variant="body1" sx={{color:'text.light', mt: 1}}>Confirm Changes Confirm your decision by clicking "Yes" to proceed with the deletion.</Typography>
                    {(astep === 'S10') && <Button variant='contained' onClick={()=>{
                      setaStep('S11')
                      window.scrollBy(0, 150);
                    }} sx={{mt: 2}}>Next</Button>}    
                </Card>
            </Grid>
            <Grid item xs={2} sx={{display:{md:'flex', xs:'none'}, justifyContent:'center', alignItems:'center'}}>
                <Paper sx={{width: '10px', background:( astep==="S10" || astep==="S11" || astep==="S12") ? '#00d094' : '#e6edfb', height:'100%', borderRadius:0, boxShadow:'none'}}>
                </Paper>
            </Grid>
            <Grid item xs={5} sx={{display:{md:'block', xs:'none'}}}>

            </Grid>
            <Grid item xs={5} sx={{display:{md:'block', xs:'none'}}}>

            </Grid>
            <Grid item xs={2} sx={{display:{md:'flex', xs:'none'}, justifyContent:'center', alignItems:'center'}}>
                <Paper sx={{width: '10px', background:( astep==="S11" || astep==="S12") ? '#00d094' : '#e6edfb', height:'100%',borderRadius:0, boxShadow:'none'}}>
                </Paper>
            </Grid>
            <Grid item xs={12} md={5}>
                <Card sx={{p: 3, background:'fafafa', boxShadow:'none', borderRadius: 2}}>
                    <Box sx={{display:'flex', justifyContent:'space-between'}}>
                      <Typography variant="h6" sx={{fontWeight:'bold'}}>Fine-Tune Lot Sizes</Typography>
                      <Typography sx={{color:'primary.main', fontWeight:'bold'}} variant="h6">S11</Typography>
                    </Box>
                    <Typography variant="body1" sx={{color:'text.light', mt: 1}}>
Click on "Edit Symbol," then select "Fixed by default 1LOT." Adjust the lot size to your preference, such as 15k/1LOT, and click "Save Changes."</Typography>
                    {(astep === 'S11') && <Button variant='contained' onClick={()=>{
                      setaStep('S12')
                      window.scrollBy(0, 150);
                    }} sx={{mt: 2}}>Next</Button>}                    </Card>
            </Grid>
            <Grid item xs={12} md={5}>
                <Card sx={{p: 3, background:'fafafa', boxShadow:'none', borderRadius: 2}}>
                    <Box sx={{display:'flex', justifyContent:'space-between'}}>
                      <Typography variant="h6" sx={{fontWeight:'bold'}}>Verify Your Updates</Typography>
                      <Typography sx={{color:'primary.main', fontWeight:'bold'}} variant="h6">S12</Typography>
                    </Box>
                    <Typography variant="body1" sx={{color:'text.light', mt: 1}}>
                    Ensure that your lot size adjustments have been successfully updated. We recommend checking this regularly to stay informed of any changes.</Typography>
                    {(astep === 'S12') && <Button variant='contained' onClick={()=>{
                      setaStep('S12')
                      window.scrollBy(0, 150);
                    }} sx={{mt: 2}}>Finish</Button>}    
                </Card>
            </Grid>
            <Grid item xs={2} sx={{display:{md:'flex', xs:'none'}, justifyContent:'center', alignItems:'center'}}>
                <Paper sx={{width: '10px', background:( astep==="S12") ? '#00d094' : '#e6edfb', height:'100%', borderRadius:0, boxShadow:'none'}}>
                </Paper>
            </Grid>
          </Grid>
    </Container>
        </Box>
    </div>
  )
}

export default Algo