import { Typography } from '@mui/material';
import React, { useState } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import './style.css';

export const CustInput = ({placeholder, type, label}) => {
  return (
    <div style={{width: '100%'}}>
                            <Typography variant='h6'>{label}</Typography>
                    <input type={type} className='inputField' placeholder={placeholder}></input>
    </div>
  )
}

export const CustPassInput = ({placeholder, type, label}) => {

    const [show, setShow] = useState(false);

    return (
      <div style={{width: '100%'}}>
                              <Typography variant='h6'>{label}</Typography>
                    <div style={{position:'relative'}}>
                      <input type={show ? 'text' :'password'} className='inputField' placeholder={placeholder}></input>
                      <div className='iocn'>
                      {show ? <VisibilityIcon onClick={()=> setShow(!show)}/> : <VisibilityOffIcon onClick={()=> setShow(!show)}/>}
                      </div>
                      </div>
      </div>
    )
  }