import React from 'react'
import { Outlet } from 'react-router-dom';
import LandHeader from './layouts/landHeader/LandHeader';
import LandFooter from './layouts/landFooter/landFooter';

const Layout2 = () => {
  return (
    <div>
        <LandHeader/>
        <Outlet/>
        <LandFooter/>
    </div>
  )
}

export default Layout2;