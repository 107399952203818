import React, {useState, useEffect} from 'react';
import {
  Box, Button, Container, Divider, Grid, TextField, Typography,
} from "@mui/material";
import './style.css';
import takepic from '../../assets/images/takeapick.png';
import temp from '../../assets/images/choosetemp.png';
import studio from '../../assets/images/studio.png';
import arrow1 from '../../assets/images/arrow1.png';
import arrow2 from '../../assets/images/arrow2.png';
import VertTabs from '../../components/tabs/Tabs';
import userAi from '../../assets/images/users_ai.png';
import AIenhance from '../../assets/images/aienhancer.png';
import Easytask from '../../assets/images/easytask.png';
import team from '../../assets/images/team.png';
import figma1 from '../../assets/images/figma_1.png';

import uparrow from '../../assets/images/uparrow.png';
import trader from '../../assets/images/trader.png';
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';
import Reattimedata from '../../assets/images/1 (1).png';
import custSupport from '../../assets/images/customer_support.png';
import highSec from '../../assets/images/high_security.png';
import lowCommis from '../../assets/images/lower_commis.png';
import check from '../../assets/images/check.png';
import ourcommit from '../../assets/images/aboutus.png';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import starery from '../../assets/images/stagery.png';
import financeadvi from '../../assets/images/financeadvi.png';
import management from '../../assets/images/management.png';
import check1 from '../../assets/images/check1.svg';
import MultipleItems from '../../components/carousal/Carousal';
import faq from '../../assets/images/faq.png';
import CustomizedAccordions from '../../components/accordion/Accordion';
import contact from '../../assets/images/contact.png';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import TelegramIcon from '@mui/icons-material/Telegram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import MailIcon from '@mui/icons-material/Mail';
import CallIcon from '@mui/icons-material/Call';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import ScrollToTop from '../../components/ScrollToTop';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 15,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));


const Contact = ({setLoader}) => {


  return (
    <div>
    <ScrollToTop/>
    <Box className='bannerBg'>
    <Container maxWidth="lg" sx={{py: 12}}>
      <Typography variant='h3' sx={{color:'text.white', fontWeight:'bold'}}>Contact</Typography>
      <Typography variant='h6' sx={{fontFamily:"'Sora', sans-serif", fontWeight:'medium',  color:'text.white', mt: 3}}>
          <Typography variant='p' sx={{color:'primary.main'}}>Home</Typography> / Contact
          </Typography>
</Container>
    </Box>
        <Box className="thirdSec" sx={{position:'relative'}}>
    <Container maxWidth="lg">
    <Grid container spacing={2}>
  <Grid item xs={12} md={6}>
  <Typography variant='h4' sx={{fontFamily:"'Sora', sans-serif", fontWeight:'bold',  color:'text.main', mt: 3, lineHeight: 1.5}}>Let's
          <Typography variant='p' sx={{color:'secondary.main'}}> Get In Touch </Typography>  With Us
          </Typography>
          <Box sx={{display:'flex', justifyContent:{md:'start', xs:'start'}, alignItems:'center', gap: 3, mt: 3}}>
          <a href='https://wa.link/l8q5eh' target='_blank' style={{textDecoration:'none'}}>
          <Box sx={{border:'2px solid', borderColor:'primary.main', width:'45px', height:'45px', borderRadius:'50%', display:'flex', justifyContent:'center', alignItems:'center'}}>
    <WhatsAppIcon sx={{color:'text.maain'}}/>
  </Box>
  </a>
  <a href='https://www.instagram.com/dtz_vinoth/' target='_blank' style={{textDecoration:'none'}}>
  <Box sx={{border:'2px solid', borderColor:'primary.main', width:'45px', height:'45px', borderRadius:'50%', display:'flex', justifyContent:'center', alignItems:'center'}}>
    <InstagramIcon sx={{color:'text.main'}}/>
  </Box>
  </a>
  <a href='https://t.me/DTZcommunity/' target='_blank' style={{textDecoration:'none'}}>
  <Box sx={{border:'2px solid ', borderColor:'primary.main', width:'45px', height:'45px', borderRadius:'50%', display:'flex', justifyContent:'center', alignItems:'center'}}>
    <TelegramIcon sx={{color:'text.main'}}/>
  </Box>
  </a>
  <a href='https://www.youtube.com/@DisciplineTradersZone' target='_blank' style={{textDecoration:'none'}}>
  <Box sx={{border:'2px solid', borderColor:'primary.main', width:'45px', height:'45px', borderRadius:'50%', display:'flex', justifyContent:'center', alignItems:'center'}}>
    <YouTubeIcon sx={{color:'text.main'}}/>
  </Box>
  </a>
  </Box>
  <Box  sx={{display:'flex', justifyContent:'start', alignItems:'center', gap: 2, mt: 8}}>
  <Box sx={{border:'1px solid #6b777f', width:'45px', height:'45px', borderRadius:'50%', display:'flex', justifyContent:'center', alignItems:'center'}}>
    <CallIcon sx={{color:'secondary.main'}}/>
  </Box>
  <a href='tel:+91 96775 95880' style={{textDecoration:'none'}}>
  <Box>
    <Typography variant='body1' sx={{fontWeight:'bold', color:'text.light'}}> +91 96775 95880</Typography>
  </Box>
  </a>
  </Box>
  <Box  sx={{display:'flex', justifyContent:'start', alignItems:'center', gap: 2, mt: 3}}>
  <Box sx={{border:'1px solid #6b777f', width:'45px', height:'45px', borderRadius:'50%', display:'flex', justifyContent:'center', alignItems:'center'}}>
    <MailIcon sx={{color:'secondary.main'}}/>
  </Box>
  <Box>
  <a href='mailto:disciplinetraderszone@gmail.com' style={{textDecoration:'none'}}>
    <Typography variant='body1' sx={{fontWeight:'bold', color:'text.light'}}>disciplinetraderszone@gmail.com</Typography>
    </a>
    <a href='mailto:sales@dtzvinoth.com' style={{textDecoration:'none'}}>
    <Typography variant='body1' sx={{fontWeight:'bold', color:'text.light'}}>sales@dtzvinoth.com</Typography>
    </a>
  </Box>
  </Box>
  {/* <Box  sx={{display:'flex', justifyContent:'start', alignItems:'center', gap: 2, mt: 3}}>
  <Box sx={{border:'1px solid #6b777f', width:'45px', height:'45px', borderRadius:'50%', display:'flex', justifyContent:'center', alignItems:'center'}}>
    <FmdGoodIcon sx={{color:'secondary.main'}}/>
  </Box>
  <Box>
    <Typography variant='body1' sx={{fontWeight:'bold', color:'text.light'}}>88 Sheridan Street

534 Victoria Trail</Typography>
  </Box>
  </Box> */}
  </Grid>
  <Grid className="commitImg" item xs={12} md={6}>
      <Box>
        <Typography variant='h6'>Name</Typography>
        <TextField fullWidth id="outlined-basic" placeholder="Full Name" variant="outlined" sx={{mt: 1, }}/>
      </Box>
      <Box sx={{mt: 3}}>
        <Typography variant='h6'>Email</Typography>
        <TextField fullWidth id="outlined-basic" placeholder="Email here" variant="outlined" sx={{mt: 1, }}/>
      </Box>
      <Box sx={{mt: 3}}>
        <Typography variant='h6'>Message</Typography>
        <TextField rows={3} multiline fullWidth id="outlined-basic" placeholder="Enter your message" variant="outlined" sx={{mt: 1, background:'#fff', }}/>
      </Box>
      <Box sx={{mt: 3}}>
        <Button fullWidth size='large' variant='contained' sx={{py: 1.9}}>Contact Us Now</Button>
      </Box>
  </Grid>
</Grid>
    </Container>
        </Box>
    </div>
  )
}

export default Contact