import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import store from "./store";
import "./index.css";
import App from "./App";
import { ThemeProvider, createTheme } from '@mui/material/styles';

const font =  "'DM Sans', sans-serif";

const theme = createTheme({
  palette: {
    primary: {
      main: '#00d094',
      light: '#d9d9d9',
      dark: '#eff8fa'
    },
    text:{
      main:'#0c263a',
      light:'#6b777f',
      white:'#fff'
    },
    secondary:{
      main:'#0a4fd5'
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform:'none',
          fontSize: '16px',
          boxShadow: 'none',
          "&:hover": {
            backgroundColor: '#00d094',
            boxShadow:'none'
          },
        },
        outlined: {
          "&:hover": {
            backgroundColor: '#fff',
          },
        },
        text: {
          "&:hover": {
            backgroundColor: 'transparent',
          },
        },
      },
    },
  },
  typography: {
    fontFamily: font,
    fontSize: 14,
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider theme={theme}>
  <Provider store={store}>
  <App />
</Provider>
</ThemeProvider>,
);