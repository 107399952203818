import React from 'react';
import './HorizontalScroll.css'; // Import CSS file with styling

const HorizontalScroll = ({ text }) => {
  return (
    <div className="horizontal-scroll">
      <div className="scrolling-text">{text}</div>
    </div>
  );
};

export default HorizontalScroll;
