import React from 'react';
import { Box, Grid, Button } from '@mui/material';
import CustomTable from '../../components/table/Table';
import Addsubcategory from './Addsubcategory';

const columns = [
  { id: 'sno', label: 'S.No', minWidth: 150, align:'start' },
  { id: 'category', label: 'Category', minWidth: 150, align:'start' },
  { id: 'subcategory', label: 'Subcategory', minWidth: 150, align:'start' },
  { id: 'img', label: 'Image', minWidth: 150, align:'center' },
  { id: 'action', label: 'Action', minWidth: 100, align:'center', actionType: [{edit: true, delete: true}] },
];

const rows = [
  {sno: '01', category: 'Cow', subcategory:'Girr', img: 'url'},
  {sno: '02', category: 'Dog', subcategory:'Girr', img: 'url'}
];

const Subcategory = () => {
  return (
    <div className="content">
        <Box>
        <Grid container spacing={2} sx={{ paddingBottom: 2, alignItems:'center' }}>
          <Grid item xs={6} sx={{paddingTop: 0}}>
            Manage Subcategory
          </Grid>
          <Grid item xs={6} sx={{textAlign: 'end', paddingTop: 0}}>
            <Addsubcategory  />
          </Grid>
          </Grid>
        </Box>
         <Box>
           <CustomTable columns={columns} rows={rows} />
         </Box>
    </div>
  );
}

export default Subcategory;