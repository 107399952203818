import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { TextField } from '@mui/material';
import CloudUpload from '../../components/button/Cloudupload';
import {Grid} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const style = {
  position: 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 12,
  p: 4,
};

export default function Addcategory() {
  const [open, setOpen] = React.useState(false);
  const [category, setCategory] = React.useState('');

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = (event) => {
    setCategory(event.target.value);
  };

  return (
    <div>
      <Button variant='contained' size='large' onClick={handleOpen}>Add Subcategory</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
            <Grid container sx={{paddingBottom: 2}}>
                <Grid xs={6} sx={{textAlign:'start'}}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
            ADD SUBCATEGORY
          </Typography>
                </Grid>
                <Grid xs={6} sx={{textAlign:'end'}}>
               <CloseIcon onClick={handleClose} sx={{cursor:'pointer'}}></CloseIcon>
                </Grid>
            </Grid>
            <hr></hr>
         <div style={{marginTop: '30px'}}>
         <FormControl sx={{marginBottom: 2}} fullWidth>
  <InputLabel id="demo-simple-select-label">Select Category</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={category}
    label="Select Category"
    onChange={handleChange}
  >
    <MenuItem value={"Cow"}>Cow</MenuItem>
    <MenuItem value={"Dog"}>Dog</MenuItem>
  </Select>
</FormControl>
         <TextField sx={{marginBottom: 2}} fullWidth id="outlined-basic" label="Subcategory Name" variant="outlined" />
         <CloudUpload/>
         <Button size='large' sx={{marginTop: 5, width: '100%'}} variant='contained'> Submit</Button>
         </div>
        </Box>
      </Modal>
    </div>
  );
}