import React, {useState, useEffect} from 'react';
import {
  Box, Button, Container, Divider, Grid, TextField, Typography,
} from "@mui/material";
import './style.css';
import takepic from '../../assets/images/takeapick.png';
import temp from '../../assets/images/choosetemp.png';
import studio from '../../assets/images/studio.png';
import arrow1 from '../../assets/images/arrow1.png';
import arrow2 from '../../assets/images/arrow2.png';
import VertTabs from '../../components/tabs/Tabs';
import userAi from '../../assets/images/users_ai.png';
import AIenhance from '../../assets/images/aienhancer.png';
import Easytask from '../../assets/images/easytask.png';
import team from '../../assets/images/team.png';
import figma1 from '../../assets/images/figma_1.png';

import uparrow from '../../assets/images/uparrow.png';
import trader from '../../assets/images/trader.png';
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';
import Reattimedata from '../../assets/images/1 (1).png';
import custSupport from '../../assets/images/customer_support.png';
import highSec from '../../assets/images/high_security.png';
import lowCommis from '../../assets/images/lower_commis.png';
import check from '../../assets/images/check.png';
import ourcommit from '../../assets/images/aboutus.png';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import starery from '../../assets/images/stagery.png';
import financeadvi from '../../assets/images/financeadvi.png';
import management from '../../assets/images/management.png';
import check1 from '../../assets/images/check1.svg';
import MultipleItems from '../../components/carousal/Carousal';
import faq from '../../assets/images/faq.png';
import CustomizedAccordions from '../../components/accordion/Accordion';
import contact from '../../assets/images/contact.png';
import ScrollToTop from '../../components/ScrollToTop';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 15,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));


const Services = ({setLoader}) => {


  return (
    <div>
    <ScrollToTop/>
    <Box className='bannerBg'>
    <Container maxWidth="lg" sx={{py: 12}}>
      <Typography variant='h3' sx={{color:'text.white', fontWeight:'bold'}}>Services</Typography>
      <Typography variant='h6' sx={{fontFamily:"'Sora', sans-serif", fontWeight:'medium',  color:'text.white', mt: 3}}>
          <Typography variant='p' sx={{color:'primary.main'}}>Home</Typography> / Services
          </Typography>
</Container>
    </Box>
    <Box className="fourthSec" sx={{position:'relative'}}>
    <Container maxWidth="lg">
          <Typography variant='h3' sx={{fontFamily:"'Sora', sans-serif", fontWeight:'bold', textAlign:'center', color:'text.main'}}>
          <Typography variant='p' sx={{color:'secondary.main'}}>Services</Typography> We Offer
          </Typography>
          <Typography variant='h6' sx={{color:'text.light', fontSize:'18px', fontWeight:'normal', my: 3, textAlign:'center'}}>
We are proud to offer three essential services to our DTZ family</Typography>
          <Grid container spacing={3} sx={{mt: 5}}>
              <Grid item xs={12} md={4}>
                  <Box sx={{background:'linear-gradient(152deg, rgba(237,242,248,.22) 0, #edf2f8 100%)', borderRadius: 3, p: 7, border:'2px solid #fff'}}>
                  <Box sx={{ display:'flex', justifyContent:'center', alignItems:'center', mb: 4}}>
                  <Box sx={{background:'#fff', p: 3, borderRadius:'50%'}}>
                  <img src={starery} alt='real'></img>
                  </Box>
                  </Box>
                  <Typography variant='h5' sx={{fontWeight:'bold', textAlign:'center', mt: 1}}>Algorithmic Trading</Typography>
                  <Typography variant='body1' sx={{color:'text.light', textAlign:'center', my: 2}}>Experience the future of trading with our cutting-edge algorithmic trading services, providing automation and optimization for your trading strategies</Typography>
                  </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                  <Box sx={{background:'linear-gradient(152deg, rgba(237,242,248,.22) 0, #edf2f8 100%)', borderRadius: 3, p: 7, border:'2px solid #fff'}}>
                  <Box sx={{ display:'flex', justifyContent:'center', alignItems:'center', mb: 4}}>
                  <Box sx={{background:'#fff', p: 3, borderRadius:'50%'}}>
                  <img src={financeadvi} alt='real'></img>
                  </Box>
                  </Box>
                  <Typography variant='h5' sx={{fontWeight:'bold', textAlign:'center', mt: 1}}>Option Buying Class</Typography>
                  <Typography variant='body1' sx={{color:'text.light', textAlign:'center', my: 2}}>Dive into the world of option buying with our comprehensive classes, designed to empower you with knowledge and skills for successful trading.</Typography>
                  </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                  <Box sx={{background:'linear-gradient(152deg, rgba(237,242,248,.22) 0, #edf2f8 100%)', borderRadius: 3, p: 7, border:'2px solid #fff'}}>
                  <Box sx={{ display:'flex', justifyContent:'center', alignItems:'center', mb: 4}}>
                  <Box sx={{background:'#fff', p: 3, borderRadius:'50%'}}>
                  <img src={management} alt='real'></img>
                  </Box>
                  </Box>
                  <Typography variant='h5' sx={{fontWeight:'bold', textAlign:'center', mt: 1}}>Option Selling Class</Typography>
                  <Typography variant='body1' sx={{color:'text.light', textAlign:'center', my: 2}}>Explore advanced techniques in option selling through our specialized classes, aimed at enhancing your proficiency and confidence in the options market.</Typography>
                  </Box>
              </Grid>
          </Grid>
    </Container>
        </Box>
        <Box className="sixthSec" >
    <Container maxWidth="lg">
        <Grid container spacing={2}>
            <Grid item xs={12} md={7}>
            <Typography variant='h3' sx={{fontFamily:"'Sora', sans-serif", fontWeight:'bold',  color:'text.main'}}>Connect With
           <Typography variant='p' sx={{color:'secondary.main'}}> Our Clients</Typography>
          </Typography>
          <Typography variant='h6' sx={{color:'text.light', fontSize:'18px', fontWeight:'normal', my: 3, }}>We love connecting with our clients to hear about their experiences and how we can improve.</Typography>
            </Grid>
        </Grid>
          <Grid container spacing={3} sx={{mt:{md: 0, xs: 5}, alignItems:'center'}}>
              <Grid item xs={12} md={12}>
                  <MultipleItems/>
              </Grid>
          </Grid>
    </Container>
        </Box>
    </div>
  )
}

export default Services