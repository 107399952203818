import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import "./style.css";
import takepic from "../../assets/images/takeapick.png";
import temp from "../../assets/images/choosetemp.png";
import studio from "../../assets/images/studio.png";
import arrow1 from "../../assets/images/arrow1.png";
import arrow2 from "../../assets/images/arrow2.png";
import VertTabs from "../../components/tabs/Tabs";
import userAi from "../../assets/images/users_ai.png";
import AIenhance from "../../assets/images/aienhancer.png";
import Easytask from "../../assets/images/easytask.png";
import team from "../../assets/images/team.png";
import figma1 from "../../assets/images/figma_1.png";

import uparrow from "../../assets/images/uparrow.png";
import trader from "../../assets/images/trader.png";
import PlayCircleOutlinedIcon from "@mui/icons-material/PlayCircleOutlined";
import Reattimedata from "../../assets/images/1 (1).png";
import custSupport from "../../assets/images/customer_support.png";
import highSec from "../../assets/images/high_security.png";
import lowCommis from "../../assets/images/lower_commis.png";
import check from "../../assets/images/check.png";
import ourcommit from "../../assets/images/aboutus.png";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import starery from "../../assets/images/stagery.png";
import financeadvi from "../../assets/images/financeadvi.png";
import management from "../../assets/images/management.png";
import check1 from "../../assets/images/check1.svg";
import MultipleItems from "../../components/carousal/Carousal";
import faq from "../../assets/images/faq.png";
import CustomizedAccordions from "../../components/accordion/Accordion";
import contact from "../../assets/images/contact.png";
import course from "../../assets/images/sell.jpg";
import testi from "../../assets/images/testi.png";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Link } from "react-router-dom";
import CustomizedAccordions2 from "../../components/accordion/Accordion2";
import ScrollToTop from "../../components/ScrollToTop";
import BasicTabs from "../../components/tabs/Tabs3";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 15,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

const Coursedetailsell = ({ setLoader }) => {
  return (
    <div>
    <ScrollToTop/>
      <Box className="bannerBg">
        <Container maxWidth="lg" sx={{ py: 12 }}>
          <Typography
            variant="h3"
            sx={{ color: "text.white", fontWeight: "bold" }}
          >
            Course Details
          </Typography>
          <Typography
            variant="h6"
            sx={{
              fontFamily: "'Sora', sans-serif",
              fontWeight: "medium",
              color: "text.white",
              mt: 3,
            }}
          >
            <Typography variant="p" sx={{ color: "primary.main" }}>
              Home
            </Typography>{" "}
            / Course Details
          </Typography>
        </Container>
      </Box>
      <Container maxWidth="lg" sx={{ py: 10 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <Typography variant="h4" sx={{ fontWeight: "bold" }}>
            Selling Options with Confidence: Mastering the Art of Option Selling
            </Typography>
            <Typography
              component="p"
              sx={{
                color: "text.light",
                fontSize: "16px",
                fontWeight: "normal",
                mt: 2,
                lineHeight: 2
              }}
            >
             Master option selling confidently with our course. Learn strategies and risk management to excel in this profitable approach, for traders of all levels.
            </Typography>
            <Typography variant='h6' sx={{mt: 2}}>4.9 &#9733; (526 Students)</Typography>
            <Box sx={{mt: 2}}>
            <BasicTabs/>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
          <Card sx={{boxShadow:'none', borderRadius: 3, border: '1px solid #cacaca'}}>
      <CardMedia
        sx={{ height: 250 }}
        image={course}
        title="green iguana"
      />
      <CardContent>
        <Grid container spacing={2} sx={{alignItems:'center'}}>
        <Grid item xs={12} sx={{display:'flex', alignItems:'center'}}>
        <Typography variant='h4' sx={{fontWeight:'bold'}}>₹19999</Typography>
            <Typography variant='body1' sx={{textDecoration:'line-through', ml: 1}}>₹39000</Typography>
            <Typography variant='body1' sx={{ ml: 1}}>40% off</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1' sx={{color: 'primary.main', display:'inline-block', px: 1, borderRadius: 5}}> 5 days left at this price!</Typography>
          </Grid>
          <Grid item xs={12}>
          <a href='https://wa.link/l8q5eh' target='_blank'>
          <Button size="large" fullWidth variant="contained">Buy Now</Button>
          </a>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
          </Grid>
        </Grid>
      </Container>
      {/* <Box sx={{py: 5, background:'#fbfbfb'}} >
    <Container maxWidth="lg">
          <Grid container spacing={3} sx={{ alignItems:'center'}}>
          <Grid item xs={12} md={8}>
          <Typography variant="h4" sx={{ fontWeight: "bold" }}>
          Course Curriculum
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
          <Box sx={{background:'#fff', p: 2}}>
          <Typography variant="h5" sx={{ fontWeight: "bold", textAlign:'center' }}>
          05
            </Typography>
            <Typography variant="h6" sx={{ fontWeight: "bold", textAlign:'center' }}>
          Topic
            </Typography>
          </Box>
          <Box sx={{background:'#fff', p: 2}}>
          <Typography variant="h5" sx={{ fontWeight: "bold", textAlign:'center' }}>
          50
            </Typography>
            <Typography variant="h6" sx={{ fontWeight: "bold", textAlign:'center' }}>
          Lesson
            </Typography>
          </Box>
          <Box sx={{background:'#fff', p: 2}}>
          <Typography variant="h5" sx={{ fontWeight: "bold", textAlign:'center' }}>
          20
            </Typography>
            <Typography variant="h6" sx={{ fontWeight: "bold", textAlign:'center' }}>
          Video
            </Typography>
          </Box>
          </Grid>
              <Grid item xs={12} >
                  <CustomizedAccordions2/>
              </Grid>
          </Grid>
    </Container>
        </Box> */}
    </div>
  );
};

export default Coursedetailsell;
