import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import Slider from "react-slick";
import testi from "../../assets/images/testi.png";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <Box
        className={className}
        sx={{ ...style, display: "block", background: "#00d094", p: 2, borderRadius:'50%',     position: 'absolute',
        top: {md: '-100px', xs:'-45px'},
    right: {md: '0px', xs:'240px'}}}
        onClick={onClick}
      />
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <Box
        className={className}
        sx={{ ...style, display: "block", background: "#00d094", p: 2, borderRadius:'50%',     position: 'absolute',
    top: {md: '-100px', xs:'-45px'},
    left: {md: '1030px', xs:'0px'},
    right: 0 }}
        onClick={onClick}
      />
    );
  }

function MultipleItems() {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
  };
  return (
    <div className="slider-container">
      <Slider {...settings}>
        <div style={{marginRight: '10px'}}>
        <Box>
          <Box
            sx={{
              background: "#fff",
              borderRadius: 4,
              p: {md:5 , xs: 2},
              m: {md:2 , xs: 0},
              border: "2px solid #fff",
            }}
          >
            <Typography
              component="p"
              sx={{
                color: "text.light",
                fontSize: "16px",
                fontWeight: "normal",
              }}
            >
              " DTZ Trading Academy's option buying and selling classes have been a game-changer for me. The in-depth knowledge and practical strategies I gained have significantly improved my trading results. I highly recommend it to anyone looking to excel in options trading. "
            </Typography>
            <Divider sx={{ my: 3 }} />
            <Grid container spacing={2} sx={{ alignItems: "center" }}>
              <Grid
                item
                xs={8}
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <img src={testi} alt="testi"></img>
                <Box>
                  <Typography variant="h6">John Smith</Typography>
                  <Typography
                    component="p"
                    sx={{
                      color: "text.light",
                      fontSize: "16px",
                      fontWeight: "normal",
                    }}
                  >
                    Trade Master
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={4} sx={{ textAlign: "end" }}>
                <FormatQuoteIcon sx={{ color: "primary.main", fontSize: 50 }} />
              </Grid>
            </Grid>
          </Box>
          </Box>
        </div>
        <div>
        <Box>
          <Box
            sx={{
              background: "#fff",
              borderRadius: 4,
              p: {md:5 , xs: 2},
              m: {md:2 , xs: 0},
              border: "2px solid #fff",
            }}
          >
            <Typography
              component="p"
              sx={{
                color: "text.light",
                fontSize: "16px",
                fontWeight: "normal",
              }}
            >
              " I've been following DTZ Trading Academy's YouTube live sessions religiously, and I must say, they provide invaluable insights into the market trends and trading strategies. The live interactions make learning enjoyable and interactive. Kudos to the team for their dedication! "
            </Typography>
            <Divider sx={{ my: 3 }} />
            <Grid container spacing={2} sx={{ alignItems: "center" }}>
              <Grid
                item
                xs={8}
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <img src={testi} alt="testi"></img>
                <Box>
                  <Typography variant="h6">Sarah Johnson</Typography>
                  <Typography
                    component="p"
                    sx={{
                      color: "text.light",
                      fontSize: "16px",
                      fontWeight: "normal",
                    }}
                  >
                    Trade Master
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={4} sx={{ textAlign: "end" }}>
                <FormatQuoteIcon sx={{ color: "primary.main", fontSize: 50 }} />
              </Grid>
            </Grid>
          </Box>
          </Box>
        </div>
        <div>
        <Box>
          <Box
            sx={{
              background: "#fff",
              borderRadius: 4,
              p: {md:5 , xs: 2},
              m: {md:2 , xs: 0},
              border: "2px solid #fff",
            }}
          >
            <Typography
              component="p"
              sx={{
                color: "text.light",
                fontSize: "16px",
                fontWeight: "normal",
              }}
            >
              " DTZ Trading Academy's algorithmic trading service has transformed the way I trade. The automation and optimization provided by their algorithms have not only saved me time but also significantly improved my trading performance. It's like having a personal trading assistant 24/7! " 
            </Typography>
            <Divider sx={{ my: 3 }} />
            <Grid container spacing={2} sx={{ alignItems: "center" }}>
              <Grid
                item
                xs={8}
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <img src={testi} alt="testi"></img>
                <Box>
                  <Typography variant="h6">Michael Brown</Typography>
                  <Typography
                    component="p"
                    sx={{
                      color: "text.light",
                      fontSize: "16px",
                      fontWeight: "normal",
                    }}
                  >
                    Trade Master
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={4} sx={{ textAlign: "end" }}>
                <FormatQuoteIcon sx={{ color: "primary.main", fontSize: 50 }} />
              </Grid>
            </Grid>
          </Box>
          </Box>
        </div>
        <div>
        <Box>
          <Box
            sx={{
              background: "#fff",
              borderRadius: 4,
              p: {md:5 , xs: 2},
              m: {md:2 , xs: 0},
              border: "2px solid #fff",
            }}
          >
            <Typography
              component="p"
              sx={{
                color: "text.light",
                fontSize: "16px",
                fontWeight: "normal",
              }}
            >
              " I am grateful for DTZ Trading Academy's comprehensive approach to educating traders. Their emphasis on both theory and practice has helped me understand the nuances of the market better. I feel more confident in my trading decisions now, thanks to their guidance. "  
            </Typography>
            <Divider sx={{ my: 3 }} />
            <Grid container spacing={2} sx={{ alignItems: "center" }}>
              <Grid
                item
                xs={8}
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <img src={testi} alt="testi"></img>
                <Box>
                  <Typography variant="h6">Emily Chen</Typography>
                  <Typography
                    component="p"
                    sx={{
                      color: "text.light",
                      fontSize: "16px",
                      fontWeight: "normal",
                    }}
                  >
                    Trade Master
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={4} sx={{ textAlign: "end" }}>
                <FormatQuoteIcon sx={{ color: "primary.main", fontSize: 50 }} />
              </Grid>
            </Grid>
          </Box>
          </Box>
        </div>
      </Slider>
    </div>
  );
}

export default MultipleItems;
