import React, {useState, useEffect} from 'react';
import {
  Box, Button, Card, CardActions, CardContent, CardMedia, Container, Divider, Grid, TextField, Typography,
} from "@mui/material";
import './style.css';
import takepic from '../../assets/images/takeapick.png';
import temp from '../../assets/images/choosetemp.png';
import studio from '../../assets/images/studio.png';
import arrow1 from '../../assets/images/arrow1.png';
import arrow2 from '../../assets/images/arrow2.png';
import VertTabs from '../../components/tabs/Tabs';
import userAi from '../../assets/images/users_ai.png';
import AIenhance from '../../assets/images/aienhancer.png';
import Easytask from '../../assets/images/easytask.png';
import team from '../../assets/images/team.png';
import figma1 from '../../assets/images/figma_1.png';

import uparrow from '../../assets/images/uparrow.png';
import trader from '../../assets/images/trader.png';
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';
import Reattimedata from '../../assets/images/1 (1).png';
import custSupport from '../../assets/images/customer_support.png';
import highSec from '../../assets/images/high_security.png';
import lowCommis from '../../assets/images/lower_commis.png';
import check from '../../assets/images/check.png';
import ourcommit from '../../assets/images/aboutus.png';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import starery from '../../assets/images/stagery.png';
import financeadvi from '../../assets/images/financeadvi.png';
import management from '../../assets/images/management.png';
import check1 from '../../assets/images/check1.svg';
import MultipleItems from '../../components/carousal/Carousal';
import faq from '../../assets/images/faq.png';
import CustomizedAccordions from '../../components/accordion/Accordion';
import contact from '../../assets/images/contact.png';
import course from '../../assets/images/course.jpg';
import user from '../../assets/images/user_course.png';
import { Link } from 'react-router-dom';
import ScrollToTop from '../../components/ScrollToTop';
import buy from '../../assets/images/buy.jpg';
import sell from '../../assets/images/sell.jpg';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 15,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));


const Course = ({setLoader}) => {


  return (
    <div>
    <ScrollToTop/>
    <Box className='bannerBg'>
    <Container maxWidth="lg" sx={{py: 12}}>
      <Typography variant='h3' sx={{color:'text.white', fontWeight:'bold'}}>Course</Typography>
      <Typography variant='h6' sx={{fontFamily:"'Sora', sans-serif", fontWeight:'medium',  color:'text.white', mt: 3}}>
          <Typography variant='p' sx={{color:'primary.main'}}>Home</Typography> / Course
          </Typography>
</Container>
    </Box>
        <Box className="thirdSec" sx={{position:'relative'}}>
    <Container maxWidth="lg">
    <Grid container spacing={3}>
    <Grid item xs={12} md={4}>
    <Link to='buydetail' style={{textDecoration:'none'}}>
    <Card sx={{boxShadow:'none', borderRadius: 1}}>
      <CardMedia
        sx={{ height: 250 }}
        image={buy}
        title="green iguana"
      />
      <CardContent sx={{px: 0}}>
        <Grid container spacing={2} sx={{alignItems:'center'}}>
          <Grid item xs={4}>
            <Typography variant='body1' sx={{backgroundColor: 'green', display:'inline-block', px: 1, borderRadius: 5, color:'#fff'}}>Buying</Typography>
          </Grid>
          <Grid item xs={8} sx={{textAlign:'end'}}>
            <Typography variant='body1'>4.9 &#9733; (526)</Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography variant='h6' sx={{fontWeight:'medium', fontSize: 18}}>Mastering Option Buying: A Comprehensive
Guide to Successful Trading</Typography>
          </Grid>
          <Grid item xs={8} sx={{display:'flex', justifyContent:'start', alignItems:'center', gap: 1}}>
          <img src={user} alt="testi" width={30}></img>
                  <Typography
                    component="p"
                    sx={{
                      color: "text.light",
                      fontSize: "16px",
                      fontWeight: "normal",
                    }}
                  >
                    DTZ Trading Academy
                  </Typography>
          </Grid>
          <Grid item xs={4} sx={{display:'flex', justifyContent:'end', alignItems:'center'}}>
            <Typography variant='body1' sx={{textDecoration:'line-through', mr: 1}}>₹10000</Typography>
            <Typography variant='h6'>₹5999</Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
    </Link>
    </Grid>
    <Grid item xs={12} md={4}>
    <Link to='selldetail' style={{textDecoration:'none'}}>
    <Card sx={{boxShadow:'none', borderRadius: 1}}>
      <CardMedia
        sx={{ height: 250 }}
        image={sell}
        title="green iguana"
      />
      <CardContent sx={{px:0}}>
        <Grid container spacing={2} sx={{alignItems:'center'}}>
          <Grid item xs={4}>
            <Typography variant='body1' sx={{backgroundColor: 'red', display:'inline-block', px: 1, borderRadius: 5, color:'#fff'}}>Selling</Typography>
          </Grid>
          <Grid item xs={8} sx={{textAlign:'end'}}>
            <Typography variant='body1'>4.9 &#9733; (526)</Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography variant='h6' sx={{fontWeight:'medium', fontSize: 18}}>Selling Options with Confidence: Mastering 
the Art of Option Selling</Typography>
          </Grid>
          <Grid item xs={8} sx={{display:'flex', justifyContent:'start', alignItems:'center', gap: 1}}>
          <img src={user} alt="testi" width={30}></img>
                  <Typography
                    component="p"
                    sx={{
                      color: "text.light",
                      fontSize: "16px",
                      fontWeight: "normal",
                    }}
                  >
                    DTZ Trading Academy
                  </Typography>
          </Grid>
          <Grid item xs={4} sx={{display:'flex', justifyContent:'end', alignItems:'center'}}>
            <Typography variant='body1' sx={{textDecoration:'line-through', mr: 1}}>₹39000</Typography>
            <Typography variant='h6'>₹19999</Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
    </Link>
    </Grid>
</Grid>
    </Container>
        </Box>
    </div>
  )
}

export default Course