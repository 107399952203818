import React, {useState, useEffect} from 'react';
import {
  Box, Button, Card, CardContent, CardMedia, Container, Divider, Grid, TextField, Typography,
} from "@mui/material";
import './style.css';
import takepic from '../../assets/images/takeapick.png';
import temp from '../../assets/images/choosetemp.png';
import studio from '../../assets/images/studio.png';
import arrow1 from '../../assets/images/arrow1.png';
import arrow2 from '../../assets/images/arrow2.png';
import VertTabs from '../../components/tabs/Tabs';
import userAi from '../../assets/images/users_ai.png';
import AIenhance from '../../assets/images/aienhancer.png';
import Easytask from '../../assets/images/easytask.png';
import team from '../../assets/images/team.png';
import figma1 from '../../assets/images/figma_1.png';

import uparrow from '../../assets/images/uparrow.png';
import trader from '../../assets/images/trader.png';
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';
import Reattimedata from '../../assets/images/1 (1).png';
import custSupport from '../../assets/images/customer_support.png';
import highSec from '../../assets/images/high_security.png';
import lowCommis from '../../assets/images/lower_commis.png';
import check from '../../assets/images/check.png';
import ourcommit from '../../assets/images/aboutus.png';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import starery from '../../assets/images/stagery.png';
import financeadvi from '../../assets/images/financeadvi.png';
import management from '../../assets/images/management.png';
import check1 from '../../assets/images/check1.svg';
import MultipleItems from '../../components/carousal/Carousal';
import faq from '../../assets/images/faq.png';
import CustomizedAccordions from '../../components/accordion/Accordion';
import contact from '../../assets/images/contact.png';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import TelegramIcon from '@mui/icons-material/Telegram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import MailIcon from '@mui/icons-material/Mail';
import CallIcon from '@mui/icons-material/Call';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import ScrollToTop from '../../components/ScrollToTop';
import firstock from '../../assets/images/firstock.png';
import aliceblue from '../../assets/images/aliceblue.png';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 15,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));


const Referral = ({setLoader}) => {


  return (
    <div>
    <ScrollToTop/>
    <Box className='bannerBg'>
    <Container maxWidth="lg" sx={{py: 12}}>
      <Typography variant='h3' sx={{color:'text.white', fontWeight:'bold'}}>Referral Trading Account</Typography>
      <Typography variant='h6' sx={{fontFamily:"'Sora', sans-serif", fontWeight:'medium',  color:'text.white', mt: 3}}>
          <Typography variant='p' sx={{color:'primary.main'}}>Home</Typography> / Referral Trading Account
          </Typography>
</Container>
    </Box>
        <Box className="thirdSec" sx={{position:'relative'}}>
    <Container maxWidth="md" sx={{justifyContent:'center'}}>
    <Typography variant='h4' sx={{color:'primary.main', textAlign:'center', fontWeight:'bold'}}> Referral Trading Account</Typography> 
    <Grid container spacing={5} sx={{mt: 4}}>
      <Grid item md={6} xs={12}>
        <Card sx={{boxShadow:'none'}}>
        <CardMedia
        sx={{ height: {xs: 350, md: 400} }}
        image={firstock}
        title="green iguana"
      />
            <CardContent sx={{px:0}}>
            <a href='https://signup.thefirstock.com?p=DTZ94' target='_blank'>
            <Button fullWidth sx={{py: 2}} variant='contained'>Open Now</Button>
            </a>
</CardContent>
        </Card>
      </Grid>
      <Grid item md={6} xs={12}>
        <Card sx={{boxShadow:'none'}}>
        <CardMedia
        sx={{ height: {xs: 300, md: 400} }}
        image={aliceblue}
        title="green iguana"
      />
            <CardContent sx={{px:0}}>
            <a href='https://accountopeningaliceblue.com/?m=SCHE567&DiciplineTradersZoneVinoth' target='_blank'>
            <Button fullWidth sx={{py: 2}} variant='contained'>Open Now</Button>
            </a>
</CardContent>
        </Card>
      </Grid>
    </Grid>
    </Container>
        </Box>
    </div>
  )
}

export default Referral