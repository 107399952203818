import * as React from 'react';
import { styled } from '@mui/material/styles';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { Box } from '@mui/material';
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  '&::before': {
    display: 'none',
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(1, 2),
}));

export default function CustomizedAccordions2() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };


  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      color:'#00d094'
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      color:'#00d094'
    },
  }));

  return (
    <div>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary 
        expandIcon={expanded ==='panel1' ? <RemoveCircleOutlineIcon  sx={{ fontSize: '1.5rem' }}/> : <AddCircleOutlineIcon sx={{ fontSize: '1.5rem' }}/>}
        aria-controls="panel1d-content" id="panel1d-header">
          <Typography variant='h6'>Course Introduction - Lesson 1</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Box sx={{display:'flex', justifyContent:'start', alignItems:'center', gap: 2}}>
        <PlayCircleOutlinedIcon/>
<Typography  component='p' sx={{fontSize: '16px', fontWeight:'medium'}}>Basic knowledge and detailed understanding of CSS3 to create attract websites</Typography>
  </Box>
  <Box sx={{display:'flex', justifyContent:'start', alignItems:'center', gap: 2, mt: 2}}>
        <PlayCircleOutlinedIcon/>
<Typography  component='p' sx={{fontSize: '16px', fontWeight:'medium'}}>Basic knowledge and detailed understanding of CSS3 to create attract websites</Typography>
  </Box>
  <Box sx={{display:'flex', justifyContent:'start', alignItems:'center', gap: 2, my: 2}}>
        <PlayCircleOutlinedIcon/>
<Typography  component='p' sx={{fontSize: '16px', fontWeight:'medium'}}>Basic knowledge and detailed understanding of CSS3 to create attract websites</Typography>
  </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary 
                expandIcon={expanded ==='panel2' ? <RemoveCircleOutlineIcon  sx={{ fontSize: '1.5rem' }}/> : <AddCircleOutlineIcon sx={{ fontSize: '1.5rem' }}/>}
        aria-controls="panel2d-content" id="panel2d-header">
          <Typography variant='h6'>Tools and Software - Lesson 2</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Box sx={{display:'flex', justifyContent:'start', alignItems:'center', gap: 2}}>
        <PlayCircleOutlinedIcon/>
<Typography  component='p' sx={{fontSize: '16px', fontWeight:'medium'}}>Basic knowledge and detailed understanding of CSS3 to create attract websites</Typography>
  </Box>
  <Box sx={{display:'flex', justifyContent:'start', alignItems:'center', gap: 2, mt: 2}}>
        <PlayCircleOutlinedIcon/>
<Typography  component='p' sx={{fontSize: '16px', fontWeight:'medium'}}>Basic knowledge and detailed understanding of CSS3 to create attract websites</Typography>
  </Box>
  <Box sx={{display:'flex', justifyContent:'start', alignItems:'center', gap: 2, my: 2}}>
        <PlayCircleOutlinedIcon/>
<Typography  component='p' sx={{fontSize: '16px', fontWeight:'medium'}}>Basic knowledge and detailed understanding of CSS3 to create attract websites</Typography>
  </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary
        expandIcon={expanded ==='panel3' ? <RemoveCircleOutlineIcon  sx={{ fontSize: '1.5rem' }}/> : <AddCircleOutlineIcon sx={{ fontSize: '1.5rem' }}/>}
         aria-controls="panel3d-content" id="panel3d-header">
          <Typography variant='h6'>HTML - Lesson 3</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Box sx={{display:'flex', justifyContent:'start', alignItems:'center', gap: 2}}>
        <PlayCircleOutlinedIcon/>
<Typography  component='p' sx={{fontSize: '16px', fontWeight:'medium'}}>Basic knowledge and detailed understanding of CSS3 to create attract websites</Typography>
  </Box>
  <Box sx={{display:'flex', justifyContent:'start', alignItems:'center', gap: 2, mt: 2}}>
        <PlayCircleOutlinedIcon/>
<Typography  component='p' sx={{fontSize: '16px', fontWeight:'medium'}}>Basic knowledge and detailed understanding of CSS3 to create attract websites</Typography>
  </Box>
  <Box sx={{display:'flex', justifyContent:'start', alignItems:'center', gap: 2, my: 2}}>
        <PlayCircleOutlinedIcon/>
<Typography  component='p' sx={{fontSize: '16px', fontWeight:'medium'}}>Basic knowledge and detailed understanding of CSS3 to create attract websites</Typography>
  </Box>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}