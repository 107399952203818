import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import check1 from '../../assets/images/check1.svg';
import { Grid, Rating } from '@mui/material';
import instructor from '../../assets/images/Vinoth_About.jpg';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import TelegramIcon from '@mui/icons-material/Telegram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import lokesh from '../../assets/images/lokesh.jpeg';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 4 }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Overview" {...a11yProps(0)} />
          <Tab label="Curriculum" {...a11yProps(1)} />
          <Tab label="Instructor" {...a11yProps(2)} />
          <Tab label="Reviews" {...a11yProps(3)} />
          <Tab label="Help" {...a11yProps(3)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
      <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            Study Plan
            </Typography>
            <Typography
              component="p"
              sx={{
                color: "text.light",
                fontSize: "16px",
                fontWeight: "normal",
                mt: 1,
              }}
            >
Our Option Buying Class offers a comprehensive guide to understanding and mastering the art of buying options. Designed for traders of all levels, this course provides valuable insights, strategies, and techniques to help you navigate the options market with confidence. Whether you're a beginner looking to build a solid foundation or an experienced trader seeking to refine your skills, this class will equip you with the knowledge and tools needed to succeed in option buying.</Typography>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
      <Typography variant="h5" sx={{ fontWeight: "bold", mt: 2 }}>
What will you learn from this course?
            </Typography>
            <Box sx={{display:'flex', justifyContent:'start', alignItems:'center', gap: 2, mt: 2}}>
  <img src={check1} alt='check'></img>
<Typography  component='p' sx={{fontSize: '16px', fontWeight:'medium'}}>Introduction to Options: Understanding the basics of options trading, including terminology, concepts, and market dynamics.</Typography>
  </Box>
  <Box sx={{display:'flex', justifyContent:'start', alignItems:'center', gap: 2, mt: 2}}>
  <img src={check1} alt='check'></img>
<Typography  component='p' sx={{fontSize: '16px', fontWeight:'medium'}}>Types of Options: Exploring the different types of options available, including calls, puts, and their variations.</Typography>
  </Box>
  <Box sx={{display:'flex', justifyContent:'start', alignItems:'center', gap: 2, mt: 2}}>
  <img src={check1} alt='check'></img>
<Typography  component='p' sx={{fontSize: '16px', fontWeight:'medium'}}>Option Pricing: Learning how options are priced and the factors that influence their value</Typography>
  </Box>
  <Box sx={{display:'flex', justifyContent:'start', alignItems:'center', gap: 2, mt: 2}}>
  <img src={check1} alt='check'></img>
<Typography  component='p' sx={{fontSize: '16px', fontWeight:'medium'}}>Strategies for Option Buying: Exploring various option buying strategies, such as long calls, long puts, and spreads.</Typography>
  </Box>
  <Box sx={{display:'flex', justifyContent:'start', alignItems:'center', gap: 2, mt: 2}}>
  <img src={check1} alt='check'></img>
<Typography  component='p' sx={{fontSize: '16px', fontWeight:'medium'}}> Risk Management: Understanding the importance of risk management in options trading and implementing effective risk management strategies.</Typography>
  </Box>
  <Box sx={{display:'flex', justifyContent:'start', alignItems:'center', gap: 2, mt: 2}}>
  <img src={check1} alt='check'></img>
<Typography  component='p' sx={{fontSize: '16px', fontWeight:'medium'}}>Advanced Topics: Diving into advanced topics such as volatility, option Greeks, and position sizing.</Typography>
  </Box>
  <Box sx={{display:'flex', justifyContent:'start', alignItems:'center', gap: 2, mt: 2}}>
  <img src={check1} alt='check'></img>
<Typography  component='p' sx={{fontSize: '16px', fontWeight:'medium'}}>Case Studies and Examples: Analyzing real-world examples and case studies to illustrate key concepts and strategies.</Typography>
  </Box>
  <Box sx={{display:'flex', justifyContent:'start', alignItems:'center', gap: 2, mt: 2}}>
  <img src={check1} alt='check'></img>
<Typography  component='p' sx={{fontSize: '16px', fontWeight:'medium'}}> Trading Psychology: Developing the right mindset and discipline for successful option buying.</Typography>
  </Box>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
                <img src={instructor} alt='image' width='100%' style={{borderRadius: 20}}></img>
            </Grid>
            <Grid item xs={12} md={4}>
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
  Vinoth
            </Typography>
            <Typography
              component="p"
              sx={{
                color: "text.light",
                fontSize: "16px",
                fontWeight: "normal",
              }}
            >
Founder & CEO</Typography>
<Typography
              component="p"
              sx={{
                color: "text.light",
                fontSize: "16px",
                fontWeight: "normal",
                mt: 2,
              }}
            >
I'm Vinoth, founder of DTZ Trading Academy. With a passion for innovation, I aim to democratize access to algorithmic trading, empowering traders of all levels. Join us to revolutionize trading and unlock new growth opportunities.</Typography>
<Box sx={{display:'flex', justifyContent:{md:'start', xs:'start'}, alignItems:'center', gap: 3, mt: 3}}>
          <a href='https://wa.link/l8q5eh' target='_blank' style={{textDecoration:'none'}}>
          <Box sx={{border:'2px solid', borderColor:'primary.main', width:'45px', height:'45px', borderRadius:'50%', display:'flex', justifyContent:'center', alignItems:'center'}}>
    <WhatsAppIcon sx={{color:'text.main'}}/>
  </Box>
  </a>
  <a href='https://www.instagram.com/dtz_vinoth/' target='_blank' style={{textDecoration:'none'}}>
  <Box sx={{border:'2px solid', borderColor:'primary.main', width:'45px', height:'45px', borderRadius:'50%', display:'flex', justifyContent:'center', alignItems:'center'}}>
    <InstagramIcon sx={{color:'text.main'}}/>
  </Box>
  </a>
  <a href='https://t.me/DTZcommunity/' target='_blank' style={{textDecoration:'none'}}>
  <Box sx={{border:'2px solid ', borderColor:'primary.main', width:'45px', height:'45px', borderRadius:'50%', display:'flex', justifyContent:'center', alignItems:'center'}}>
    <TelegramIcon sx={{color:'text.main'}}/>
  </Box>
  </a>
  <a href='https://www.youtube.com/@DisciplineTradersZone' target='_blank' style={{textDecoration:'none'}}>
  <Box sx={{border:'2px solid', borderColor:'primary.main', width:'45px', height:'45px', borderRadius:'50%', display:'flex', justifyContent:'center', alignItems:'center'}}>
    <YouTubeIcon sx={{color:'text.main'}}/>
  </Box>
  </a>
  </Box>
            </Grid>
        </Grid>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
      <Typography variant="h4" sx={{ fontWeight: "bold" }}>
  Reviews
            </Typography>
        <Grid container spacing={4} sx={{mt: 2}}>
            <Grid item xs={12} md={2}>
                <img src={lokesh} alt='image' width={100} style={{borderRadius: '50%'}}></img>
            </Grid>
            <Grid item xs={12} md={8}>
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            Lokesh V
            </Typography>
            <Typography
              component="p"
              sx={{
                color: "text.light",
                fontSize: "16px",
                fontWeight: "normal",
              }}
            >
Nexarcit CEO</Typography>
<Typography
              component="p"
              sx={{
                color: "text.light",
                fontSize: "16px",
                fontWeight: "normal",
                mt: 2,
              }}
            >
I found the Option Buying Class to be incredibly informative and practical. The course covers everything from the basics to advanced strategies, and the instructor's teaching style makes complex concepts easy to understand. I highly recommend this class to anyone looking to improve their options trading skills.</Typography>
            </Grid>
            <Grid item xs={12} md={2}>
            <Rating name="half-rating" defaultValue={4.5} precision={0.5} />
            </Grid>
        </Grid>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4}>
      <Typography variant="h4" sx={{ fontWeight: "bold" }}>
      For details about the course
            </Typography>
            <Typography
              component="p"
              sx={{
                color: "text.light",
                fontSize: "16px",
                fontWeight: "normal",
                mt: 1,
              }}
            >
If you have any questions or need assistance with the Option Buying Class content, our support team is here to help. Please don't hesitate to reach out to us via email at Sales@dtzvinoth.com. We're committed to ensuring your learning experience is smooth and enjoyable.
</Typography>
      </CustomTabPanel>
    </Box>
  );
}