import React, { useState } from 'react';
import {
  Container, Box, Typography, TextField, FormControlLabel, Checkbox,
  Button, Grid, MenuItem, Select, InputLabel, FormControl, RadioGroup, Radio,
  Divider
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ScrollToTop from '../../components/ScrollToTop';

const Algoform = () => {
  const [orderType, setOrderType] = useState('paper');
  const [runName, setRunName] = useState('');
  const [folderName, setFolderName] = useState('');
  const [instrument, setInstrument] = useState('');
  const [features, setFeatures] = useState({
    trailingStopLoss: false,
    reEntry: false,
    reExecute: false,
    waitAndTrade: false,
  });

  const handleFeatureChange = (event) => {
    setFeatures({
      ...features,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <Container>
        <ScrollToTop/>
      <Box my={5} mt={20} p={2} border={1} borderColor="grey.300" borderRadius={2}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h4">Live</Typography>
        </Box>
        <Box mt={3}>
          <Grid container spacing={2} mt={2}>
            <Grid item xs={6}>
            <Box>
            <Typography variant="h6" sx={{fontSize: 16, mb: 0.5, color: "grey"}}>Order Type</Typography>
            <RadioGroup row value={orderType} onChange={(e) => setOrderType(e.target.value)}>
              <FormControlLabel  value="paper" control={<Radio />} label="Paper Testing" />
              <FormControlLabel value="oneclick" control={<Radio />} label="Oneclick Trading" />
              <FormControlLabel value="auto" control={<Radio />} label="Auto Trading" />
            </RadioGroup>
          </Box>
            </Grid>
            <Grid item xs={12} md={3}>
                <Typography variant="h6" sx={{fontSize: 16, mb: 0.5, color: "grey"}}>Run Name</Typography>
              <TextField
                size='small'
                placeholder='Enter Run Name'
                fullWidth
                value={runName}
                onChange={(e) => setRunName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={3}>
                <Typography variant="h6" sx={{fontSize: 16, mb: 0.5, color: "grey"}}>Folder Name</Typography>
              <TextField
                size='small'
                placeholder='Select a folder'
                fullWidth
                value={folderName}
                onChange={(e) => setFolderName(e.target.value)}
              />
            </Grid>
          </Grid>
          <Box mt={2}>
          <Divider sx={{my: 3}}/>
          <Grid container spacing={2}>
                <Grid item xs={1.5}>
                <Typography variant="h6">Instrument</Typography>
                </Grid>
                <Grid item xs={3}>
                <FormControl size='small' fullWidth>
              <Select value="Future & Options" onChange={(e) => setInstrument(e.target.value)}>
                <MenuItem value="Future & Options" >Future & Options</MenuItem>
                <MenuItem value="equity_index">Equity & Index</MenuItem>
              </Select>
            </FormControl>
                </Grid>
                <Grid item xs={3}>
                <TextField
                size='small'
                placeholder='Search...'
                fullWidth
                value={runName}
                onChange={(e) => setRunName(e.target.value)}
              />
                </Grid>
          </Grid>
          </Box>
          <Box mt={2}>
          <Typography variant="h6" sx={{mb: 1}}>Indicators</Typography>
            <Box sx={{border:'1px solid #cacaca', p: 1, borderRadius: 1}} display="flex" alignItems="center" justifyContent="space-between">
                <Box display="flex" alignItems="center">
                    <Typography>Current Candle</Typography>
                    <Typography sx={{ml: 2}}>Name: Current</Typography>
                    <Typography sx={{ml: 2}}>Candle Interval: 3 Minutes</Typography>
                    <Typography sx={{ml: 2}}>Fields: Future</Typography>
                    <Typography sx={{ml: 2}}>Chart Type: Candle</Typography>
                </Box>
                <Box display="flex" alignItems="center">
            <Button variant='outlined' sx={{mr: 2}}>
            <DeleteIcon></DeleteIcon>
            </Button>
            <Button variant='contained'>
            <EditIcon></EditIcon> Edit
            </Button>
                </Box>
            </Box>
            <Box display="flex" justifyContent="center" alignItems="center" sx={{mt: 2}}>
            <Button variant="contained" color="primary" style={{ marginLeft: 10 }}>Add Indicator</Button>
            </Box>
          </Box>
          <Divider sx={{my: 3}}></Divider>
          <Box mt={2}>
            <Grid container spacing={2}>
                <Grid item xs={3}>
                <Typography variant="h6">Features Enablement</Typography>
                </Grid>
                <Grid item xs={9}>
                <FormControlLabel
              control={<Checkbox checked={features.trailingStopLoss} onChange={handleFeatureChange} name="trailingStopLoss" />}
              label="Trailing Stop Loss"
            />
            <FormControlLabel
              control={<Checkbox checked={features.reEntry} onChange={handleFeatureChange} name="reEntry" />}
              label="Re-Entry"
            />
            <FormControlLabel
              control={<Checkbox checked={features.reExecute} onChange={handleFeatureChange} name="reExecute" />}
              label="Re-Execute"
            />
            <FormControlLabel
              control={<Checkbox checked={features.waitAndTrade} onChange={handleFeatureChange} name="waitAndTrade" />}
              label="Wait and Trade"
            />
                </Grid>
            </Grid>
          </Box>
          <Box sx={{border:'1px solid #cacaca', p: 1, borderRadius: 1, mt: 2}} display="flex" alignItems="center" justifyContent="space-between">
                <Box display="flex" alignItems="center">
                    <Typography sx={{fontWeight: 'bold'}}>Entry When</Typography>
                    <Typography sx={{ml: 2}}>Empty</Typography>
                </Box>
                <Box display="flex" alignItems="center">
            <Button variant='contained'>
            <EditIcon></EditIcon> Edit
            </Button>
                </Box>
            </Box>
            <Box sx={{border:'1px solid #cacaca', p: 1, borderRadius: 1, mt: 2}} display="flex" alignItems="center" justifyContent="space-between">
                <Box display="flex" alignItems="center">
                    <Typography sx={{fontWeight: 'bold'}}>Exit When</Typography>
                    <Typography sx={{ml: 2}}>Empty</Typography>
                </Box>
                <Box display="flex" alignItems="center">
            <Button variant='contained'>
            <EditIcon></EditIcon> Edit
            </Button>
                </Box>
            </Box>
          <Box mt={2} sx={{mt: 5}}>
            <Grid container spacing={2}>
                <Grid item xs={1}>
                <Typography variant="h6" sx={{fontSize: 14, mb: 0.5, color: "grey"}}>SEGMENT</Typography>
                </Grid>
                <Grid item xs={1.5}>
                <Typography variant="h6" sx={{fontSize: 14, mb: 0.5, color: "grey"}}>INSTRUMENT</Typography>
                </Grid>
                <Grid item xs={1}>

                </Grid>
                <Grid item xs={1}>

                </Grid>
                <Grid item xs={1}>

                </Grid>
                <Grid item xs={1}>
                <Typography variant="h6" sx={{fontSize: 14, mb: 0.5, color: "grey"}}>QUANTITY</Typography>
                </Grid>
                <Grid item xs={1}>
                <Typography variant="h6" sx={{fontSize: 14, mb: 0.5, color: "grey"}}>ACTION</Typography>
                </Grid>
                <Grid item xs={1.5}>
                <Typography variant="h6" sx={{fontSize: 14, mb: 0.5, color: "grey"}}>STOP LOSS</Typography>
                </Grid>
                <Grid item xs={1.5}>
                <Typography variant="h6" sx={{fontSize: 14, mb: 0.5, color: "grey"}}>TAKE PROFIT</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={2} sx={{mt: 0}}>
              <Grid item xs={12} md={1}>
                <FormControl value="option 1" size='small' fullWidth>
                  <Select>
                    <MenuItem value="option 1">Option 1</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={1.5}>
                <FormControl size='small' fullWidth>
                  <Select>
                    <MenuItem value="current_week">Current Week</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={1}>
                <FormControl size='small' fullWidth>
                  <Select>
                    <MenuItem value="current_week">Current Week</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={1}>
                <FormControl size='small' fullWidth>
                  <Select>
                    <MenuItem value="current_week">Current Week</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={1}>
                <Button variant="contained" color="success">CE</Button>
              </Grid>
              <Grid item xs={12} md={1}>
                <TextField size='small' value="1" fullWidth type="number" />
              </Grid>
              <Grid item xs={12} md={1}>
                <Button variant="contained" color="success">Buy</Button>
              </Grid>
              <Grid display='flex' item xs={12} md={1.5}>
              <FormControl size='small' fullWidth>
                  <Select>
                    <MenuItem value="₹">₹</MenuItem>
                  </Select>
                </FormControl>
                <TextField size='small' fullWidth />
              </Grid>
              <Grid display='flex' item xs={12} md={1.5}>
                <FormControl size='small' fullWidth>
                  <Select>
                    <MenuItem value="₹">₹</MenuItem>
                  </Select>
                </FormControl>
                <TextField size='small' fullWidth />
              </Grid>
              <Grid item xs={12} md={1.5}>
                <Button variant='outlined'><DeleteIcon></DeleteIcon></Button>
              </Grid>
            </Grid>
            <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
            <FormControlLabel
              control={<Checkbox checked={features.trailingStopLoss} onChange={handleFeatureChange} name="Move to Cost" />}
              label="Move to Cost"
            /> 
            <Typography sx={{color: 'red'}}>Please resolve the errors in the expiry (Some instrument have weekly or monthly expiry only)</Typography>
              <Button variant="contained" color="primary">Add Leg</Button>
            </Box>
            <Box mt={4} display="flex" justifyContent="center" alignItems="center">
            <Button variant="contained" color="primary">Add Adjustments</Button>
            </Box>
          </Box>
          <Box mt={3}>
            <Typography variant="h6">Advanced Options</Typography>
            <Box mt={3} display='flex'>
            <Typography variant="h6" sx={{fontSize: 14, mb: 0.5, color: "grey"}}>Adjustment / ReEntry / ReExecute / OpenNewlegs</Typography>
            <FormControl value="next_minute_start" fullWidth>
              <Select>
                <MenuItem value="next_minute_start">Next Minute Start</MenuItem>
              </Select>
            </FormControl>
            </Box>
          </Box>
          <Box mt={2}>
            <Typography variant="h6">Transaction Targets</Typography>
            <Grid mt={1} container spacing={2}>
              <Grid item xs={12} md={6}>
              <Typography variant="h6" sx={{fontSize: 16, mb: 1, color: "grey"}}>Stop Loss (%)</Typography>
                <TextField  fullWidth />
              </Grid>
              <Grid item xs={12} md={6}>
              <Typography variant="h6" sx={{fontSize: 16, mb: 1, color: "grey"}}>Take Profit (%)</Typography>
                <TextField fullWidth />
              </Grid>
            </Grid>
          </Box>
          <Box mt={2}>
            <Typography variant="h6">Daily Targets</Typography>
            <Grid mt={1}  container spacing={2}>
              <Grid item xs={12} md={6}>
              <Typography variant="h6" sx={{fontSize: 16, mb: 1, color: "grey"}}>Stop Loss (₹)</Typography>
                <TextField fullWidth />
              </Grid>
              <Grid item xs={12} md={6}>
              <Typography variant="h6" sx={{fontSize: 16, mb: 1, color: "grey"}}>Take Profit (₹)</Typography>
                <TextField fullWidth />
              </Grid>
            </Grid>
          </Box>
          <Box mt={2}>
            <Typography variant="h6">Expiry</Typography>
            <Grid container spacing={2}>
              <Grid item>
                <FormControlLabel control={<Checkbox />} label="Intraday" />
              </Grid>
              <Grid item>
                <FormControlLabel control={<Checkbox />} label="Positional" />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField label="Trade During" fullWidth placeholder="09:15 - 15:25" />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField label="Max Transactions Per Day" fullWidth type="number" />
              </Grid>
            </Grid>
          </Box>
          <Box mt={3}>
            <Button variant="contained" color="primary">Submit</Button>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default Algoform;
