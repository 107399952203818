import React, {useState, useEffect} from 'react';
import {
  Box, Button, Container, Divider, Grid, TextField, Typography,
} from "@mui/material";
import './style.css';
import takepic from '../../assets/images/takeapick.png';
import temp from '../../assets/images/choosetemp.png';
import studio from '../../assets/images/studio.png';
import arrow1 from '../../assets/images/arrow1.png';
import arrow2 from '../../assets/images/arrow2.png';
import VertTabs from '../../components/tabs/Tabs';
import userAi from '../../assets/images/users_ai.png';
import AIenhance from '../../assets/images/aienhancer.png';
import Easytask from '../../assets/images/easytask.png';
import team from '../../assets/images/team.png';
import figma1 from '../../assets/images/figma_1.png';

import uparrow from '../../assets/images/uparrow.png';
import trader from '../../assets/images/trader.png';
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';
import Reattimedata from '../../assets/images/1 (1).png';
import custSupport from '../../assets/images/customer_support.png';
import highSec from '../../assets/images/high_security.png';
import lowCommis from '../../assets/images/lower_commis.png';
import check from '../../assets/images/check.png';
import ourcommit from '../../assets/images/aboutus.png';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import starery from '../../assets/images/stagery.png';
import financeadvi from '../../assets/images/financeadvi.png';
import management from '../../assets/images/management.png';
import check1 from '../../assets/images/check1.svg';
import MultipleItems from '../../components/carousal/Carousal';
import faq from '../../assets/images/faq.png';
import CustomizedAccordions from '../../components/accordion/Accordion';
import contact from '../../assets/images/contact.png';
import ScrollToTop from '../../components/ScrollToTop';
import Vinoth from '../../assets/images/Vinoth_About.jpg'

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 15,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));


const About = ({setLoader}) => {


  return (
    <div>
    <ScrollToTop/>
    <Box className='bannerBg'>
    <Container maxWidth="lg" sx={{py: 12}}>
      <Typography variant='h3' sx={{color:'text.white', fontWeight:'bold'}}>About Us</Typography>
      <Typography variant='h6' sx={{fontFamily:"'Sora', sans-serif", fontWeight:'medium',  color:'text.white', mt: 3}}>
          <Typography variant='p' sx={{color:'primary.main'}}>Home</Typography> / About
          </Typography>
</Container>
    </Box>
    <Box className="thirdSec" sx={{pb: 0}}>
    <Container maxWidth='lg'>
    <iframe width="100%" height="600" src="https://www.youtube.com/embed/9zUZolg9EFo?si=ted702qAZ62YgCpd&amp;controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    </Container>
    </Box>
        <Box className="thirdSec" sx={{position:'relative'}}>
    <Container maxWidth="lg">
    <Grid container spacing={2} sx={{alignItems:'center'}}>
    <Grid className="commitImg" item xs={12} md={6} sx={{textAlign:'center'}}>
      <img src={Vinoth} alt='ourcommit' width='70%' style={{borderRadius: '0px 70px ', boxShadow:'0px 0px 20px #c0c0c0'}}></img>
      <Box className="commitProgress3">
        <Typography variant='h5' sx={{fontWeight:'bold', color:'secondary.main'}}>5+ Years</Typography>
        <Typography variant='body1'>Trading Experience</Typography>
     </Box>
     <Box className="commitProgress4">
        <Typography variant='h5' sx={{fontWeight:'bold', color:'secondary.main'}}>1000+</Typography>
        <Typography variant='body1'>Students</Typography>
     </Box>
  </Grid>
  <Grid item xs={12} md={6}>
  <Typography variant='h4' sx={{fontFamily:"'Sora', sans-serif", fontWeight:'bold',  color:'text.main', lineHeight: 1.5, mt: {md: 0, xs: 3}}}>Meet
          <Typography variant='p' sx={{color:'secondary.main'}}> Vinoth: </Typography>  Pioneering the Future of Trading with Algorithmic Innovation
          </Typography>
          <Typography  component='p' sx={{fontSize: '19px', fontWeight:'normal', color:'text.light', my: 3}}>Hi, I'm Vinoth, the founder of DTZ Trading Academy. My journey in the financial markets has been driven by a passion for innovation and a relentless pursuit of excellence. As a seasoned trader and entrepreneur, I've witnessed firsthand the transformative power of algorithmic trading. At DTZ, my vision is to democratize access to these cutting-edge technologies, empowering traders of all levels to succeed in the dynamic world of finance. Join me as we revolutionize the way you trade and unlock new opportunities for financial growth.</Typography>
          <a href='https://wa.link/l8q5eh' target='_blank'>
        <Button size="large" variant='contained'>Contact Me</Button>
        </a>
  </Grid>
</Grid>
    </Container>
        </Box>
    </div>
  )
}

export default About