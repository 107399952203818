import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import check1 from '../../assets/images/check1.svg';
import { Grid, Rating } from '@mui/material';
import instructor from '../../assets/images/Vinoth_About.jpg';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import TelegramIcon from '@mui/icons-material/Telegram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import sara from '../../assets/images/sara.png';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 4 }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Overview" {...a11yProps(0)} />
          <Tab label="Curriculum" {...a11yProps(1)} />
          <Tab label="Instructor" {...a11yProps(2)} />
          <Tab label="Reviews" {...a11yProps(3)} />
          <Tab label="Help" {...a11yProps(3)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
      <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            Study Plan
            </Typography>
            <Typography
              component="p"
              sx={{
                color: "text.light",
                fontSize: "16px",
                fontWeight: "normal",
                mt: 1,
              }}
            >
DTZ's Option Selling Class is an intensive program designed to equip traders with the knowledge and skills needed to excel in options trading. Over two days, participants will delve into various aspects of options trading, including candlestick sentiment analysis, trend identification, strike price selection, premium value momentum, and much more. The course will also cover market sentiment concepts, gap analysis, entry and exit rules, and practical market analysis using our proprietary strategies. Please note that this class is for educational purposes only, and traders are advised to consult their financial advisor before making any trades.</Typography>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
      <Typography variant="h5" sx={{ fontWeight: "bold", mt: 2 }}>
What will you learn from this course?
            </Typography>
            <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
Day 01 :
            </Typography>
            <Box sx={{display:'flex', justifyContent:'start', alignItems:'center', gap: 2, mt: 2}}>
  <img src={check1} alt='check'></img>
<Typography  component='p' sx={{fontSize: '16px', fontWeight:'medium'}}>Candlestick Sentiment Analysis</Typography>
  </Box>
  <Box sx={{display:'flex', justifyContent:'start', alignItems:'center', gap: 2, mt: 2}}>
  <img src={check1} alt='check'></img>
<Typography  component='p' sx={{fontSize: '16px', fontWeight:'medium'}}>How to Find Trending Markets</Typography>
  </Box>
  <Box sx={{display:'flex', justifyContent:'start', alignItems:'center', gap: 2, mt: 2}}>
  <img src={check1} alt='check'></img>
<Typography  component='p' sx={{fontSize: '16px', fontWeight:'medium'}}>How to Avoid Sideways Markets</Typography>
  </Box>
  <Box sx={{display:'flex', justifyContent:'start', alignItems:'center', gap: 2, mt: 2}}>
  <img src={check1} alt='check'></img>
<Typography  component='p' sx={{fontSize: '16px', fontWeight:'medium'}}>Highly Probability Patterns</Typography>
  </Box>
  <Box sx={{display:'flex', justifyContent:'start', alignItems:'center', gap: 2, mt: 2}}>
  <img src={check1} alt='check'></img>
<Typography  component='p' sx={{fontSize: '16px', fontWeight:'medium'}}> Selecting Strike Prices for Option Buying and Selling</Typography>
  </Box>
  <Box sx={{display:'flex', justifyContent:'start', alignItems:'center', gap: 2, mt: 2}}>
  <img src={check1} alt='check'></img>
<Typography  component='p' sx={{fontSize: '16px', fontWeight:'medium'}}>Revealing the Secrets of Premium Value Momentum</Typography>
  </Box>
  <Box sx={{display:'flex', justifyContent:'start', alignItems:'center', gap: 2, mt: 2}}>
  <img src={check1} alt='check'></img>
<Typography  component='p' sx={{fontSize: '16px', fontWeight:'medium'}}>Magic Box Concept & Strategy Class</Typography>
  </Box>
  <Box sx={{display:'flex', justifyContent:'start', alignItems:'center', gap: 2, mt: 2}}>
  <img src={check1} alt='check'></img>
<Typography  component='p' sx={{fontSize: '16px', fontWeight:'medium'}}> Option Chain Analysis</Typography>
  </Box>
  <Box sx={{display:'flex', justifyContent:'start', alignItems:'center', gap: 2, mt: 2}}>
  <img src={check1} alt='check'></img>
<Typography  component='p' sx={{fontSize: '16px', fontWeight:'medium'}}>Finding Perfect Support, Resistance & Reversal Points</Typography>
  </Box>
  <Box sx={{display:'flex', justifyContent:'start', alignItems:'center', gap: 2, mt: 2}}>
  <img src={check1} alt='check'></img>
<Typography  component='p' sx={{fontSize: '16px', fontWeight:'medium'}}>Tomorrow's Market Prediction</Typography>
  </Box>
  <Box sx={{display:'flex', justifyContent:'start', alignItems:'center', gap: 2, mt: 2}}>
  <img src={check1} alt='check'></img>
<Typography  component='p' sx={{fontSize: '16px', fontWeight:'medium'}}> Identifying Panic Selling & Buying</Typography>
  </Box>
  <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
Day 02 :
            </Typography>
            <Box sx={{display:'flex', justifyContent:'start', alignItems:'center', gap: 2, mt: 2}}>
  <img src={check1} alt='check'></img>
<Typography  component='p' sx={{fontSize: '16px', fontWeight:'medium'}}>Market Sentiment Concepts</Typography>
  </Box>
  <Box sx={{display:'flex', justifyContent:'start', alignItems:'center', gap: 2, mt: 2}}>
  <img src={check1} alt='check'></img>
<Typography  component='p' sx={{fontSize: '16px', fontWeight:'medium'}}>Gap-Up & Gap-Down Sentiment</Typography>
  </Box>
  <Box sx={{display:'flex', justifyContent:'start', alignItems:'center', gap: 2, mt: 2}}>
  <img src={check1} alt='check'></img>
<Typography  component='p' sx={{fontSize: '16px', fontWeight:'medium'}}>Time & Trend-Based Market Sentiment</Typography>
  </Box>
  <Box sx={{display:'flex', justifyContent:'start', alignItems:'center', gap: 2, mt: 2}}>
  <img src={check1} alt='check'></img>
<Typography  component='p' sx={{fontSize: '16px', fontWeight:'medium'}}>Entry & Exit Rules</Typography>
  </Box>
  <Box sx={{display:'flex', justifyContent:'start', alignItems:'center', gap: 2, mt: 2}}>
  <img src={check1} alt='check'></img>
<Typography  component='p' sx={{fontSize: '16px', fontWeight:'medium'}}> Market Analysis using Our Strategy on Nifty, Finnifty, and Banknifty Markets</Typography>
  </Box>
  <Typography variant="h6" sx={{ fontWeight: "medium", mt: 4, fontStyle:'italic' }}>
  Desclaimer
            </Typography>
  <Typography variant="h6" sx={{ fontWeight: "bold"}}>
  For Educational Purposes Only:
            </Typography>
            <Box sx={{display:'flex', justifyContent:'start', alignItems:'center', gap: 2, mt: 1}}>
<Typography  component='p' sx={{fontSize: '16px', fontWeight:'medium'}}>Risk and rewards are inherent in the market. We are not liable for any losses incurred. Before making any trades, it is essential to consult your financial advisor.</Typography>
  </Box>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
                <img src={instructor} alt='image' width='100%' style={{borderRadius: 20}}></img>
            </Grid>
            <Grid item xs={12} md={8}>
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
  Vinoth
            </Typography>
            <Typography
              component="p"
              sx={{
                color: "text.light",
                fontSize: "16px",
                fontWeight: "normal",
              }}
            >
Founder & CEO</Typography>
<Typography
              component="p"
              sx={{
                color: "text.light",
                fontSize: "16px",
                fontWeight: "normal",
                mt: 2,
              }}
            >
I'm Vinoth, founder of DTZ Trading Academy. With a passion for innovation, I aim to democratize access to algorithmic trading, empowering traders of all levels. Join us to revolutionize trading and unlock new growth opportunities.</Typography>
<Box sx={{display:'flex', justifyContent:{md:'start', xs:'start'}, alignItems:'center', gap: 3, mt: 3}}>
          <a href='https://wa.link/l8q5eh' target='_blank' style={{textDecoration:'none'}}>
          <Box sx={{border:'2px solid', borderColor:'primary.main', width:'45px', height:'45px', borderRadius:'50%', display:'flex', justifyContent:'center', alignItems:'center'}}>
    <WhatsAppIcon sx={{color:'text.main'}}/>
  </Box>
  </a>
  <a href='https://www.instagram.com/dtz_vinoth/' target='_blank' style={{textDecoration:'none'}}>
  <Box sx={{border:'2px solid', borderColor:'primary.main', width:'45px', height:'45px', borderRadius:'50%', display:'flex', justifyContent:'center', alignItems:'center'}}>
    <InstagramIcon sx={{color:'text.main'}}/>
  </Box>
  </a>
  <a href='https://t.me/DTZcommunity/' target='_blank' style={{textDecoration:'none'}}>
  <Box sx={{border:'2px solid ', borderColor:'primary.main', width:'45px', height:'45px', borderRadius:'50%', display:'flex', justifyContent:'center', alignItems:'center'}}>
    <TelegramIcon sx={{color:'text.main'}}/>
  </Box>
  </a>
  <a href='https://www.youtube.com/@DisciplineTradersZone' target='_blank' style={{textDecoration:'none'}}>
  <Box sx={{border:'2px solid', borderColor:'primary.main', width:'45px', height:'45px', borderRadius:'50%', display:'flex', justifyContent:'center', alignItems:'center'}}>
    <YouTubeIcon sx={{color:'text.main'}}/>
  </Box>
  </a>
  </Box>
            </Grid>
        </Grid>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
      <Typography variant="h4" sx={{ fontWeight: "bold" }}>
  Reviews
            </Typography>
        <Grid container spacing={4} sx={{mt: 2}}>
            <Grid item xs={12} md={2}>
                <img src={sara} alt='image' width={100} style={{borderRadius: '50%'}}></img>
            </Grid>
            <Grid item xs={12} md={8}>
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            Sarah T
            </Typography>
            <Typography
              component="p"
              sx={{
                color: "text.light",
                fontSize: "16px",
                fontWeight: "normal",
              }}
            >
Student</Typography>
<Typography
              component="p"
              sx={{
                color: "text.light",
                fontSize: "16px",
                fontWeight: "normal",
                mt: 2,
              }}
            >
I attended DTZ's Option Buying Class, and it exceeded my expectations. The instructors were knowledgeable and provided practical insights into options trading. The comprehensive curriculum covered everything from basic concepts to advanced strategies. I highly recommend this class to anyone looking to enhance their options trading skills.</Typography>
            </Grid>
            <Grid item xs={12} md={2}>
            <Rating name="half-rating" defaultValue={4.5} precision={0.5} />
            </Grid>
        </Grid>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4}>
      <Typography variant="h4" sx={{ fontWeight: "bold" }}>
      For details about the course
            </Typography>
            <Typography
              component="p"
              sx={{
                color: "text.light",
                fontSize: "16px",
                fontWeight: "normal",
                mt: 1,
              }}
            >
For any inquiries or assistance regarding the Option Buying Class content, feel free to contact our support team at Sales@dtzvinoth.com. We are here to ensure your learning experience is smooth and rewarding.
</Typography>
      </CustomTabPanel>
    </Box>
  );
}